
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { SubNav } from '../../components/SubNav';
import { productNav } from '../../config/navItems';
import {Button, Layout,Dropdown,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { EllipsisOutlined, EyeOutlined, NotificationOutlined } from '@ant-design/icons';
import Formpurchase from './addpurchase';
import TabProductPlaceholder from './components/tabproductplaceholder'; 
import LayoutPage from '../layout';
import DetailsPurchase from './detailspurchase';
import { useGetPurchases,useEditPurchase} from '../../api/api';
import AddAppro from './components/AddAppro'

const {  Content } = Layout;
export default function  Purchases () {

    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [visibleD,setVisibleD] = useState(false)
    const [orders,setOrders] = useState(null)
    const [purchases,setPurchases] = useState(null)
    const [purchase,setPurchase] = useState(null)
    const [filters, setFilters] = useState({status:'all'});
    const [searchText, setSearchText] = useState("");

    const { data, error, isFetching, refetch } = useGetPurchases({}, filters);
    const { mutateAsync: editProduct } = useEditPurchase(purchase?._id);

  
    useEffect(() => {
        setPurchases(data?.data);
   
      }, [data]);
    
       useEffect(() => {
        refetch();
      }, [filters]); 



      const handleChange = (value) => {
           setFilters({...filters,status:value})
      };

    const close = () => {

        setVisibleD(false)

    }

    const change  =  (r)  => {
       
        setVisibleD(true)
        setPurchase(r)
        
            }

  
            const items = [

                {
                   key: '1',
                   label: (
                     <span className="fs11 flex itemcenter" onClick={()=>setVisibleD(true)}  >
                   <EyeOutlined className="u-mar-right-xxs" />  Plus de détails
                     </span>
                   ),
                 },
                  /* 
                 {
                   key: '4',
                   label: (
                     <span className="fs11 flex itemcenter w100" >
                   <PlusCircleOutlined className="u-mar-right-xxs" />  Historique de stock
                     </span>
                   ),
                 }, 
                   {
                     key: '2',
                     label: (
                       <span className="fs11 flex itemcenter w100">
                     <EditOutlined />  <AddProduct prod={product} /> 
                       </span>
                     ),
                   },
               
                   {
                     key: '3',
                     label: (
                       <span className="nega fs11" onClick={showDeleteConfirm}>
                         <DeleteOutlined /> Supprimer le produit
                       </span>
                     ),
                   }*/
                 ];
          

    const columns = [


        {
            title: 'Ref',
            dataIndex: 'purchase_id',
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.purchase_id).toLowerCase().includes(value.toLowerCase()) || String(record.infos.invoice_number).toLowerCase().includes(value.toLowerCase()) || String(record.infos.vendor.name).toLowerCase().includes(value.toLowerCase()) || String(record.infos.status).toLowerCase().includes(value.toLowerCase())
            }
        },

        {
            title: 'Date de commande',
            dataIndex: 'createdAt',
            render: item =>
            <div className="column flex">
{moment(item).format('ddd d MMM YY')}
<span className="fs11 fW300" style={{color:'#7e838f'}}>{moment(item).format('HH')}h{moment(item).format('mm')}</span>

            </div>
        },

        {
            title: 'Fournisseur',
            dataIndex: ['infos','vendor','name'],
        },


        {


            title: 'Date de livraison',
            dataIndex: ['infos','expected_arrival_date'],
   
render: item =>
            <div className="column flex">
{item && moment(item).format('ddd d MMM YY')}
{item && <span className="fs11 fW300" style={{color:'#7e838f'}}>{moment(item).format('HH')}h{moment(item).format('mm')}</span> }

            </div>



    },
       
     /*    {
            title: 'Nun Facture',
            dataIndex: ['infos','invoice_number'],
        }, */
       
      
        {
            title: 'Qte ',
            dataIndex: 'products',


            render: item =>
            <>
{  item.reduce((n, {quantity}) => n + quantity, 0)}

            </>



        },
        {
            title: 'Status',
            dataIndex: ['status'],
            render: item =>
            <>

{ item == "Livrée" ? <span className="u-pad-xxs greenstatus rad4 fs11"> {item} </span> : item == "Attente livraison" ? <span className="u-pad-xxs yellowstatus rad4 fs11"> {item} </span> : <span className="u-pad-xxs  redstatus rad4 fs11"> {item} </span> }

            </>
        },
        {
            title: 'Prix',
            dataIndex: 'total_price', 
            
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) } Fcfa
            </>
        
        },
        {
            title: '',
            key: 'action',
            width: 40,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
   setPurchase(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },
        



    ];







    return (





        <LayoutPage>

<SubNav items={productNav}  /> 

<div className="container">

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div className='flex grille itemcenter noneres'>
       
       


       <div className='w30 u-mar-right-s'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher dans les appro" style={{minWidth:'250px',height:'45px!important'}} />

</div>


                                              <Select className='w20 u-mar-right-s' onChange={handleChange}  defaultValue="all">
                                              <option value={"all"}>Tous les approvisionnement</option>
                                        

                                              <option  value={"Attente livraison"} >Attente de livraison</option>
                                              <option  value={"Livrée"} >Livrée</option>
                                              <option  value={"Livrée partiellement"} >Livrée partiellement</option>
                                              <option  value={"Annulée"} >Annulée</option>



                                              </Select>



       </div>


                               
       <div className="flex itemcenter">
                                <AddAppro />
</div>

                       


<Drawer
                                    


                                    height={"100%"}
                                    placement={"bottom"}
                                    onClose={()=>setVisibleD(false)}
                                    visible={visibleD}
                                    closable={false}
                                    bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0,backgroundColor:"#eff4fa"  }}
                                    destroyOnClose={true}
                                    >



                                      <DetailsPurchase  close={close} purchase={purchase}/>

                                      </Drawer>



                                </div>

                                <Table 
                                onRow={(record) => ({
                                    onClick: () => { change(record) }
                                })}
                                columns={columns}  locale={{emptyText:<></>}}  loading={{indicator:<TabProductPlaceholder/>,spinning:isFetching}}  dataSource={purchases} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>

                            
                            </div>

             
</LayoutPage>


    );


}
