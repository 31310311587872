








import React, {Fragment, useState,useCallback,useRef} from 'react';
import moment from 'moment';
import 'moment/locale/fr';


import {Col, Row,Button,Menu,Dropdown,Drawer,Steps,Spin,Descriptions,Modal,message} from 'antd';
//import Print from './printOrder';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import {useHistory} from "react-router";
import {CaretDownOutlined,CloseOutlined,MessageOutlined,WhatsAppOutlined,MailOutlined,CopyOutlined} from '@ant-design/icons';
import iconexport from '../../images/icon-export.png'
import iconimport from '../../images/icon-import.png'
import iconbarcode from '../../images/icon-barcode.png'
import Details from '../sales/details';
import { useEditStatusOrder } from '../../api/api';

const { Step } = Steps;

export default function  Detailsorder ({close,order}) {
    const history = useHistory()
    const [visible,setVisible] = useState(false)

    const [loading,setLoading] = useState(false)

    const [loadingP,setLoadingP] = useState(false)
    const [visibleFac,setVisibleFac] = useState(false)
    const [phone,setPhone] = useState(order.sale.customer.phones)
    const [email,setEmail] = useState(order.sale.customer.email)
    const componentRef = useRef();
    const { mutateAsync: editStatusOrder } = useEditStatusOrder(order?.id);




    const menuAction = (
        <Menu style={{width:'200px'}} >
    
    
    <Menu.Item key="1" className="flex itemcenter rad8 " onClick={()=>setVisibleFac(true)} >
    
    <div  className="  itemcenter justbtw flex w100">
    
     
    <span className="av-heavy fs14">imprimer la facture</span>
    
    <img src={iconbarcode} height={16} width={16}  />
    
    
    </div>
    
    
    
    
    </Menu.Item>
        
    
            <Menu.Item key="2" className="flex itemcenter rad8 " onClick={()=>setVisible(true)}>
    
                <div className="  itemcenter justbtw flex w100">
    
             
                <span className="av-heavy fs14">Envoyer la facture</span>
    
                <img src={iconexport} height={16} width={16}  />
    
    
                </div>
    
    
    
    
            </Menu.Item>
    
    
    
            <Menu.Item key="3" className="flex itemcenter rad8 " >
    
            <div  className="  itemcenter justbtw flex w100">    
             
    <span className="av-heavy fs14">Modifier la commande</span>
    
    <img src={iconimport} height={16} width={16}  />
    
    </div>
    
    
    
    
    
            </Menu.Item>
    
    
    
    
       
    
        </Menu>
    );
    

 
   const  renderSwitch = (param) => {
        switch(param) {
          case 'Non traitée':
            return <Button type="primary" size="large" loading={loading}  className="rad8" onClick={()=>changeOrderStatus("Traitée")} >
            <span className="fs16 av-heavy">  Marquer comme  traitée</span>
        </Button>;
        case 'Traitée':
            return <Button type="primary" size="large" loading={loading}  className="rad8" onClick={()=>changeOrderStatus("Livraison en cours")}>
            <span className="fs16 av-heavy">  Marquer livraison en cours</span>
        </Button>;

        case 'Livraison en cours':
            return <Button type="primary" size="large" loading={loading}  className="rad8" onClick={()=>changeOrderStatus("Livrée")} >
            <span className="fs16 av-heavy">  Marquer comme Livrée </span>
        </Button>;


          default:
            return ;
        }
      }



      const  changeOrderStatus =  useCallback(async (data) => {

        try {
           
            setLoading(true)
          await  editStatusOrder({status:data})
            message.success('Enregistrement réussi!!')
            close()          
            setLoading(false)
        } catch(e) {
        
            message.warning('Erreur enregistrement!')
            setLoading(false)
        }





      }, []);
    





    return (






                            <div className="flex  column rel h100">




<Modal
                                    
                                    width={600}
                                    onCancel={()=>setVisible(false)}
                                    visible={visible}
bodyStyle={{ paddingBottom: 12,paddingTop:12,height:'auto'}}
destroyOnClose={true}
closable={true}
footer=""
maskClosable={true}


>

    <h2 className="fW500 w100 center"> Envoyer la facture</h2>

 <div className='block  cardpour u-pad-horizontal-s justbtw u-pad-horizontal-xs-res u-mar-top flex itemcenter u-pad-vertical-m u-pad-vertical-xs-res rad12 rad8res'>

<>
<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
<MessageOutlined style={{fontSize:'22px'}} />
    </div>
    <div className="flex column">
 <span className="fW500">  Envoyer par SMS </span> 

tel : {phone}
    </div>
    </>


    <div>
        <Button primary> Envoyer</Button>
    </div>

</div>







<div className='block  cardpour u-pad-horizontal-s justbtw u-pad-horizontal-xs-res u-mar-top-m flex itemcenter u-pad-vertical-m u-pad-vertical-xs-res rad12 rad8res'>

<>
<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
<WhatsAppOutlined style={{fontSize:'22px'}} />
    </div>
    <div className="flex column">
 <span className="fW500">  Envoyer par Whatsapp </span> 

tel : {phone}

    </div>
    </>
 

    <div>
    <a href={`https://wa.me/${phone}?text=hello bien vouloir consulter votre facture sur le lien ci-dessous https://lefax.onebaz.com/order/${order.id}`}  target="_blank">    <Button primary> Envoyer</Button> </a>
    </div>

</div>




{/* <div className='block  cardpour u-pad-horizontal-s justbtw u-pad-horizontal-xs-res u-mar-top-m flex itemcenter u-pad-vertical-m u-pad-vertical-xs-res rad12 rad8res'>

<>
<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
<MailOutlined style={{fontSize:'22px'}} />
    </div>
    <div className="flex column">
 <span className="fW500">  Envoyer par email </span> 

Email : {email}

    </div>
    </>


    <div>
        <Button primary> Envoyer</Button>
    </div>

</div>  */}




<div className='block  cardpour u-pad-horizontal-s justbtw u-pad-horizontal-xs-res u-mar-top-m flex itemcenter u-pad-vertical-m u-pad-vertical-xs-res rad12 rad8res'>

<>
<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">
<CopyOutlined style={{fontSize:'22px'}} />
    </div>
    <div className="flex column">
 <span className="fW500">  Copier le lien de facture</span> 



    </div>
    </>


    <div>
        <Button primary> Envoyer</Button>
    </div>

</div>





</Modal>



                          
{console.log(order)}



<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-s" style={{top:0,borderBottom:'1px solid #DDD'}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:34}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600"> Details de la commande  </h2>








<div className="flex itemcenter abs" style={{right:34}}>


<div className="flex itemcenter">
                                <Dropdown overlay={menuAction} trigger={['click']}>


<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">

    <span className="fs15 av-heavy u-mar-right-xs" style={{color:'#1a1a1a'}}> 
    
    Actions 
     </span>

    <span style={{marginTop:'4.5px'}}>

<CaretDownOutlined />

</span>


</div>


</Dropdown>





{renderSwitch(order.status)}
</div>

</div>









</div>

        








                       

                            <Spin tip="Chargment..." size="large" spinning={false}>



                                <Row gutter={32}  className=" w100 u-mar-top-xl u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres ">

                                <Col span={11} offset={4}  className="u-pad-top-s  flex justcenter u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres ">


                                    <div className="flex u-mar-left-m  column rad8  bgwhite  nomarres u-pad-s-res" style={{height:'max-content'}}>



                                   
  
                                           
                                           
                                           
                                       
                                    
    <Fragment>

<Row gutter={32} className="blok rad16 hauto w100">

<Col span={24} className="u-pad-vertical-s u-mar-bottom-s rad8" style={{backgroundColor:'#eff3f9'}}>

<h2 className="fW600 flex itemcenter"> commande  {order.order_id}

{ order.status == "Livrée" ? <span className="u-pad-xxs graystatus rad4 fs14 u-mar-left-xs"> {order.status} </span> : order.status == "Non traitée" ? <span className="u-pad-xxs graystatus rad4 u-mar-left-xs fs14"> {order.status} </span> : order.status == "Traitée" ? <span className="u-pad-xxs bluestatus u-mar-left-xs rad4 fs14"> {order.status} </span> : order.status == "Livraison en cours" ? <span className="u-pad-xxs yellowstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> : order.status == "Livrée" ? <span className="u-pad-xxs greebstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> : <span className="u-pad-xxs redstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> }


{ order.payment_status == "Reglé" ? <span className="u-pad-xxs greenstatus rad4 fs14 u-mar-left-xs"> {order.payment_status} </span> : order.payment_status == "Attente de paiement" ? <span className="u-pad-xxs yellowstatus rad4 u-mar-left-xs fs14"> {order.payment_status} </span> : order.payment_status == "Avance" ? <span className="u-pad-xxs bluestatus u-mar-left-xs rad4 fs14"> {order.payment_status} </span>  : <span className="u-pad-xxs redstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> }


 </h2>


<Descriptions column={4} layout="vertical">



<Descriptions.Item label="Client">{order?.sale.customer.name }</Descriptions.Item> 
           <Descriptions.Item label="Date/heure de livraison">{moment(order?.delivery_date).format('D MMM Y HH:m')}</Descriptions.Item> 

           <Descriptions.Item label="adresse de livraison">{order.address}</Descriptions.Item> 
           <Descriptions.Item label="frais de livraison">{order.exp_fee}</Descriptions.Item> 

           <Descriptions.Item label="Chargé de la livraison">{}</Descriptions.Item> 

        <Descriptions.Item label="point de vente">{ }</Descriptions.Item> 
        <Descriptions.Item label="Mode de paiement">{order.pay_model}</Descriptions.Item> 
        <Descriptions.Item label="Date de paiement"> {moment(order.date_payment).format('D MMM Y HH:m')}</Descriptions.Item> 
        <Descriptions.Item label="Note">{order.note}</Descriptions.Item> 
    </Descriptions>

</Col>

<Col span={24} className="nomar">
    



<h3 className="fW600 nomar">Produits</h3>

<div>
<div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s" style={{borderTop: '1px #f2f2f2 solid',
    borderBottom: '1px #b3b3b3 solid'}}>  
  <span className="grille3 fW500"> Produits</span>
  <span className="grille07 fW500 right" style={{marginRight:'15px'}}> Qté </span>
  <span className="grille07  fW500 right"> P.U</span>
  <span className="grille  fW500 right"> Total</span>
   </div>



   <Fragment>
  
      
       <ul class="cart-list" style={{height:'auto',overflowY:'auto'}}>
      
       {
       
       
       order.sale.items.map( row => 
 <li key={row._id} class="section-cart flex itemcenter justbtw  u-pad-left-xs " style={{height:'38px'}}>
                     <span  className="grille3 av-heavy fs14 ">
           {row.product.name}
 </span>

 <span  className="grille av-heavy fs14 right">
           {row.quantity}
 </span>

 <span className="av-heavy fs14 grille right">
                 {  Intl.NumberFormat('fr-FR').format(row.price
) }

                     </span>


                   <span className="av-heavy fs14 grille right u-pad-right-xs">
                   {  Intl.NumberFormat('fr-FR').format(row.total) }

                     </span>
                  
             </li>
 ) }


      
       </ul>
       </Fragment>


       <div className="flex  w100 flexend">


<div className="w50" style={{border:'1px #d9d9d9 solid',borderTop:0}}>
   <div className="flex justbtw u-pad-s"><span className="fW500"> Frais de livraison</span> <span>{order.exp_fee}</span> </div>
 
  <div className="flex justbtw  bggray u-pad-s fW600 ">  <span > PRIX TOTAL  </span>   <span>{  Intl.NumberFormat('fr-FR').format(parseInt(order.sale.total) + (order.exp_fee ? order.exp_fee : 0)  ) } Fcfa</span>  </div>   

   </div>


   </div>

</div>
</Col>




</Row>



</Fragment>
   

                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

    <Col span={5} className="u-mar-top-s blok rad16 hauto w100">

    <h2 className="fW600 flex itemcenter">  Historique de commmande </h2>


        <Steps direction="vertical" size="small">

        {order.modifications_history.map(modif=>

<Step title={moment(modif.date).format('D MMM Y HH:m')} description={modif.description} />

        )}
    
    

  </Steps>
    
    </Col>

                              
                                </Row>

                            </Spin>






                            <Drawer

width={510}
onClose={()=>setVisibleFac(false)}
visible={visibleFac}
closable={false}
bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
destroyOnClose={true}

>
{ <Details id={order?.sale._id} onClose={()=>setVisibleFac(false)} />
}
</Drawer>



                  

</div>
             
                            

    );


}
