
import React, { useState,useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import axios from "axios";

import {Button,Checkbox, DatePicker,Upload, Layout,Select,Form,Col,message, Row,Divider,Input} from 'antd';
import {URL} from "../../urlapi";

import { UploadOutlined } from '@ant-design/icons';


const {  Content } = Layout;
const {Option} = Select;
export default function  FormRole ({close}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)

    const [nom,setNom] = useState("")
    const [description,setDescription] = useState("")
const [back_office,setBack_office] = useState({
    "allow_access_backoffice": true,
    "product_permissions": {
      "products_descriptions": true,
      "editing_products": true,
      "editing_definitions": true,
      "see_products_ingredients": true
    },
    "customer_permissions": {
      "seeing_customers": true,
      "editing_customers": true
    },
    "inventory_permissions": {
      "inventory_viewing_editing": true,
      "inventory_validation": true
    },
    "employee_permissions": {
      "seeing_employees": true,
      "editing_employees": true
    },
    "other_permissions": {
      "view_reports": true,
      "setting_access": true,
      "update_payment_type": true
    },
    "marketplace_permissions": {
      "viewing_orders": true,
      "approval_orders": true,
      "authority_to_create_invoice": true,
      "brand_category_product": true,
      "viewing_editing_marketplace_settings": true
    }
  }
  )


  const [point_of_sale,setPoint_of_sale] = useState ( {
    "allow_access_pos": true,
    "terminal_permissions": {
      "view_terminal_status": true,
      "terminal_open_close": true,
      "see_sales_transactions": true,
      "access_pos_settings": true
    },
    "sale_permissions": {
      "refund": true,
      "cancellation_of_sales": true,
      "update_payment_type": true,
      "update_custom_price": true,
      "park_deletion": true,
      "basket_deletion": true
    },
    "product_permissions": {
      "add_remove_favorite": true,
      "allow_see_others_branches": true
    },
    "discount_rates": {
      "product_discount_limit": 0,
      "cart_discount_limit": 0
    }
  } )


    function onChange(date, dateString) {
        console.log(date);
      }


      
      
  
   const  changeBack = e => {
   

setBack_office({...back_office,allow_access_backoffice:e.target.checked})

      };
  
  

    async function onSubmit ( values )  {

console.log(values.dateOfBeginning)
   
        const data = new FormData()
        data.append("firstname",values.firstname)
        values.lastname != undefined && data.append("lastname",values.lastname)
      
      
      
        if(values.upload){
        data.append("image",values.upload.file.originFileObj)
        }
       



        try {
            values.branches = [];
            values.roles = ["admin"]
            setLoading(true)
            const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

            const result = await axios.post(URL + '/roles',
            values,{
                headers: {
                    'tenant_user_api_key': token             
                }
            }
            )

            close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }








    return (

                                <div className="flex  column rel h100">

                                    <div
                                        className="justcenter itemcenter flex column  w100 abs  w100" style={{top:0}}

                                    >

                                        <span className="av-heavy fs22"> Ajouter un rôle </span>

                                        <Divider className="u-mar-vertical-xs" />

                                    </div>



                                        <Form layout="vertical" style={{marginTop:50}} onFinish={onSubmit} >
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="name"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom du rôle' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="description"
                                                        label="description"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>
                                                <Col span={12}>
                                               
<h3 className="av-heavy colprim fs18"> Administration </h3>

<Checkbox
            checked={back_office.allow_access_backoffice}
            onChange={changeBack}
          >
            <span>Allow access to the Backoffice</span><br/>
            <span>This setting allows the user to access all resources in the Backoffice</span>
          </Checkbox>




          <h3 className="av-heavy colprim nomarbottom"> Permission sur les produits </h3>

          <Checkbox
            checked={back_office.allow_access_backoffice}
            disabled={!back_office.allow_access_backoffice}
          >
            <span className="fs16">Produits/ categories / marques / fournisseurs</span><br/>
            <span>This setting allows the user to view the registered products and descriptions on the phone.</span>
          </Checkbox>


                                                </Col>



                                                <Col span={12}>

                                                <h3 className="av-heavy colprim"> Point de vente (caisse) </h3>
                                                 
                                                </Col>
                                            </Row>               
          


                                            <Form.Item className="w100 abs " style={{bottom:0,right:0}}>




                                                    <Divider/>
                                                    <div style={{textAlign:'right'}}>
                                                        <Button onClick={()=>setVisible(false)} size={'large'} className="rad8 u-pad-horizontal-l" style={{ marginRight: 12 }}>
                                                            Annuler
                                                        </Button>
                                                        <Button  size={'large'} loading={loading} type="primary" htmlType="submit" className="rad8 u-pad-horizontal-l" type="primary">
                                                            Enregistrer
                                                        </Button>
                                                    </div>



                                            </Form.Item>




                                        </Form>







                                </div>




    );


}
