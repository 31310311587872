
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import {useHistory} from "react-router";
import Details from './details';
moment.locale('fr')

const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Reports () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const history = useHistory()
    const [sales,setSales] = useState(null)
    const [factureId,setFactureId] = useState(null)

    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));


    useEffect(() => {


        const getSales = async () => {

try {
    const result = await axios.get(URL+'/sales', {
        headers: {
            'tenant_user_api_key': token             
        }
            })
    setSales(result.data.data);
    setLoading(false)

} catch (e) {
   if(e.response.status == 401){
       Logout()
   }
}



        };




        getSales();


    }, [reload]);



    const change  =  (r)  => {
console.log(r._id)
setVisible(true)
setFactureId(r._id)

    }




    const columns = [

        {
            title: 'Client',
            dataIndex: '',

            render: item =>
            <>

{item.customer  != null &&

<div className="flex itemcenter">
<svg viewBox="0 0 200 200" width="30" height="30"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>




<div class="flex column u-mar-left-s">
  
    
        <span className="coltext  av-heavy fs13"> {item.customer.name}</span>
 
<span className="coltext fs13"> {item.customer.lastname}</span>

</div>

</div>

}

            </>

    
        },
        {
            title: 'Date et heure',
            dataIndex: '',
            render: item =>
                <>
 {moment(item.createdAt).format('d MMM Y HH:m')}
                </>

        },

        {
            title: 'Montant',
            dataIndex: '',
            width: 30,
            render: item =>
                <>
<NumberFormat value={item.total}  displayType={'text'} thousandSeparator={true}  />
Fcfa
                </>

        },



    
        {
            title: 'Tunnel',
            dataIndex: 'pos',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },



        {
            title: 'Vendeur',
            dataIndex: '',
            width: 150,

            render: item =>
            <>

{item.user  != null &&

<div className="flex itemcenter">
<svg viewBox="0 0 200 200" width="30" height="30"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>




<div class="flex column u-mar-left-s">
  
    
        <span className="coltext  av-heavy fs13"> {item.user.firstname}</span>
 
<span className="coltext fs13"> {item.user.lastname}</span>

</div>

</div>

}

            </>

    
        },



    ];







    return (





  
        <Layout className=" bgapp u-pad-horizontal-l">

        <div className="u-mar-top-s">

                <Layout className="bgapp" >
                    <LeftSide />
                    <Content  className="bgwhite box-shadow u-pad-m">


                        <div className="flex column">


                        <h1 className="fs24 color-0d0 av-heavy u-mar-top-xs ">Rapports</h1>

                            <div className="flex u-mar-top-xs page-tab">
                            <div className="bmc-dash-pill p-relative cursor-pointer cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16"> Ventes</span>
                                <Link to={'/reports/sales'} className="ab-link t-0">
                                </Link>
                            </div>


                            <div className="bmc-dash-pill p-relative cursor-pointer bmc-dash-pill-active cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16"> stock</span>
                                <Link to={'/reports/stock'} className="ab-link t-0">
                                </Link>
                            </div>


                            <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">approvisionnement</span>
                                <Link to={'/reports/orders'} className="ab-link t-0">
                                </Link>
                            </div>


                            <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16"> cloture de caisse</span>
                                <Link to={'/reports/terminal'} className="ab-link t-0">
                                </Link>
                            </div>


                            <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16"> sur les réductions</span>
                                <Link to={'/reports/discounts'} className="ab-link t-0">
                                </Link>
                            </div>


                         




                            </div>

                            <Spin tip="Chargment..." size="large" spinning={loading }>

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                    <Input placeholder="Rechercher dans la table" style={{minWidth:'250px',height:'35px!important'}} />

                                </div>

                                


                                    <Drawer

                                        width={510}
                                        onClose={()=>setVisible(false)}
                                        visible={visible}
                                        closable={false}
                                        bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
                                        destroyOnClose={true}

                                    >
{ <Details id={factureId} onClose={()=>setVisible(false)} />
 }
                                    </Drawer>



                                </div>

                                <Table columns={columns} dataSource={sales}

                                       onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>

                            </Spin>


                        </div>


                    </Content>
                </Layout>
        </div>
        </Layout>



    );


}
