
import React, { useState,useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import axios from "axios";

import {Button, Layout,Select,Form,Col,message, Row,Divider,Input} from 'antd';
import {URL} from "../../urlapi";
const token = localStorage.getItem('token');



const {  Content } = Layout;
const {Option} = Select;
const { TextArea } = Input;
export default function  FormCustomer ({close}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)




    async function onSubmit ( values )  {




        try {
            setLoading(true)
            const result = await axios.post('https://3m5tgjm8h8.execute-api.eu-central-1.amazonaws.com/dev/customers',
                values,{ headers: {
                        'tenant_user_api_key':  token
                    }}
            )

            close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }








    return (

                                <div className="flex  column rel h100" style={{paddingBottom: 90, overflowY: "scroll"}}>

                                    <div
                                        className="justcenter itemcenter flex column  w100 abs  w100" style={{top:0}}

                                    >

                                        <span className="av-heavy fs22"> Ajouter un client  </span>

                                        <Divider/>

                                    </div>



                                        <Form layout="vertical" style={{marginTop:80}} onFinish={onSubmit} >
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="iname"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="lastname"
                                                        label="Prénom"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>


                                                <Col span={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Téléphone"
                                                        rules={[{ required: true, message: 'entrer un numéro' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[{ type:'email', message: 'Veuillez entre l email' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                            </Row>


                                            <Row gutter={16}>





                                                <Col span={24}>

                                                    <Form.Item
                                                        name="adresse"
                                                        label="Adresse complete"
                                                    >
                                                      <TextArea
                                                          autoSize={{ minRows: 3, maxRows: 5 }}
                                                      />

                                                    </Form.Item>


                                                </Col>

                                            </Row>








                                            <Form.Item className=" fixed " style={{width: 555, bottom: 0, right: 20, backgroundColor: "white"}}>




                                                    <Divider/>
                                                    <div style={{textAlign:'right'}}>
                                                        <Button onClick={()=>setVisible(false)} size={'large'} className="rad8 u-pad-horizontal-l" style={{ marginRight: 12 }}>
                                                            Annuler
                                                        </Button>
                                                        <Button  size={'large'} loading={loading} type="primary" htmlType="submit" className="rad8 u-pad-horizontal-l" type="primary">
                                                            Enregistrer
                                                        </Button>
                                                    </div>



                                            </Form.Item>




                                        </Form>







                                </div>




    );


}
