
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Spin, Menu,Tag,Select, Table,Input,Typography} from 'antd';

import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import TabProdPlaceholder from './components/tabprodplaceholder'

import {
    useRecoilValue,
  } from 'recoil';

  import { useGetReportSalesProduct } from '../../api/api';
  import { SubNav } from '../../components/SubNav';
  import { reportNav } from '../../config/navItems';
import SelectDate from '../../components/selectdate';

import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import {useHistory} from "react-router";
import Details from './details';
import LayoutPage from '../layout';
import { activeStartDate,activeEndDate } from "../../store";


moment.locale('fr')
const { Text } = Typography;


export default function  Reports () {

    
    const [searchText, setSearchText] = useState("");


    const Startdate = useRecoilValue(activeStartDate);
    const Enddate = useRecoilValue(activeEndDate);
    

    const [visibleFilter,setVisibleFilter] = useState(false)

    const history = useHistory()
    const [sales,setSales] = useState(null)



    const { data, isFetching, refetch } = useGetReportSalesProduct({}, {});


    useEffect(() => {

        setSales(data?.data.sort((a, b) => b.quantity - a.quantity));


    }, [data,Startdate,Enddate]);

    useEffect(() => {
        refetch();
      }, [Startdate,Enddate]);






    const columns = [

     
        {
            title: 'Produit',
            dataIndex: 'product',
            filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.product).toLowerCase().includes(value.toLowerCase())
}
        },
        {
            title: 'Quantité',
            dataIndex: 'quantity',
        },
        {
            title: 'Montant',
            dataIndex: 'total',
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) }
 Fcfa
            </>
        },
        {
            title: 'Benefice brut',
            dataIndex: 'gross_profit',
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) }
 Fcfa
            </>
        },
/*         {
            title: 'Marge',
            dataIndex: 'profit_margin',
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) }
%
            </>
        }, */






    ];







    return (





        <LayoutPage>
                  
                            
                  <SubNav items={reportNav}  /> 
                  <div className="container">
                            <div className="grille w100  u-mar-top-l    hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

<div className="flex grille itemcenter noneres">


<div className='w25'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par produit" style={{minWidth:'220px',height:'32px!important'}} />

</div>


<Select className='w20 u-mar-left-s'  defaultValue="produit">
                                                <option value={"produit"}>Ventes par produit</option>
                                          


                                                <option  value={"valide"} > Vente par catégorie  </option>
                                                <option  value={"critique"} > Vente par marque   </option>
                                                <option  value={"epuisé"} > Vente par vendeur   </option>
                                                <option  value={"epuisé"} > Vente par client   </option>
                                                <option  value={"epuisé"} > Vente par canal de vente   </option>

                                                </Select>
</div>
   



                                <div className="flex  itemcenter">

                                 


           <SelectDate />
                              
                              
                                </div>

 
                                </div>

                                <Table locale={{emptyText:<></>}} loading={{indicator:<TabProdPlaceholder/>,spinning:isFetching}} columns={columns} dataSource={sales}  pagination={false}

summary={(pageData) => {
   
    return (
      
      <Table.Summary fixed>
     
        <Table.Summary.Row >
          <Table.Summary.Cell index={0} colSpan={2} className='fW600'>{sales && 'Total'}</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Text className='fW600' >{ sales && Intl.NumberFormat('fr-FR').format(sales?.reduce((n, {total}) => n + total, 0))} {sales && 'Fcfa'}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Text className='fW600'>{sales && Intl.NumberFormat('fr-FR').format(sales?.reduce((n, {gross_profit}) => n + gross_profit, 0))} {sales && 'Fcfa'}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>

      </Table.Summary>
    );
  }}

                                      
                                       size="default"    />


                            </div>
                            </div>
                            


       </LayoutPage>           



    );


}
