
import React, {useEffect,Fragment, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import {
  useRecoilValue
} from 'recoil';
import { Spin, Button, Menu,message, Tabs} from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import axios from "axios";
import {tenantInfos} from '../../store';
import { useGetSale,useDeleteSale,usePaySale } from '../../api/api';




const antIcon = <LoadingOutlined style={{ fontSize: 49 }} spin />;

export default function  DetailsFacture ({id,onClose}) {
    const [loadingDel,setLoadingDel] = useState(false)
    const [loadingPay,setLoadingPay] = useState(false)
    const [facture,setFacture] = useState()
    const { data, isLoading, refetch } = useGetSale(id);

    const { mutateAsync : deleteSale } = useDeleteSale();
    const { mutateAsync : paySale } = usePaySale(id);


 const tenant =  useRecoilValue(tenantInfos)
    useEffect(() => {


      console.log(data ? data : 'ok')
    setFacture(data);



    }, [data]);








    const delSale = async () => {

      try {

        setLoadingDel(true)
          await deleteSale(id)

          message.error('Supression réussie!!')
          onClose();
        
      } catch (e) {
        console.log(e)
        alert('erreeur')
        setLoadingDel(false)
         
      }
 
              };


              const paymentSale = async () => {

                try {
          
                  setLoadingPay(true)
                    await paySale()
          
                    message.success('enregistrement payment!!')
                    onClose();
                  
                } catch (e) {
                  console.log(e)
                  
                  setLoadingPay(false)
                   
                }
                
                
                
                        };

    return (



        <div className="flex u-pad-bottom-l rad8 bgpos column"  >

        {isLoading ?

<div className="h300 flex itemcenter column  rel" >
        



<Spin indicator={antIcon} className="w100 u-mar-top-xxl" />

</div>
  
        : facture != undefined ?
        
    




<Fragment>

 <div style={{height:'65px',borderBottom:'1px solid #dee3e7'}} className="flex bgwhite itemcenter justbtw u-pad-vertical-m u-pad-horizontal-s rel">


<span onClick={()=>onClose()} className="lh0 cursor" >
<svg data-v-93bf3e8c="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><line data-v-93bf3e8c="" x1="18" y1="6" x2="6" y2="18"></line><line data-v-93bf3e8c="" x1="6" y1="6" x2="18" y2="18"></line></svg>
</span>


<span className="flex itemcenter">


  <h1 className="av-heavy fs18 nomar fs18res"> 


     {facture?.status == "complété" ? 'Details Facture ' : facture?.status == "en cours" ? 'Facture en cours' : 'Facture supprimée' }

  </h1>
</span>


{facture.payment == 'Credit' &&
    <Button danger 
            style={{color: 'green', borderColor: ' green'}}
            loading={loadingPay}
            onClick={()=>paymentSale()}
          >

        Credit payée

    </Button>
    }



   {facture.status != 'annulé' &&
    <Button danger 
            style={{color: 'red', borderColor: ' #eff4fa'}}
            loading={loadingDel}
            onClick={()=>delSale()}
          >

        Supprimer

    </Button>
    } 
</div> 


<div className="flex">

<div className="grille21 flex itemcenter u-pad-top-s  column" style={{overflow:'hidden',overflowY:'scroll'}}>





<div className="w90  block bgwhite fact rel"  id="fact"  >
<h3 style={{textAlign:'center',margin:'0'}} className="fs12" > {tenant.name} </h3>
<h3 style={{textAlign:'center',margin:'0'}} className="fs12" > {tenant.address} </h3>
<h3 style={{textAlign:'center',margin:'0'}} className="fs12"> NIU: {tenant.niu}  </h3>

<h3 style={{marginBottom:'10px',textAlign:'center',marginTop:'0'}} className="fs12"> Tel :  {tenant.phone}   </h3>
<h3 className="center fW500 fs13 " style={{textAlign:'center',margin:'0'}}> {facture?.status == "complété" ? 'FACTURE ' : 'FACTURE EN COURS' } {facture?.sale_id} </h3>
<h3 className="fs10" style={{textAlign:'center',margin:'0 0 10px  '}} > { moment(facture.createdAt).format('Do MMMM Y HH:m')} </h3>





<ul className="cart-list" style={{
listStyle: 'none',
padding: '0',
margin: '0'
}}>




{  facture?.items.map( row => 


<li class="section-cart flex itemcenter justbtw ">
   <span  className="grille2 fW500 fs13 ">
       <span style={{width:'20px'}} className="u-mar-right-xs">{row.quantity}   </span>	{row.product?.name}
</span>
<div className="flex grille itemcenter flexend" >



 <span className="fW500 fs14 ">
 {  Intl.NumberFormat('fr-FR').format(row.total) } 

   </span>
</div>
</li>
) }







</ul>




<div  style={{borderTop:'1px solid #dee3e7',display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
  paddingBottom:'2px'}}  >
<span className=" fs12 fW500"> Client </span>


{ facture.customer != null &&



                    <h3 className=" fs12 nomar" style={{margin:'0'}} >
  {facture.customer.name} {facture.customer.lastname}
</h3>


}


</div>
    
<div  style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
  paddingBottom:'2px'}}  >
<span className=" fs12 fW500"> Vendeur: </span>


{ facture.user != null &&



                    <h3 className="fs12 nomar" style={{margin:'0'}} >
  {facture.user.infos.firstname}  {facture.user.infos.lastname}
</h3>


}


</div>

<div style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
  paddingBottom:'2px'}}  >
<span className="fs12 fW500"> Réglèment </span>
<h3 className=" fs12" style={{margin:'0'}}> {facture.payment} </h3>
</div>

<div className="flex  justbtw u-pad-vertical-xs" style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
  paddingBottom:'2px'}} >
<span className="fs13"> TOTAL </span>
<h2 className="fs15 fW500" style={{margin:'0'}}> 

{  Intl.NumberFormat('fr-FR').format(facture.total) }  Fcfa</h2>
</div>



<div className="flex itemcenter justcenter">
{ tenant?.signature ? 
            <img src={tenant.signature} className=" u-mar-bottom-l" width={120} height={120} style={{borderRadius:8}}/> 
:

<></>
}
</div>

<h4 style={{textAlign:'center',margin:'0'}}>  MERCI DE VOTRE VISITE !</h4>






</div>




</div>

</div>



</Fragment>

     :
     <></>   
        }
        
        
        </div>
        


    );


}
