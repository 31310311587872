import React, { useState,useEffect } from 'react';

import {Button,Modal,Select,Spin, Form,Col,message, Row,Input} from 'antd';


import { useAddSuplier,useEditSuplier } from '../../../api/api';


const {Option} = Select;
const { TextArea } = Input;


export default function  AddSuplier ({user}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const { mutateAsync: addSuplier } = useAddSuplier();
    const { mutateAsync: editSuplier } = useEditSuplier(user?.id);

    

   


    async function onSubmit ( values )  {



        try {
            setLoading(true)

           if( user ) {
            await editSuplier(values)
        }else{
            await addSuplier(values)
        }

            setVisible(false)
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{user ? 
    <div  onClick={()=>setVisible(true)}>

<span className="fs11 u-mar-left-xxs"> 
Modifier 
</span>



</div>
:
<Button type="primary"   className="rad4" onClick={()=>setVisible(true)}>
<span className="fs13 fW500">  Ajouter un fournisseur</span>
</Button>
}



<Modal

    width={500}
    okButtonProps={{form:'suplier_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {user? 'Modifier un fournisseur' : "Ajouter un fournisseur"}  </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:"#F7F7F9" }}
    okText={user? 'Modifier' : "Ajouter un fournisseur"}  
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s" style={{ overflow: "auto"}}>

                            



<Form layout="vertical" initialValues={user? user : null} id="suplier_form"  onFinish={onSubmit} className="h100 justbtw flex column" >
  
<div>                
    <Row gutter={16}>
        <Col span={24}>
            <Form.Item
                name="name"
                label="Nom"
                rules={[{ required: true, message: 'Veuillez entrer le nom' }]}
            >
                <Input disabled={loading} />
            </Form.Item>
        </Col>

    </Row>


    <Row gutter={16}>


        <Col span={12}>
            <Form.Item
                name="phone"
                label="Téléphone"
                rules={[{ required: true, message: 'entrer un numéro' }]}
            >
                <Input  disabled={loading} />
            </Form.Item>
        </Col>

        <Col span={12}>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ type:'email', message: 'Veuillez entre l email' }]}
            >
                <Input  disabled={loading} />
            </Form.Item>
        </Col>



    </Row>


    
    <Row gutter={16}>





        <Col span={24}>

            <Form.Item
                name="address"
                label="Adresse complete"
                
            >
              <TextArea disabled={loading}
                  autoSize={{ minRows: 3, maxRows: 3 }}
              />

            </Form.Item>


        </Col>

    </Row>


</div>




<Row>


</Row>



</Form>







</div>







</Modal>

</>
) }