



import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';


import {Button, Layout, Drawer, Spin, Divider, Descriptions, Menu, Tabs, Row, Col, Table} from 'antd';

import {useHistory,useParams} from "react-router";
import { useGetEmployee } from '../../api/api';
import FormEdit from './formedit'
import { FileDoneOutlined} from '@ant-design/icons';
import {CaretDownOutlined,CloseOutlined} from '@ant-design/icons';

//import Details from '../sales/details';
//import DetailsPay from './detailspay';

const { TabPane } = Tabs;
const { Content } = Layout;
export default function  Member ({memberData,close}) {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [visiblePay,setVisiblePay] = useState(false)
    const history = useHistory()
    const [member,setCustomer] = useState(null)
    const [factureId,setFactureId] = useState(null)
    const [userId,setUserId] = useState(null)

    const { data, error, isLoading, refetch } = useGetEmployee(memberData?.id);



    useEffect(() => {

        setCustomer(data);


    }, [data]);



    const doreload = () => {
        setReload(reload+1)
        setVisiblePay(false)
    }
    const change  =  (r)  => {
        setVisible(true)
        setFactureId(r.sale_id)
        
            }

            const changeTrans = (r) => {
                setVisiblePay(true)
                setUserId(r)
            }

    const columns = [


        {
            title: 'Ref',
            dataIndex: 'ref',
            width: 50,
        },

       
        {
            title: 'Date et heure',
            dataIndex: '',
            sortOrder:'descend',
            render: item =>
                <>
 {moment(item.date).format('D MMMM Y HH:m')}
                </>

        },

        {
            title: 'Prix',
            dataIndex: 'amount_excluding_tax',
        },
        
        {
            title: 'Vendeur',
            dataIndex: 'seller',
        }
    ];

    const columnsfav = [


        {
            title: 'Operation',
            dataIndex: 'operation_type',
        },
        {
            title: 'Date et heure',
            dataIndex: '',
            sortOrder:'descend',
            render: item =>
                <>
 {moment(item.date).format('D MMMM Y HH:m')}
                </>

        },
        {
            title: 'Moyen de paiment',
            dataIndex: 'payment_type',
            

        },

        {
            title: 'Montant',
            dataIndex: 'amount',
        }
    ];







    return (





        <>






<Drawer

width={510}
onClose={()=>doreload()}
visible={visiblePay}
closable={false}
title= {<span className="av-heavy fs17"> Modifier   </span>}

bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
destroyOnClose={true}

>
{ 
<FormEdit data={memberData} />
}
</Drawer>








<div className="grille w100  u-pad-top-s  u-mar-top-m   noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">



                     
<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-s" style={{top:0,borderBottom:'1px solid #DDD',paddingBottom:10}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:24}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600"> {memberData?.infos.firstname} {memberData?.infos.lastname} </h2>








<div className="flex itemcenter abs" style={{right:24}}>






<div className="flex">

          
<div className="flex itemcenter cursor u-mar-right-s">

<Button type="danger"  className="rad8 flex u-mar-left-s justcenter itemcenter" style={{height:48}} onClick={()=>changeTrans(memberData.id)} >
              <span className="fs17 ">  Supprimer</span>
          </Button>





</div>

          <Button type="primary"  className="rad8 flex u-mar-left-s justcenter itemcenter" style={{height:48}} onClick={()=>changeTrans(memberData.id)} >
              <span className="fs17 ">  Modifier</span>
          </Button>
          </div>



</div>









</div>





                        <div className="flex column ">


                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                        

                            <Spin tip="Chargment..." size="large" className="u-mar-top-jumbo" spinning={isLoading }>


                                {member != null &&

                            <div className="grille w100  u-pad-top-s w70 mauto   u-mar-top-xl-res nopadres noradres ">





                                <Row className="u-mar-top-xl ">

                                    <Col span={17} className="hauto blok rad16 justcenter itemcenter ">

<div className="flex column justcenter itemcenter h100 ">


    <Descriptions column={3} >
        <Descriptions.Item label="Téléphone" style={{paddingLeft:35}}>
            
        <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon" style={{position:'absolute',left:-34,top:5,color:'#0050dc'}}><path  d="M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z" fill="currentColor"></path></svg>

            {member.infos.phones[0]}
            
        </Descriptions.Item>
        {  <Descriptions.Item label="Email">
        <svg style={{position:'absolute',left:-34,top:5,color:'#0050dc'}} width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon"><path data-v-20586b3a="" data-v-24a92908="" d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z" fill="currentColor"></path></svg>
            {member.app_use_settings ? member.app_use_settings.email : '--'}</Descriptions.Item> }



        { <Descriptions.Item label="Adresse">
            
            
        <svg  style={{position:'absolute',left:-34,top:5,color:'#0050dc'}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path data-v-20586b3a="" data-v-24a92908="" d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle data-v-20586b3a="" data-v-24a92908="" cx="12" cy="10" r="3"></circle></svg>

            {member.street ? member.street : '--'}
            
            
            </Descriptions.Item> }
    </Descriptions>

</div>

                                    </Col>

                                   <Col span={6} offset={1} className="noshadow blok noborder hauto rad16 ">

                                   <div className="bmc-dash-pill p-relative cursor-pointer nomar flex column itemcenter u-pad-s rel">
        <span
        className=" xs-text-fs-14 av-light fs15"> Image </span>

       
    </div>


                                   </Col>

                                </Row>


                                <Row gutter={32}>

                                <Col span={24}>



{/* 
<Tabs defaultActiveKey="1" className="clienttab">

<TabPane
        tab={
            <span>


                <FileDoneOutlined className="fs18" />
Contrat
</span>
        }
        key="1"
    >



       <Table columns={columns} className="blok w100 hauto rad16 u-pad-s"
        dataSource={member.invoices_data}
        onRow={(record) => ({
            onClick: () => { change(record) }
        })}
               size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  /> 


    </TabPane>


<TabPane
        tab={
            <span className="flex">
<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{marginRight:5}} width="24" height="24"><path data-v-20586b3a="" data-v-70078886="" fill="none" d="M0 0h24v24H0z"></path><path data-v-20586b3a="" data-v-70078886="" d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm7 4h2v8h-2V9zm4 3h2v5h-2v-5zm-8 2h2v3H7v-3z" fill="currentColor"></path></svg>
Planning
</span>
        }
        key="2"
    >
         <Table columns={columnsfav} className="blok w100 hauto rad16 u-pad-s"
dataSource={member.transactions}

               size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  /> 

    </TabPane>


</Tabs>


 */}



</Col>

</Row>
                            </div>

                                }

                            </Spin>


                        </div>


       </div>      

        </>



    );


}
