
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Drawer,Form,Col,Row,Select,Spin, Menu,Image,Tag,Divider,message, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import Formpos from './formpos';
import axios from "axios";

import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import branche from "./images/branch.png";
import terminal from "./images/sale-terminal.png";




const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Pos () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [loadingrole,setLoadingrole] = useState(true)
    const [loadingbranches,setLoadingbranches] = useState(true)
    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).accessToken;

    const [pos,setPos] = useState(null)
  

 

    useEffect(() => {

        const getPos = async () => {

            const result = await axios.get(URL+'/api/pos', {
                headers: {
                    'tenant_user_api_key':  token
                }
            })

            setPos(result.data);
            setLoading(false)
        };




        getPos();


    }, [reload]);


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }






    return (





        <Layout className=" bgapp u-pad-horizontal-l">

        <div className="u-mar-top-s">

                <Layout className="bgapp" >
                    <LeftSide />
                    <Content  className="bgwhite box-shadow u-pad-m">


                        <div className="flex column">


                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                            <div className="flex u-mar-top-xs page-tab">
                            <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Préferences</span>
                                <Link to={'/settings/preferences'} className="ab-link t-0">
                                </Link>
                            </div>


                               <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Branches</span>
                                    <Link to={'/settings/branches'} className="ab-link t-0">
                                      </Link>
                                </div>

                                <div className="bmc-dash-pill p-relative cursor-pointer bmc-dash-pill-active cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16"> point de vente </span>
                                    <Link to={'/settings/pos'} className="ab-link t-0">
                                      </Link>
                                </div>

                  
                                <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Modèles de documents</span>
                                    <Link to={'/settings/modeles'} className="ab-link t-0">
                                      </Link>
                                </div>

                            </div>

                            <Spin tip="Chargment..." size="large" spinning={loading && loadingrole && loadingbranches }>

                            <div className="grille w100  u-pad-vertical-s noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                            <div className="flex u-mar-top-s column rel h100">




{pos &&



<div  className="branch-list">
  
       
       
       {pos.map(pos => 

<div   class="branch"><div  class="top">
<img  src={pos.logo ? pos.logo : terminal} className="image"/>
<h5  className="title u-text-ellipsis nomar">{pos.businessName}</h5>
</div><div  class="linebranch"></div><div  className="bottom"><img  src={branche} className="images" />
<div  class="terminal"><span  className="u-color-gray">{pos.branch.businessName}</span></div></div><button  type="button" className="more-switch"><svg  width="470px" height="470px" viewBox="0 0 470 470" version="1.1" class="icon"><g  id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g  id="editt" fill="currentColor" fill-rule="nonzero"><path  d="M438.931,30.403 C398.531,-10.097 332.831,-10.097 292.431,30.403 L23.831,298.903 C21.731,301.003 20.431,303.703 20.031,306.603 L0.131,454.003 C-0.469,458.203 1.031,462.403 3.931,465.303 C6.431,467.803 9.931,469.303 13.431,469.303 C14.031,469.303 14.631,469.303 15.231,469.203 L104.031,457.203 C111.431,456.203 116.631,449.403 115.631,442.003 C114.631,434.603 107.831,429.403 100.431,430.403 L29.231,440.003 L43.131,337.203 L151.331,445.403 C153.831,447.903 157.331,449.403 160.831,449.403 C164.331,449.403 167.831,448.003 170.331,445.403 L438.931,176.903 C458.531,157.303 469.331,131.303 469.331,103.603 C469.331,75.903 458.531,49.903 438.931,30.403 Z M297.631,63.403 L342.731,108.503 L97.631,353.603 L52.531,308.503 L297.631,63.403 Z M160.931,416.803 L116.831,372.703 L361.931,127.603 L406.031,171.703 L160.931,416.803 Z M424.831,152.403 L316.931,44.503 C330.631,33.203 347.731,27.003 365.731,27.003 C386.231,27.003 405.431,35.003 419.931,49.403 C434.431,63.803 442.331,83.103 442.331,103.603 C442.331,121.703 436.131,138.703 424.831,152.403 Z" id="Shape"></path></g></g></svg></button>
</div>


       )  
       


}



<button  className="new" type="button" onClick={()=>setVisible(true)} >
    <svg  width="26px" height="26px" viewBox="0 0 26 26" stroke="currentColor" className="icon">
        <g  stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g  transform="translate(-844.000000, -155.000000)" stroke-width="2"><g ><g  transform="translate(507.000000, 30.000000)"><g  transform="translate(1.000000, 100.000000)"><g ><g  transform="translate(337.000000, 26.000000)"><circle  cx="12" cy="12" r="12"></circle><path  d="M12,7.2 L12,16.8" id="Shape"></path><path  d="M7.2,12 L16.8,12" id="Shape"></path></g></g></g></g></g></g></g>
        </svg>
        
        <div  class="title">Ajouter un point de vente</div></button>


</div>




}


<Drawer

width={600}
onClose={()=>setVisible(false)}
visible={visible}
bodyStyle={{ paddingBottom: 0,paddingTop:12 }}
destroyOnClose={true}

>

<Formpos  close={close}/>

</Drawer>




</div>

                            </div>

                            </Spin>


                        </div>


                    </Content>
                </Layout>
            </div>
        </Layout>



    );


}
