import React from 'react';
import { useMediaQuery } from 'react-responsive'


import {Layout} from 'antd';
import Header from './header';
import {WifiOutlined} from '@ant-design/icons';

import { onlineManager } from 'react-query'

const {  Content} = Layout;





export default function LayoutPage ({children}) {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 993px)' })
  const isOnline = onlineManager.isOnline()



    return  <Layout className="bgapp" >
            
                <Content  >
                
<Header />

                    <div className="flex column u-mar-top-xl  nomarrighttab nomarright  u-pad-horizontal-xxs-res content">


                    { 1==1 ? children : <div className="h100 flex itemcenter w100 justcenter column"> <WifiOutlined className="fs40 u-mar-bottom-s"/> <h3 className="fW500">Erreur Réseau, veuillez Actualiser</h3></div> }


                      </div>

                </Content>

            </Layout>

    


    
  
    
 
}
