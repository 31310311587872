
import React, {useEffect,useRef, useCallback,useState,Fragment} from 'react';




export default function  CustomerPlaceholder () {








    return (

      <div className="u-mar-top-l">
                   
{Array(10).fill("").map((a) => 

<div class="flex itemcenter justbtw" style={{height:'42px',borderBottom: '1px solid #f0f0f0'}}>
                      
                      <div style={{width:'250px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop: '3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      
                      <div style={{width:'205px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop: '3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      <div style={{width:'205px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop: '3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      
                      <div style={{width:'255px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop:'3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      
                      <div style={{width:'150px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop:'3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>

                      <div style={{width:'55px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px',marginTop:'3px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      


                      </div>

 )}



                         
</div>
    );


}
