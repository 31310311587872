import  React , {Fragment, useEffect, useState} from "react";
import {Upload, Layout, Form, Col, Row,Descriptions,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import NumberFormat from "react-number-format";
import moment from 'moment';
import 'moment/locale/fr';


const {Option} = Select;
export default function Step3Formpur({ onSuccess,data,onBack,loading }) {


  return (
  

    <Fragment>

<Row gutter={32}>

<Col span={24} className="u-pad-vertical-s u-mar-bottom-s rad8" style={{backgroundColor:'#eff3f9'}}>

<Descriptions column={4} layout="vertical">
        <Descriptions.Item label="Note">{data.description}</Descriptions.Item> 
    </Descriptions>

</Col>

<Col span={24}>
    




<div>
<div className="flex justbtw u-pad-vertical-xs u-pad-horizontal-xs " style={{backgroundColor:'#eff3f9'}}>
  
  <span className="grille3"> Produits/ingrédients </span>
  <span className="grille right"> Qté </span>
  <span className="grille right"> P.U</span>
  <span className="grille right"> Total</span>
   </div>



   <Fragment>
  
      
       <ul class="cart-list" style={{height:'300px'}}>
      
       {
       
       
       data.prod.map( row => 
 <li key={row._id} class="section-cart flex itemcenter justbtw  u-pad-left-xs " style={{height:'38px'}}>
                     <span  className="grille3 av-heavy fs14 ">
           {row.name}
 </span>

 <span  className="grille av-heavy fs14 right">
           {row.num}
 </span>

 <span className="av-heavy fs14 grille right">
                 {  Intl.NumberFormat('fr-FR').format(row.supply_price) }

                     </span>


                   <span className="av-heavy fs14 grille right u-pad-right-xs">
                   {  Intl.NumberFormat('fr-FR').format((row.num ? row.num : 1 )*row.supply_price) }

                     </span>
                  
             </li>
 ) }

<span className="right w100 av-heavy u-mar-top-s" style={{display:'block'}}> TOTAL :  {  Intl.NumberFormat('fr-FR').format(data.total) } Fcfa</span>

      
       </ul>
       </Fragment>

</div>
</Col>




</Row>

<div className="flex justbtw itemcenter w100 u-mar-top-l">

      <span className="cursor fs14 fw600 flex itemncenter" onClick={() => onBack()} style={{color:"#1f59ed"}}>
           
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#1f59ed" className="h100" ><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></svg>
            Retour
          </span>



        <Button type="primary" loading={loading}  className="  u-pad-horizontal-xl rad4 flex justcenter" onClick={()=>onSuccess(data)}>
          Valider
        </Button>
        </div>


</Fragment>
   



  );
}
