
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import {
    useRecoilState
  } from 'recoil';
  import UploadWidget from '../../components/UploadWidget';

import {Button, Layout,Drawer,Form,Col,Row,Select,Spin, Menu,Image,Tag,Divider,message, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import Formemploye from './form';
import axios from "axios";
import LayoutPage from '../layout';
import { useGetUserInfos,useUpdatesUser, useUpdateUser,useUpdatePwd } from '../../api/api';
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";




const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Compte() {

    const [loading,setLoading] = useState(false)
    const [loadingpwd,setLoadingPwd] = useState(false)
    const [reload,setReload] = useState(1)

    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const [user,setUser] = useState(null)



    const [url, updateUrl] = useState();
    const [errored, updateError] = useState();
  
    /**
     * handleOnUpload
     */
  
    const  handleOnUpload =   async (error, result, widget) => {
      if ( error ) {
        updateError(error);
        widget.close({
          quiet: true
        });
        return;
      }
      await update({image:result?.info?.secure_url});
     
      setUser({...user,image:result?.info?.secure_url});

    }
    


    const { data, error, isLoading} = useGetUserInfos();

    const { mutateAsync: update } = useUpdateUser();
    const { mutateAsync: updatePwd } = useUpdatePwd();
    useEffect(() => {
        setUser(data);


      }, [data]);
    


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }


    const onSubmit = async (data) => {
      setLoading(true)
        const respo = await update(data);
        setUser(respo)
        setLoading(false)
        message.success('Enregistrement réussi!!')
      };




      const onSubmitPwd = async (data) => {

        try {
          setLoadingPwd(true)
          const respo = await updatePwd(data);
          setLoadingPwd(false)
          message.success('Enregistrement réussi!!')
        }catch(e) {
          console.log(e.response)
          setLoadingPwd(false)
          message.warning(e.response.data?.message)

      }
          
        };



    return (





        <LayoutPage>

                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                            <Spin tip="Chargment..." size="large" spinning={isLoading}>

                        
                            <div className="grille w100  flex column noshadow noborder hauto rad16 u-mar-top-xl-res nopadres itemcenter justcenter">


<div className="block w30 cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >


<div
className="  flex column  w100  " style={{top:0}}

>

<h3 className="fW600 fs20 colprim u-pad-left-l  u-mar-top-s"> Mon Profil </h3>



</div>


{user &&
<Form layout="vertical"  className="u-pad-horizontal-l" initialValues={{firstname:user.infos.firstname, lastname:user.infos.lastname,  address:user.infos.address,phone:user.infos.phones[0]}}  onFinish={onSubmit} >

<Row gutter={16}>


<Col span={6} className="u-mar-top-s u-mar-bottom-s">



<div className="flex column itemcenter">




{ user?.image &&
<p><img src={user?.image} className="w100" alt="Uploaded image" /></p>
}

{errored && <p>{ errored }</p>}


<UploadWidget onUpload={handleOnUpload} multiple={false}>
{({ open }) => {
function handleOnClick(e) {
e.preventDefault();
open();
}
return (
user?.image ?



<span className="fs15 fW600 cursor  coltext" onClick={handleOnClick}> 

Modifier 
</span>








:



<div className="flex itemcenter column  cursor" >



<svg viewBox="0 0 200 200" width="95" height="95"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>






<span className="fs15 fW500 cursor u-mar-top-xs coltext" onClick={handleOnClick}> 
     
     Modifier 
      </span>

</div>

)
}}
</UploadWidget>

<Divider className='u-mar-vertical-xs'/>


<span className="fs12">{user?.infos.email}</span>


<div className="flex u-mar-vertical-xs">
{user?.infos.branches.map(branch =>(

<span className="btnbranch"> {branch.business_name}</span>


))}

</div>

<span className="fs12 fW500 coltext">{user?.infos.role.name}</span>



</div>





</Col>

<Col span={18}>


<Row gutter={16}>



<Col span={12} className="u-mar-top-l">
<Form.Item
name="firstname"
label="Nom"
rules={[{ required: true, message: 'Veuillez entre le nom' }]}
>
<Input  />
</Form.Item>
</Col>


<Col span={12} className="u-mar-top-l">
<Form.Item
name="lastname"
label="Prénom"
>
<Input  />
</Form.Item>
</Col>



<Col span={12}>
<Form.Item
name="phone"
label="Téléphone"
rules={[{ message: 'entrer un numéro' }]}
>
<Input placeholder="téléphone " />
</Form.Item>
</Col>

<Col span={12}>
<Form.Item
name="address"
label="Adresse"
rules={[{  message: 'Veuillez entre le nom' }]}
>
<Input  />
</Form.Item>
</Col>

</Row>



<Row gutter={16}>






<Col className="flex flexend w100">

<Form.Item className="w100">




<div style={{textAlign:'right'}} className="u-mar-top-xs">

<Button   loading={loading} type="primary" htmlType="submit" className="rad4 u-pad-horizontal-l" >
Enregistrer
</Button>
</div>



</Form.Item>

</Col>


</Row>




</Col>




</Row>














</Form>
}






</div>





<div className="block w30 cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >


<div
className="  flex column  w100  " style={{top:0}}

>

<h3 className="fW600 fs20 colprim u-pad-left-l  u-mar-top-s"> Changer de mot de passe</h3>



</div>


{user &&
<Form layout="vertical"  className="u-pad-horizontal-l" onFinish={onSubmitPwd} >

<Row gutter={16}>



<Col span={24}>


<Row gutter={16}>



<Col span={24} className="u-mar-top-s">
<Form.Item
name="oldpassword"
label="Ancien mot de passe"
rules={[{ required: true, message: 'Requis' }]}
>
<Input  />
</Form.Item>
</Col>

<Col span={24}>
<Form.Item
name="newpassword"
label="Nouveau mot de passe"
rules={[{required: true, message: 'Requis' }]}
>
<Input />
</Form.Item>
</Col>


</Row>



<Col className="flex flexend w100">

<Form.Item className="w100">




<div style={{textAlign:'right'}} className="u-mar-top-xs">

<Button   loading={loadingpwd} type="primary" htmlType="submit" className="rad4 u-pad-horizontal-l" >
Enregistrer
</Button>
</div>



</Form.Item>

</Col>


</Col>




</Row>














</Form>
}






</div>


</div>




                            </Spin>


              </LayoutPage>



    );


}
