
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';


import {Button, Layout,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import Masonry from 'react-masonry-css'
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import {useHistory} from "react-router";
import DetailsSale from './detailssale';
import { useGetParkSales } from '../../api/api';
import { useMediaQuery } from 'react-responsive'
const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  SalesOn () {

    const [loading,setLoading] = useState(true)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const history = useHistory()
    const [sales,setSales] = useState(null)
    const [factureId,setFactureId] = useState(null)

    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const iduser = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).id;

    const { data, error, isLoading } = useGetParkSales();


  useEffect(() => {
      setSales(data?.data);
    }, [data]);






    return (





  
        <Layout className=" bgapp u-pad-horizontal-l nopadres">

        <div className="u-mar-top-s">

                <div className="bgapp" >
                  
                    <div  className="rad8  u-pad-m">


                        <div className="flex column">


                        <div style={{height:'70px',borderBottom:'1px solid #dee3e7'}} className="flex bgwhite itemcenter justbtw  w100 z999 u-pad-right-s">

<Link to={'/pos'} className="flex  itemcenter justcenter cursor" style={{width:'85px',height:'100%'}}>

<svg xmlns="http://www.w3.org/2000/svg" width="27" height="39" viewBox="0 0 17 19">
    <g fill="none" fill-rule="nonzero">
        <path stroke="#000" stroke-width="1.2" d="M1.32 7.652l6.5-6.022a1 1 0 0 1 1.36 0l6.5 6.022a1 1 0 0 1 .32.733V17a1 1 0 0 1-1 1h-3.625a1 1 0 0 1-1-1v-2a1 1 0 0 0-1-1h-1.75a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8.385a1 1 0 0 1 .32-.733z"/>
        <path fill="#000" fill-opacity=".097" d="M2.987 9.44L7.82 4.964a1 1 0 0 1 1.36 0l4.833 4.478a1 1 0 0 1 .32.733v6.284a1 1 0 0 1-1 1h-2.375a1 1 0 0 1-1-1V15a1 1 0 0 0-1-1h-.916a1 1 0 0 0-1 1v1.458a1 1 0 0 1-1 1H3.667a1 1 0 0 1-1-1v-6.284a1 1 0 0 1 .32-.733z"/>
    </g>
</svg>

</Link>

  <h1 className="av-heavy fs25">Mes ventes en cours </h1>


<Link to={'/pos'} >
    <svg     width="35" height="35" viewBox="0 0 24 24" fill="#FFF" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line  x1="18" y1="6" x2="6" y2="18"></line><line data-v-06ae59ca="" x1="6" y1="6" x2="18" y2="18"></line></svg>
</Link>

</div>

                            <Spin tip="Chargment..." size="large" spinning={isLoading }>


                            <Masonry
  breakpointCols={isMobile? 1 : 5}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">

{

sales?.map(sale => 

    <DetailsSale sale={sale} />


)


}

</Masonry>


                        

                            </Spin>


                        </div>


                    </div>
                </div>
        </div>
        </Layout>



    );


}
