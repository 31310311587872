import  React , {Fragment, useEffect, useState} from "react";
import {Form, Col, Row, Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';

import moment from 'moment';
import 'moment/locale/fr';
import FormItem from "antd/es/form/FormItem";


const {Option} = Select;
const { TextArea } = Input;
export default function Step1Form({form,users }) {
    const [visibleClient,setVisibleClient] = useState(false)

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
      };

      const format = 'HH:mm';

   

  

  return (
      <>
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
    >
    


    <Fragment>

        <Row gutter={16}>

        <Col span={24} >

    <div className="blok rad16  w100 hauto">
   
<Row gutter={16} >
<Col span={12}>
<Form.Item
name="month"
label="Mois"
className="rel"
rules={[{
required: true,
message: 'Selectionner un mois'
}]}
>
<Select showSearch allowClear 
placeholder="Selectionner le mois"
optionFilterProp="children"

>

<Option  value={'Janvier'} > Janvier </Option>
<Option  value={'Février'} > Février </Option>
<Option  value={'Mars'} > Mars </Option>
<Option  value={'Avril'} > Avril </Option>
<Option  value={'Mai'} > Mai </Option>
<Option  value={'Juin'} > Juin </Option>
<Option  value={'Juillet'} > Juillet </Option>
<Option  value={'Août'} > Août </Option>
<Option  value={'Septembre '} > Septembre </Option>
<Option  value={'Octobre'} > Octobre </Option>
<Option  value={'Novembre'} > Novembre </Option>
<Option  value={'Decembre'} > Decembre </Option>

</Select>
</Form.Item>
</Col>



<Col span={12}>
<Form.Item
name="year"
label="Année"
className="rel"
rules={[{
required: true,
message: 'Selectionner une année'
}]}
>
<Select showSearch allowClear 
placeholder="Selectionner une année"
optionFilterProp="children"

>

<Option  value={'2023'} > 2023 </Option>
<Option  value={'2024'} > 2024 </Option>
<Option  value={'2025'} > 2025 </Option>


</Select>
</Form.Item>
</Col>

</Row>


 
<Col span={24} className="nomar">
    



    <h3 className="fW600 nomar">Equipe</h3>
    
    <div>
    <div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s" style={{borderTop: '1px #f2f2f2 solid',
        borderBottom: '1px #b3b3b3 solid'}}>  
      <span className="grille fW500"> Nom </span>
      <span className="grille fW500 right" style={{marginRight:'15px'}}> Fonction</span>
      <span className="grille  fW500 right"> Objectif</span>
       </div>
    
    
    
       <Fragment>
      
          
           <ul class="cart-list" style={{height:'auto',overflowY:'auto'}}>
          
           {  users != null &&

users.map(user=>(
      
     <li key={user.id} class="section-cart flex itemcenter justbtw  u-pad-left-xs " style={{height:'65px'}}>
                         <span  className="grille av-heavy fs14 ">
               {user.infos.firstname} {user.infos.lastname}
     </span>
    
     <span  className="grille av-heavy fs14 right">
     {user.infos.roles[0]}
     </span>
    
     <span className="av-heavy fs14 grille right">
   
   <FormItem style={{height:'32px!important'}}> <Input className="w50" /> </FormItem>
    
                         </span>
    
    
              
                 </li>
     ) ) }
    
    
          
           </ul>
           </Fragment>
    
    
    
    </div>
    </Col>
    



    </div> 


   

</Col>



</Row>
                                      

                                             



                                           



                                           </Fragment>
                                           







 
    </Form>
  
  




  </>
  );
}
