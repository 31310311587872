/**
 * Created by sinclaireric on 19/02/2018.
 */



import React, {useEffect,Fragment,useRef, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Divider, Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import axios from "axios";
import {Helmet} from 'react-helmet';
import { useReactToPrint } from "react-to-print";
import {  useRecoilValue } from 'recoil';

import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import {useHistory} from "react-router";
import Details from '../sales/details';
import { userInfos } from '../../store';

const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Sales () {

  const User = useRecoilValue(userInfos);
    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const history = useHistory()
    const [sales,setSales] = useState(null)
    const [factureId,setFactureId] = useState(null)
    const componentRef = useRef();
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));
    const iduser = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).id;


    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });


    useEffect(() => {


        const getSales = async () => {
try {
  const result = await axios.get(URL+'/sales/amounts?user='+iduser+'&start_date=today', {

        headers: {
            'tenant_user_api_key': token             
        }
            })
    setSales(result.data);
    setLoading(false)

} catch (e) {
  setLoading(false)
   if(e.response.status == 401){
       Logout()
   }
}



        };




        getSales();


    }, [reload]);



    const change  =  (r)  => {
console.log(r._id)
setVisible(true)
setFactureId(r._id)

    }







    return (





  
        <Layout className=" bgapp bgwhiteres h100 u-pad-horizontal-l nopadres">

        <div className="u-mar-top-s nomarres">

                <div className="bgapp" >
                  
                    <div  className="bgwhite rad8 box-shadow u-pad-m nopadres">


                        <div className="flex column">


                        <div style={{height:'70px',borderBottom:'1px solid #dee3e7'}} className="flex bgwhite itemcenter justbtw  w100 z999 u-pad-right-s">

<Link to={'/pos'} className="flex  itemcenter justcenter cursor" style={{width:'85px',height:'100%'}}>

<svg xmlns="http://www.w3.org/2000/svg" width="27" height="39" viewBox="0 0 17 19">
    <g fill="none" fill-rule="nonzero">
        <path stroke="#000" stroke-width="1.2" d="M1.32 7.652l6.5-6.022a1 1 0 0 1 1.36 0l6.5 6.022a1 1 0 0 1 .32.733V17a1 1 0 0 1-1 1h-3.625a1 1 0 0 1-1-1v-2a1 1 0 0 0-1-1h-1.75a1 1 0 0 0-1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8.385a1 1 0 0 1 .32-.733z"/>
        <path fill="#000" fill-opacity=".097" d="M2.987 9.44L7.82 4.964a1 1 0 0 1 1.36 0l4.833 4.478a1 1 0 0 1 .32.733v6.284a1 1 0 0 1-1 1h-2.375a1 1 0 0 1-1-1V15a1 1 0 0 0-1-1h-.916a1 1 0 0 0-1 1v1.458a1 1 0 0 1-1 1H3.667a1 1 0 0 1-1-1v-6.284a1 1 0 0 1 .32-.733z"/>
    </g>
</svg>

</Link>

  <h1 className="av-heavy fs25">Mon Rapport de vente  </h1>


<Link to={'/pos'} >
    <svg     width="35" height="35" viewBox="0 0 24 24" fill="#FFF" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line  x1="18" y1="6" x2="6" y2="18"></line><line data-v-06ae59ca="" x1="6" y1="6" x2="18" y2="18"></line></svg>
</Link>

</div>

                            <Spin tip="Chargment..." size="large" spinning={loading }>

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                              
















{ sales != null ?
  <Fragment>



{ /* liste des ticket de session */ }
<div className="flex column w100 u-pad-horizontal-jumbo nopadres itemcenter justcenter" >




  <div className="w100 u-pad-horizontal-s flex itemcenter justcenter column u-mar-top-s" ref={componentRef}>


  <h3 className="fs14 colhead ultralightfibra nomar " > {moment().format('dddd , Do MMMM  YYYY  HH:m')}    </h3>
  <h3 className="fs14 colhead ultralightfibra nomar " > Par : {User.infos.firstname}  {User.infos.lastname}   </h3>



  <h1 className="fs40  colhead av-heavy u-pad-horizontal-l w100 center u-mar-top-xxs u-mar-bottom-xs" style={{borderBottom:'1px solid rgb(4 61 118)',lineHeight:'1.5'}}>


  {  Intl.NumberFormat('fr-FR').format(sales.amount != null ? sales.amount : '---')}
    <span className="fs16"> Fcfa</span>  </h1>





  <div className="flex w100 column itemcenter">

  <h3 className="u-mar-top-s av-heavy fs14 colhead "> ENCAISSEMENTS </h3>




    { sales.payments.map(u=>(
 

      <div className="flex w100 justbtw " style={{lineHeight:'1.5'}}>
      <h2 className="fs14 av-heavy u-mar-vertical-xxs colhead" > {u.name}</h2>

      <h2 className="fs14 u-mar-vertical-xxs av-heavy" style={{color:'#000'}}>

             {  Intl.NumberFormat('fr-FR').format(u.total) }
    <span className="fs10"> Fcfa</span>
    </h2>
      </div>



    ))
    }

<Divider/>
<h3 className=" av-heavy fs14 colhead "> VENTES PAR CATEGORIES </h3>

{ sales.sales_by_categories.map(u=>(
 

 <div className="flex w100 justbtw " style={{lineHeight:'1.5'}}>
 <h2 className="fs14 av-heavy u-mar-vertical-xxs colhead" > {u.category}</h2>

 <h2 className="fs14 u-mar-vertical-xxs  av-heavy" style={{color:'#000'}}>

        {  Intl.NumberFormat('fr-FR').format(u.total) }
<span className="fs10"> Fcfa</span>
</h2>
 </div>
 



))
}




<Divider/>
<h3 className=" av-heavy fs14 colhead "> VENTES PAR PRODUITS </h3>

{ sales.sales_by_products.map(u=>(
 

 <div className="flex w100 justbtw " style={{lineHeight:'1.5'}}>
 <h2 className="fs14 av-heavy u-mar-vertical-xxs colhead" > {u.product}</h2>

<div className="flex justbtw w40">
 <h2 className="fs13 u-mar-vertical-xxs  av-heavy" style={{color:'#000'}}>

   Qte:     {  Intl.NumberFormat('fr-FR').format(u.quantity) }
<span className="fs10"> </span>
</h2>

 <h2 className="fs13 u-mar-vertical-xxs  av-heavy" style={{color:'#000'}}>

   Total:     {  Intl.NumberFormat('fr-FR').format(u.total) }
<span className="fs10"> Fcfa</span>
</h2>
</div>
 </div>
))
}

  </div>

  </div>


<div className="w100 flex itemcenter justcenter u-mar-top-l u-pad-s">
  {0 == 0 ?
<div  className="btn w40 cursor btn-blue w100res btn-pos av-heavy fs15" onClick={()=>handlePrint()}>
GENERER MON RAPPORT DE VENTE
</div>

:
<button className="btn w40 btn-blue btn-pos boldfibra fs12 btn-disable " style={{color:'#333'}}>
Vous avez 23 ticket en cours
</button>

}


</div>



 










</div>

</Fragment>

:


<Fragment>

</Fragment>

}

























                           
                            </div>

                            </Spin>


                        </div>


                    </div>
                </div>
        </div>
        </Layout>



    );


}
