








import React, {Fragment, useState,useEffect,useRef} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { useReactToPrint } from "react-to-print";


import {Col, Row,Button,Steps,Spin,Descriptions} from 'antd';
import Print from './printOrder';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link,useParams } from "react-router-dom";

import { useGetOrder } from '../../api/api';




const { Step } = Steps;

export default function  OrderInfo () {

    let { id } = useParams();
    const [order,setOrder] = useState()

    const { data, isLoading, refetch } = useGetOrder(id);

    const componentRef = useRef();


    


 
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
      });



      useEffect(() => {


        console.log(data ? data : 'ok')
        setOrder(data);
  
  
  
      }, [data]);
  


    return (






                            <div className="flex  column rel h100 bgwhite">





                          



{/* <div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s" style={{top:0,borderBottom:'1px solid #DDD',paddingBottom:10}}>







<div className="flex itemcenter">



<Button type="primary" size="large"  className="rad8" >
    <span className="fs16 av-heavy">  Noter la commande </span>
</Button>

</div> 




</div> */}

        








                       

                            <Spin tip="Chargment..." size="large" spinning={isLoading}>



{order &&
<>
    <Print ref={componentRef} order={order} /> 
                                <Row gutter={32}  className=" w100 u-mar-top-xl u-pad-horizontal-s   h100  u-mar-top-s-res nomarres nopadres noradres ">

                                <Col    xs={{
        span: 24,
        offset: 0,
      }}
      md={{
        span: 12,
        offset: 6,
      }}   className="u-pad-top-s  flex justcenter u-pad-horizontal-s   h100  u-mar-top-s-res nopadres noradres ">


                                    <div className="flex u-mar-left-m  column rad8  bgwhite  nomarres u-pad-s-res" style={{height:'max-content'}}>



                                   
  
                                           
                                           
                                           
                                       
                                    
    <Fragment>

<Row gutter={32}>

<Col span={24} className="u-pad-vertical-s u-mar-bottom-s rad8" style={{backgroundColor:'#eff3f9'}}>

<h2 className="fW600 flex itemcenter"> commande  {order.order_id}

{ order.status == "Livrée" ? <span className="u-pad-xxs graystatus rad4 fs14 u-mar-left-xs"> {order.status} </span> : order.status == "Non traitée" ? <span className="u-pad-xxs graystatus rad4 u-mar-left-xs fs14"> {order.status} </span> : order.status == "Traitée" ? <span className="u-pad-xxs bluestatus u-mar-left-xs rad4 fs14"> {order.status} </span> : order.status == "Livraison en cours" ? <span className="u-pad-xxs yellowstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> : <span className="u-pad-xxs redstatus rad4 fs14 u-mar-left-xs"> {order.status} </span> }

 </h2>


<Descriptions column={2} layout="vertical">



<Descriptions.Item label="Client">{order?.sale.customer.name }</Descriptions.Item> 
           <Descriptions.Item label="Date/heure de livraison">{moment(order?.delivery_date).format('D MMM Y HH:m')}</Descriptions.Item> 

           <Descriptions.Item label="adresse de livraison">{order.address}</Descriptions.Item> 


        <Descriptions.Item label="Note">{order.note}</Descriptions.Item> 
    </Descriptions>

</Col>

<Col span={24} className="nomar">
    



<h3 className="fW600 nomar">Produits</h3>

<div>
<div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s" style={{borderTop: '1px #f2f2f2 solid',
    borderBottom: '1px #b3b3b3 solid'}}>  
  <span className="grille3 fW500"> Produits</span>
  <span className="grille07 fW500 right" style={{marginRight:'15px'}}> Qté </span>
  <span className="grille07  fW500 right"> P.U</span>
  <span className="grille  fW500 right"> Total</span>
   </div>



   <Fragment>
  
      
       <ul class="cart-list" style={{height:'auto',overflowY:'auto'}}>
      
       {
       
       
       order.sale.items.map( row => 
 <li key={row._id} class="section-cart flex itemcenter justbtw  u-pad-left-xs " style={{height:'38px'}}>
                     <span  className="grille3 av-heavy fs14 ">
           {row.product.name}
 </span>

 <span  className="grille av-heavy fs14 right">
           {row.quantity}
 </span>

 <span className="av-heavy fs14 grille right">
                 {  Intl.NumberFormat('fr-FR').format(row.price
) }

                     </span>


                   <span className="av-heavy fs14 grille right u-pad-right-xs">
                   {  Intl.NumberFormat('fr-FR').format(row.total) }

                     </span>
                  
             </li>
 ) }


      
       </ul>
       </Fragment>


       <div className="flex  w100 flexend">


<div className="w50" style={{border:'1px #d9d9d9 solid',borderTop:0}}>
   <div className="flex justbtw u-pad-s"><span className="fW500"> Frais de livraison</span> <span>--</span> </div>
 
  <div className="flex justbtw  bggray u-pad-s fW600 ">  <span > PRIX TOTAL  </span>   <span>{  Intl.NumberFormat('fr-FR').format(order.sale.total) } Fcfa</span>  </div>   

   </div>


   </div>

</div>
</Col>




</Row>



</Fragment>
   

                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

    <Col xs={{
        span: 22,
      }}
      md={{
        span: 4,
      }}  className="u-mar-top-l">

    <h3 className="fW500 flex itemcenter"> Historique de commmande </h3>


        <Steps direction="vertical" size="small">

        {order.modifications_history.map(modif=>

<Step title={moment(modif.date).format('D MMM Y HH:m')} description={modif.description} />

        )}
    
    

  </Steps>
    
    </Col>

                              
                                </Row>
 </>
                            }
                            </Spin>




                  

</div>
             
                            

    );


}
