import  React , {Fragment, useEffect, useState} from "react";
import {Upload, Layout, Form, Col, Row,Descriptions,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import NumberFormat from "react-number-format";
import moment from 'moment';
import 'moment/locale/fr';


const {Option} = Select;
export default function Step3Formpur({ purchase }) {

  const statepur = {...purchase}

  return (
  

    <Fragment>

<Row gutter={32}>

<Col span={24} className="u-pad-vertical-s u-mar-bottom-s rad8" style={{backgroundColor:'#eff3f9'}}>
<h2 className="fW600 flex itemcenter"> Approvisionnement {purchase.purchase_id}

{ purchase.status == "Livrée" ? <span className="u-pad-xxs greenstatus rad4 fs11 u-mar-left-xs"> {purchase.status} </span> : purchase.status == "Attente livraison" ? <span className="u-pad-xxs yellowstatus rad4 u-mar-left-xs fs11"> {purchase.status} </span> : <span className="u-pad-xxs redstatus u-mar-left-xs rad4 fs11"> {purchase.status} </span>   }




 </h2>
<Descriptions column={4} layout="vertical">


           <Descriptions.Item label="Date de livraison">{purchase.infos.expected_arrival_date  && moment(purchase.infos?.expected_arrival_date).format('D MMMM Y ')} {moment(purchase.infos.billing_date).format('HH')}h{moment(purchase.infos.billing_date).format('m')}</Descriptions.Item> 
        <Descriptions.Item label="Fournisseur">{purchase.infos.vendor?.name }</Descriptions.Item> 
     
        <Descriptions.Item label="Livré chez ">{purchase.infos.branch_to_be_shipped.business_name }</Descriptions.Item> 
        <Descriptions.Item label="Numéro facture">{purchase.infos.invoice_number}</Descriptions.Item> 
        <Descriptions.Item label="Date de paiement"> {moment(purchase.infos.billing_date).format('D MMMM Y ')} {moment(purchase.infos.billing_date).format('HH')}h{moment(purchase.infos.billing_date).format('m')}</Descriptions.Item> 
        <Descriptions.Item label="Note">{purchase.infos.description}</Descriptions.Item> 
    </Descriptions>

</Col>

<Col span={24} className="nomar">
    




<div>
<div className="flex justbtw u-pad-vertical-xs u-pad-horizontal-xs " style={{backgroundColor:'#eff3f9'}}>
  
  <span className="grille3"> Produits/ingrédients </span>
  <span className="grille right"> Qté </span>
  <span className="grille right"> P.U</span>
  <span className="grille right"> Total</span>
   </div>



   <Fragment>
  
      
       <ul class="cart-list" style={{height:'52vh',overflowY:'auto'}}>
      
       {
       
       
       purchase.products.map( row => 
 <li key={row._id} class="section-cart flex itemcenter justbtw  u-pad-left-xs " style={{height:'38px'}}>
                     <span  className="grille3 av-heavy fs14 ">
           {row.name}
 </span>

 <span  className="grille av-heavy fs14 right">
           {row.quantity}
 </span>

 <span className="av-heavy fs14 grille right">
                 {  Intl.NumberFormat('fr-FR').format(row.unit_price
) }

                     </span>


                   <span className="av-heavy fs14 grille right u-pad-right-xs">
                   {  Intl.NumberFormat('fr-FR').format(row.sub_total) }

                     </span>
                  
             </li>
 ) }

<span className="right w100 av-heavy u-mar-top-s" style={{display:'block'}}> TOTAL :  {  Intl.NumberFormat('fr-FR').format(purchase.total_price) } Fcfa</span>

      
       </ul>
       </Fragment>

</div>
</Col>




</Row>



</Fragment>
   



  );
}
