
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { SubNav } from '../../components/SubNav';
import { productNav } from '../../config/navItems';
import {Button, Layout,Modal,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import FormDestock from './adddestock';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import {Logout} from "../../helpers/logout";
import LayoutPage from '../layout';
import DetailsPurchase from './detailspurchase';

const {  Content } = Layout;
export default function  Orders () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [visibleD,setVisibleD] = useState(false)
    const [orders,setOrders] = useState(null)
    const [purchase,setPurchase] = useState(null)


    useEffect(() => {
        const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));


        const getorders= async () => {

            try {
                const result = await axios.get(URL+'/destock', {
                    headers: {
                        'tenant_user_api_key': token             
                }
            }
            
            )

            setOrders(result.data.data);
            setLoading(false)

        } catch (e) {
            setLoading(false)
            if(e.response && e.response.status == 401){
                Logout()
            }
        }



        };




        getorders();


    }, [reload]);


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }

    const change  =  (r)  => {
       
        setVisibleD(true)
        setPurchase(r)
        
            }



    const columns = [

        {
            title: 'Ref',
            dataIndex: '',
        },
        {
            title: 'Par',
            dataIndex: '',
        },

        {
            title: 'Date',
            dataIndex: 'createdAt',
            render: item =>
                <>
 {moment(item).format('D MMMM Y HH:m')}
                </>
        },

      
        {
            title: 'Qte ',
            dataIndex: 'products',


            render: item =>
            <>
{  item.reduce((n, {quantity}) => n + quantity, 0)}

            </>



        },
        {
            title: 'Valeur',
            dataIndex: 'total_price', 
            
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) } Fcfa
            </>
        
        },
      
        



    ];







    return (





        <LayoutPage>

<SubNav items={productNav}  /> 

<div className="container">

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                    <Input placeholder="Rechercher dans la table" style={{minWidth:'250px',height:'35px!important'}} />

                                </div>

                                    <Button type="primary"   className="rad4" onClick={()=>setVisible(true)} >
                                    <span className="fs13">  Nouveau destockage </span>
                                </Button>


                                 

                                <Modal
                                        footer={null}
                                        width={850}
                                        onCancel={()=>setVisible(false)}
                                        visible={visible}
                                        bodyStyle={{ paddingBottom: 0, height:'85vh',overflowY:'auto', paddingLeft:0,paddingRight:0, paddingTop:12 }}
                                        destroyOnClose={true}
                                        maskClosable={false}

                                    >

                                      <FormDestock  close={close}/>

                                    </Modal>


                                    <Modal
                                        footer={null}
                                        width={850}
                                        onCancel={()=>setVisibleD(false)}
                                        visible={visibleD}
                                        bodyStyle={{ paddingBottom: 0, height:'85vh',overflowY:'auto', paddingLeft:0,paddingRight:0, paddingTop:12 }}
                                        destroyOnClose={true}
                                        maskClosable={false}

                                    >

                                      <DetailsPurchase  close={close} purchase={purchase}/>

                                    </Modal>



                                </div>

                                <Table 
                                onRow={(record) => ({
                                    onClick: () => { change(record) }
                                })}
                                columns={columns} dataSource={orders} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />,


                            </div>

                            
                            </div>

             
</LayoutPage>


    );


}
