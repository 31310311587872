







import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { SubNav } from '../../components/SubNav';
import { customerNav } from '../../config/navItems';
import {Drawer,Modal,message,Dropdown,Table,Input} from 'antd';
import DetailsCustomer from './customerdetails'
import LayoutPage from '../layout';
import { useGetCustomers,useEditCustomer } from '../../api/api';

import {PlusCircleOutlined, EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';

import AddCustomer from './component/AddCustomer';
import AddPayment from './component/AddPayment';
import TabPlaceholder from './component/tabplaceholder'
export default function  Customer () {

    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [customers,setCustomers] = useState(null)
    const [searchText, setSearchText] = useState("");
    const [customer,setCustomer] = useState(null)
    const [visibleD,setVisibleD] = useState(false)
  
const {confirm} = Modal
    const { data, error, isLoading, refetch } = useGetCustomers({}, {});
    const { mutateAsync: editCustomer } = useEditCustomer(customer?.id);

    const showDeleteConfirm =  () => {
      confirm({
        title: "Supression de l'utilisateur "+customer.name,
        icon: <ExclamationCircleFilled />,
        content: 'Confirmer la supression de cet utilisateur ?',
        okText: 'Oui',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: async () => { 
          await editCustomer({status:"delete"})
          message.error ('Suppression réussie!!')
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };        



    const items = [

      {
        key: '1',
        label: (
          <span className="fs11 flex itemcenter" onClick={()=>change(customer)}>
        <EyeOutlined className="u-mar-right-xxs" />  Plus de détails
          </span>
        ),
      },
      {
        key: '4',
        label: (
          <span className="fs11 flex itemcenter" >
        <PlusCircleOutlined className="u-mar-right-xxs" />  <AddPayment user={customer}/>
          </span>
        ),
      },
        {
          key: '2',
          label: (
            <span className="fs11 flex itemcenter w100">
          <EditOutlined />  <AddCustomer user={customer}/>
            </span>
          ),
        },
    
        {
          key: '3',
          label: (
            <span className="nega fs11 w100" onClick={showDeleteConfirm} >
              <DeleteOutlined /> Supprimer
            </span>
          ),
        }
      ];

    useEffect(() => {

        setCustomers(data?.data);


    }, [data]);


     useEffect(() => {
        refetch();
      }, [reload]); 


    const change  =  (r)  => {
       
        setVisibleD(true)
        setCustomer(r)
        
            }


 
            

    const close = () => {

        setVisibleD(false)
        refetch()


    }

    const columns = [

        {
            title: 'Nom et prénom',
            dataIndex: '',
            width: 200,
            render: item =>
            <>

            {
            
            <div className="flex itemcenter">
            
            
            
            
            <div class="flex">
              
                
                    <span > {item.name}</span>
             
            <span > {item.lastname}</span>
            
            </div>
            
            </div>
            
            }
            
                        </>,

           filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.lastname).toLowerCase().includes(value.toLowerCase()) || String(record.street).toLowerCase().includes(value.toLowerCase())
}            

        },
        

        {
            title: 'Telephone',
            dataIndex: 'phones',
            width: 150,
        },

        {
            title: 'Balance',
            dataIndex: '',
            width: 150,
            render: item =>
            <>

{ item.balance >= 0 ? <span className="u-pad-vertical-xxs  fW500 "> {  Intl.NumberFormat('fr-FR').format(item.balance) } Fcfa </span> :  <span className="u-pad-vertical-xxs  fW500 redstatus "> {  Intl.NumberFormat('fr-FR').format(item.balance) } Fcfa </span>   }

            </>
        },
        {
            title: 'Adresse',
            dataIndex: 'street',
            width: 200,
        },
        {
            title: '',
            key: 'action',
            width: 10,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
    setCustomer(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },
    ];







    return (





        <LayoutPage>

                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                              

                            




                   <SubNav items={customerNav}  />         

       


                            <div className="container">

                            <div className="grille w100  u-mar-top-l    hauto  u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div className='u-mar-bottom-xs w30'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par nom ou adresse" style={{idth:'250px',height:'32px!important'}} />

</div>

                               <AddCustomer />



                                </div>

                            <Table columns={columns}  locale={{emptyText:<></>}}  loading={{indicator:<TabPlaceholder/>,spinning:isLoading}} dataSource={customers}

                                       onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />

                               

                            </div>

</div>



                      




        
                           


                                    <Drawer
                                    


                                    height={"100%"}
                                    placement={"bottom"}
                                    onClose={()=>setVisibleD(false)}
                                    
                                    visible={visibleD}
                                    closable={false}
                                    className={"bgapp"}
                                    
                                    bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0,backgroundColor:"#eff4fa" }}
                                    destroyOnClose={true}

                                    >

                                      <DetailsCustomer close={close} customerData={customer}/>

                                    </Drawer>










        </LayoutPage>



    );


}
