
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';


import {Button, Layout,Modal,Select,Spin, Menu,Tag, Table,Input, Drawer} from 'antd';
import { HomeOutlined, NotificationOutlined } from '@ant-design/icons';
import Formemploye from './form';
import axios from "axios";
import {useHistory} from "react-router";
import { SubNav } from '../../components/SubNav';
import { settingNav } from '../../config/navItems';
import { useGetTeam} from '../../api/api';
import LayoutPage from '../layout';
import DetailsMember from './teamdetails'
export default function  Employes () {

    const [reload,setReload] = useState(1)
    const history = useHistory()
    const [visible,setVisible] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [member,setMember] = useState(null)
    const [visibleD,setVisibleD] = useState(false)


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });

    const [staffs,setStaffs] = useState(null)
  

    const { data, error, isLoading, refetch } = useGetTeam({}, {});


    useEffect(() => {
        setStaffs(data?.data);
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });

      }, [data]);



      const change  =  (r)  => {
       
        setVisibleD(true)
        setMember(r)
        
            }

    const close = () => {

        setVisible(false)
        setVisibleD(false)
        setReload(reload+1)

    }

    const columns = [

        

        {
            title: 'Nom',
            dataIndex: '',
            width: 150,

            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.infos.firstname).toLowerCase().includes(value.toLowerCase()) || String(record.infos.lastname).toLowerCase().includes(value.toLowerCase())
            },

            render: item =>
            <>



<div className="flex itemcenter">
<svg viewBox="0 0 200 200" width="35" height="35"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>




<div class="flex column u-mar-left-s">
  
    
        <span className="coltext  av-heavy fs13"> {item.infos.firstname && item.infos.firstname}</span>
 
<span className="coltext fs13"> {item.infos.lastname}</span>

</div>

</div>



            </>

    
        },
        

        {
            title: 'Adresse',
            dataIndex: '',
            width: 150,

            render: item =>
            <>


<div class="flex column">
    <div className="flex itemcenter">
        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="14" width="14" viewBox="0 0 40 40"  style={{verticalAlign: 'middle'}}><g><path d="m35.9 27.7q0 0.6-0.2 1.6t-0.5 1.5q-0.4 1.1-2.7 2.3-2.1 1.2-4.1 1.2-0.6 0-1.2-0.1t-1.3-0.3-1.1-0.3-1.2-0.5-1.1-0.4q-2.2-0.7-3.9-1.8-2.8-1.8-5.9-4.8t-4.8-5.9q-1.1-1.7-1.9-3.9 0-0.2-0.4-1.1t-0.4-1.3-0.3-1-0.3-1.3-0.1-1.2q0-2 1.1-4.1 1.3-2.3 2.4-2.8 0.6-0.2 1.5-0.4t1.6-0.2q0.3 0 0.5 0 0.4 0.2 1.2 1.7 0.2 0.4 0.6 1.2t0.8 1.4 0.7 1.2q0.1 0.1 0.4 0.6t0.5 0.8 0.1 0.6q0 0.5-0.6 1.1t-1.4 1.3-1.4 1.1-0.6 1.1q0 0.2 0.1 0.5t0.2 0.4 0.3 0.6 0.3 0.4q1.7 3.1 3.8 5.2t5.3 3.9q0 0 0.4 0.3t0.6 0.3 0.4 0.2 0.5 0.1q0.4 0 1-0.6t1.2-1.4 1.3-1.4 1.1-0.6q0.3 0 0.6 0.1t0.8 0.5 0.6 0.4q0.5 0.3 1.1 0.7t1.5 0.8 1.2 0.6q1.5 0.8 1.7 1.2 0 0.2 0 0.5z"></path></g></svg>
        <span className="u-mar-left-xs fs13"> {item.infos.phones[0]}</span></div>
        <div className="flex itemcenter"><svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="14" width="14" viewBox="0 0 40 40" style={{verticalAlign: 'middle'}}><g><path d="m37.1 33.6v-17.2q-0.7 0.8-1.5 1.5-6 4.6-9.5 7.5-1.1 1-1.9 1.5t-1.9 1.1-2.3 0.6h0q-1.1 0-2.3-0.6t-1.9-1.1-1.9-1.5q-3.5-2.9-9.5-7.5-0.8-0.7-1.5-1.5v17.2q0 0.3 0.2 0.5t0.5 0.2h32.8q0.3 0 0.5-0.2t0.2-0.5z m0-23.5v-0.5l0-0.3 0-0.3-0.2-0.2-0.2-0.2-0.3 0h-32.8q-0.3 0-0.5 0.2t-0.2 0.5q0 3.7 3.2 6.3 4.3 3.4 9 7.1 0.1 0.1 0.8 0.7t1 0.8 1 0.7 1.1 0.6 1 0.2h0q0.5 0 1-0.2t1.1-0.6 1-0.7 1-0.8 0.8-0.7q4.7-3.7 9-7.1 1.2-0.9 2.2-2.6t1-2.9z m2.9-0.8v24.3q0 1.4-1 2.5t-2.6 1h-32.8q-1.5 0-2.6-1t-1-2.5v-24.3q0-1.5 1.1-2.5t2.5-1.1h32.8q1.5 0 2.6 1.1t1 2.5z"></path></g></svg>
<span className=" u-mar-left-xs fs13"> {item.infos.email}</span>
</div>
</div>


            </>


        },
        {
            title: 'Emploi',
            dataIndex: '',
            width: 150,
            render: item =>
            <>


{item.infos.role.name}





            </>
        }
    ];







    return (





      <LayoutPage>
       
       <SubNav items={settingNav}  /> 

<div className="container">
                        


                            <div className="grille w100   u-mar-top-l hauto  u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                    <Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}} placeholder="Rechercher un employé" style={{minWidth:'210px',height:'35px!important'}} />

                                </div>

                                    <Button type="primary"   className="rad4" onClick={()=>setVisible(true)}>
                                    <span className="fs13">  Ajouter un employé </span>
                                </Button>


                                    <Drawer

                                        height={"100%"}
                                        placement={"bottom"}
                                        onClose={()=>setVisible(false)}
                                        visible={visible}
                                        closable={false}
                                        bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
                                        destroyOnClose={true}

                                    >

                                      <Formemploye  close={close}/>

                                    </Drawer>



                                </div>

                                <Table columns={columns}    onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })} dataSource={staffs} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>

           </div>                 










                            <Drawer
                                    
                                    height={"100%"}
                                    placement={"bottom"}
                                    onClose={()=>setVisibleD(false)}
                                    visible={visibleD}
                                    closable={false}
                                    className={"bgapp"}
                                    
                                    bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0,backgroundColor:"#eff4fa" }}
                                    destroyOnClose={true}

                                    >

                                      <DetailsMember close={close} memberData={member}/>

                                    </Drawer>





                            </LayoutPage>

    );


}
