
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import {
    useRecoilState
  } from 'recoil';


import UploadWidget from '../../components/UploadWidget';
import { SubNav } from '../../components/SubNav';
import { settingNav } from '../../config/navItems';
import {Button, Layout,Form,Col,Row,Select,Spin, Menu,message,Input} from 'antd';

import LayoutPage from '../layout';
import { useGetTenantInfos,useUpdateTenant } from '../../api/api';
import  uploadimg from "../../images/image-uploader.png";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import { tenantInfos} from "../../store/index"
import { UploadOutlined } from '@ant-design/icons';




const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Preferences () {


    const [loading,setLoading] = useState(false)
    const [reload,setReload] = useState(1)


    const [url, updateUrl] = useState();
    const [errored, updateError] = useState();
    const [erroredSign, updateErrorSign] = useState();
    /**
     * handleOnUpload
     */
  
    const  handleOnUpload =   async (error, result, widget) => {
      if ( error ) {
        updateError(error);
        widget.close({
          quiet: true
        });
        return;
      }
      await update({logo:result?.info?.secure_url});
     
      setCore({...core,logo:result?.info?.secure_url});

    }



    const  handleOnUploadSignature =   async (error, result, widget) => {
        if ( error ) {
          updateErrorSign(error);
          widget.close({
            quiet: true
          });
          return;
        }
        await update({signature:result?.info?.secure_url});
       
        setCore({...core,signature:result?.info?.secure_url});
  
      }
    

    



    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const [core,setCore] = useState(null)
    const [_,setTenant] = useRecoilState(tenantInfos)

    const { data, error, isLoading} = useGetTenantInfos();

    const { mutateAsync: update } = useUpdateTenant();

    useEffect(() => {
        setCore(data);
      }, [data]);
    


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }


    const onSubmit = async (data) => {
        setLoading(true)
        await update(data);
        setLoading(false)
        message.success('Enregistrement réussi!!')
        setTenant(data)
      };






    return (





        <LayoutPage>

<SubNav items={settingNav}  /> 

<div className="container">
                            <Spin tip="Chargment..." size="large" spinning={isLoading}>

                            <div className="grille w100  noshadow noborder hauto rad16 u-mar-top-xl-res nopadres  flex justcenter">


                            <div className="block w100 cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >





{core &&
    <Form layout="vertical"  className="u-pad-horizontal-l" initialValues={{name:core.core_branch,businessType:core.businessType,email:core.email,phone:core.phone,address:core.address,niu:core.niu,rccm:core.rccm}}  onFinish={onSubmit} >
       
        <Row gutter={16}>


       <Col span={6} className="u-mar-top-l u-mar-bottom-s">



<div className="flex column itemcenter">




{ core?.logo &&
<p><img src={core?.logo} className="w100" alt="Uploaded image" /></p>
}

{errored && <p>{ errored }</p>}


<UploadWidget onUpload={handleOnUpload} multiple={false}>
{({ open }) => {
function handleOnClick(e) {
  e.preventDefault();
  open();
}
return (
     core?.logo ?



     <span className="fs15 fW600 cursor  coltext" onClick={handleOnClick}> 
     
     Modifier le logo
      </span>
     
   






  :



<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s" onClick={handleOnClick}>

<span className="fs15 av-heavy u-mar-right-xs" style={{color:'#1a1a1a'}}> 

Ajouter un logo
</span>

</div>

)
}}
</UploadWidget>

</div>








<div className="flex column u-mar-top-xl itemcenter">




{ core?.signature &&
<p><img src={core?.signature} className="w100" alt="Uploaded image" /></p>
}

{erroredSign && <p>{ erroredSign }</p>}


<UploadWidget onUpload={handleOnUploadSignature} multiple={false}>
{({ open }) => {
function handleOnClickSign(e) {
  e.preventDefault();
  open();
}
return (
     core?.signature ?



     <span className="fs15 fW600 cursor  coltext" onClick={handleOnClickSign}> 
     
     Modifier la signature
      </span>
     
   






  :



<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s" onClick={handleOnClickSign}>

<span className="fs15 av-heavy u-mar-right-xs" style={{color:'#1a1a1a'}}> 

Ajouter une signature
</span>

</div>

)
}}
</UploadWidget>

</div>





       </Col>

            <Col span={18}>


            <Row gutter={16}>



            <Col span={24} className="u-mar-top-l">
                <Form.Item
                    name="name"
                    label="Nom"
                    rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                >
                    <Input  />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ type:'email',message: 'Veuillez entre l email' }]}
                >
                    <Input disabled />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="phone"
                    label="Téléphone"
                    rules={[{ message: 'entrer un numéro' }]}
                >
                    <Input placeholder="téléphone " />
                </Form.Item>
            </Col>
</Row>

{/* <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="businessType"
                    label="Type de business"
                    rules={[{ required: true, message: 'selectionner un type' }]}
                >
                    <Select >
                        

                    <Option value="Boutique généraliste"> Boutique généraliste</Option>
                    <Option value="Boutique ecommerce"> Boutique ecommerce</Option>
                    <Option value={"Institut de beauté"}>Institut de beauté  </Option>
                    <Option value={"Pressing"}> Pressing </Option>
                    <Option value={"Restaurant/Cafe"}> Restaurant/Cafe </Option>
                    <Option value={"Pizerria"}> Pizerria </Option>
                    <Option value={"Prêt à porter"} > Prêt à porter </Option>
                    <Option value="Autres">Autres </Option>

                        
                    </Select>
                </Form.Item>
            </Col>
</Row> */}

<Row gutter={16}>

            <Col span={24}>
                <Form.Item
                    name="address"
                    label="Adresse"
                    rules={[{  message: 'Veuillez entre le nom' }]}
                >
                    <Input  />
                </Form.Item>
            </Col>
</Row>
            <Row gutter={16}>

            <Col span={12}>
                <Form.Item
                    name="niu"
                    label="Contribuable"
                >
                    <Input  />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="rccm"
                    label="RCCM"
                >
                    <Input  />
                </Form.Item>
            </Col>

</Row>



            </Col>


<div className="w100 u-pad-right-xs">

<Form.Item className="w100">




<div style={{textAlign:'right'}} className="u-mar-top-xs">
    
    <Button   loading={loading} type="primary" htmlType="submit" className="rad4 u-pad-horizontal-l" type="primary">
        Enregistrer
    </Button>
</div>



</Form.Item>

</div>
        
        </Row>


    





       





    </Form>
}






</div>

                            </div>

                            </Spin>
</div>

              </LayoutPage>



    );


}
