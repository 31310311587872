import React, { useState,useEffect } from 'react';

import {Button,Modal,Switch, Form,Col,message, Row,Input} from 'antd';


import { useAddBrand,useEditBrand } from '../../../api/api';

import { CloseOutlined, CheckOutlined  } from '@ant-design/icons';

const { TextArea } = Input;


export default function  AddBrand ({brand}) {

    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [isAccess,setIsAccess] = useState(brand? brand.for_sale : true)

    const { mutateAsync: addBrand } = useAddBrand();
    const { mutateAsync: editBrand } = useEditBrand(brand?._id);


    

    function onChange(checked) {
        setIsAccess(checked)
    
      }

    async function onSubmit ( values )  {


        values.for_sale = isAccess

        try {
            setLoading(true)

            if( brand ) {
                await editBrand(values)
            }else{
                await addBrand(values)
            }


            setVisible(false)
            message.success('Enregistrement réussi!!')

            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{brand ? 
    <div  onClick={()=>setVisible(true)} className="w100" >

<span className="fs11 u-mar-left-xxs w100"> 
Modifier 
</span>

</div>
:
<Button type="primary"   className="rad4" onClick={()=>setVisible(true)}>
<span className="fs13 fW500">  Ajouter une marque</span>
</Button>
}

<Modal

    width={500}
    okButtonProps={{form:'Brand_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {brand? 'Modifier une marque':'Ajouter une marque'} </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:'#F7F7F9' }}
    okText= {brand? 'Modifier':'Ajouter une marque'}
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s" style={{ overflow: "auto"}}>

                            



<Form layout="vertical" id="Brand_form" initialValues={brand? brand : null} onFinish={onSubmit} className="h100 justbtw  flex column" >
  
<div>                
    
<Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="name"
                                                        label="Nom "
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={24}>
                                              



                                                    <Form.Item
    name="description"
    label="Description de la marque"
    
>
  <TextArea 
      autoSize={{ minRows: 3, maxRows: 5 }}
  />

</Form.Item>


                                                </Col>

                                           
                                           <Col span={24}>

                                           <Row className="u-mar-top-m flex itemcenter">

<span className="fs13 av-roman  u-mar-right-s">

marque en vente ?

</span>

  <span>   

<Switch onChange={onChange} defaultChecked={isAccess}  size="large"

checkedChildren={<CheckOutlined />}
unCheckedChildren={<CloseOutlined />}
/>

</span>
      </Row>

                                           </Col>

                                            </Row>


    

</div>





</Form>







</div>







</Modal>

</>
) }