import  React , {Fragment, useEffect, useState} from "react";
import {Upload, Layout, Form, Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';

import UploadWidget from '../../../components/UploadWidget';


const {Option} = Select;
export default function Step1Form({ form,data, onSuccess,categories,vendors,images,addImage }) {
    const [background,setBackground] = useState('#ABB8C3')


    const [onStock,setOnStock] = useState(false)
    const [errored, updateError] = useState();



    const  handleOnUpload =   async (error, result, widget) => {
        if ( error ) {
          updateError(error);
          widget.close({
            quiet: true
          });
          return;
        }

        addImage( result?.info?.secure_url )
  
      }
      

    const onAdd = (val) => {

        setOnStock(val)

    }

  




  

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
    >
    


    <Fragment>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="name"
                                                        label="Désignation"
                                                        rules={[{ required: true, message: 'entrez la Désignation' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>


                                                <Col span={24}>
                                                <Form.Item
                                                name="product_type"
                                                label="Catégorie"
                                                rules={[{ required: true, message: 'choisir une categorie' }]}
                                                >
                                                <Select>

                                            {  categories != null &&

                                                categories.map(categorie=>(


                                                <option  value={categorie._id} > {categorie.name} </option>


                                                ))   }


                                                </Select>
                                                </Form.Item>
                                                </Col>

                                                

                                            </Row>

                                            


                                             



                                               <Row gutter={16} >
                                                   <Col span={12}>
                                                       <Form.Item
                                                           name="retail_price"
                                                           label="Prix de vente"
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: 'Entrez le prix de vente en chiffre',
                                                               },
                                                           ]}
                                                       >
                                                           <Input type={"number"} />
                                                       </Form.Item>
                                                   </Col>

                                                   <Col span={12}>
                                                       <Form.Item
                                                           name="supply_price"
                                                           label="prix d'achat"
                                                           rules={[{ message: "entrez le prix d'achat en chiffre"  }]}

                                                       >
                                                           <Input type={"number"} />
                                                       </Form.Item>
                                                   </Col>











                                                 {/*   {branches != null && branches.length > 1 &&
                                                   <span className="av-heavy"> + Ajouter un prix different pour une branche </span>
                                                   } */}
                                               </Row>



                                               <Row gutter={16}>
    <Col span={12}>
        <Form.Item
            name="brand"
            label="Marque"
        >
            <Select  >

                



            </Select>
        </Form.Item>
    </Col>

    <Col span={12}>
    <Form.Item
        name="barcode"
        label="Code barre"
    >
        <Input  />
    </Form.Item>
</Col>

    <Col span={24}>
        <Form.Item
            name="vendors"
            label="Fournisseurs"
        >
            <Select mode="multiple" allowClear  >



                {  vendors != null &&

vendors.map(vendor=>(


<Option  value={vendor.id} > {vendor.name} </Option>


))   }

            </Select>
        </Form.Item>
    </Col>

</Row>



<Row gutter={16}>



    <Col span={24}>
        <Form.Item
            name="description"
            label="Description"
        >
            <Input.TextArea />
        </Form.Item>
    </Col>


</Row>


<Row gutter={16} className="u-mar-top-l">
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="isInventorytracked"
                                                        label="Gérer le stock"

                                                    >
                                                        <Switch onChange={onAdd}/>
                                                    </Form.Item>
                                                </Col>




                                            </Row>

                                            {onStock &&
                                                <Row gutter={16} className="u-mar-bottom-l">
                                                <Col span={12}>
                                                <Form.Item
                                                name="inStock"
                                                label="Stock actuel"
                                                rules={[
                                                {
                                                    required: onStock,
                                                    message: 'Entrez le stock en chiffre',
                                                },
                                            ]}
                                                >
                                                <Input type={"number"} />
                                                </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                <Form.Item
                                                name="stockAlert"
                                                label="stock mininale"
                                                >
                                                <Input  />
                                                </Form.Item>
                                                </Col>


                                                </Row>

                                            }





                                               <div className="grille u-mar-top-s block  cardpour flex u-pad-xs u-pad-s order2  rad12 abs" style={{height:'auto',width:'400px',right:-100,top:'12%' }} >


                                                   <div className=" flex column  w100">



                                                      



                                                   <div className="flex column itemcenter">



<div className="flex wrap">
{ images.length > 0 &&
images.map(file => 
<div style={{width:130,height:120,margin:'0 10px'}} ><img src={file} width="130" height="120" className="w100 rad8" alt="Uploaded image" /></div>

)
}
</div>

{errored && <p>{ errored }</p>}


<UploadWidget onUpload={handleOnUpload} multiple={false}>
{({ open }) => {
function handleOnClick(e) {
  e.preventDefault();
  open();
}
return (
   


<div className="flex itemcenter cursor rad8 btnfilter u-mar-top-s" onClick={handleOnClick}>

<span className="fs15 av-heavy u-mar-right-xs" style={{color:'#1a1a1a'}}> 

Ajouter une image

</span>

</div>

)
}}
</UploadWidget>

</div>








                                                      

                                                   </div>


                                               </div>


                                           </Fragment>
                                           


    </Form>
  );
}
