import React from "react";
import { Button,Switch, Row} from 'antd';

import Cardset from "./settingCom";

export default function Dcon ({close,}) {
    return <div className=""> 
    <Row justify="space-between mh mg">
     <div  className='justcenter itemcenter flex' onClick={close}> <svg data-testid="close-icon" width="24" height="24" viewBox="0 0 24 24" role="img"  title="Fermer"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M5.75 5.75L18.25 18.25M18.25 5.75L5.75 18.25"></path></svg>
     </div>
     <span className='text fs30 justcenter itemcenter flex'>settings</span>
    
      <Button className='ant-btn ant-btn-primary ant-btn-lg rad8' type="primary">save</Button>
    
    </Row>
    <div className='mgb'>
    <p className="av-heavy fs20">Notifications</p>
    <p className=""> Choose how you would like to be notified when you receive a new message..</p>

    <div className='block u-pad-vertical-s rad5 crp '>
        {/* <Row>
        <span>Notifications</span>
            <span>Notifications</span>
        </Row> */}
           {/* <Cardset 
           title={'nnnnnnnnnnnnnnn'}
           desc={'dqzjhbd enfhef ozfeféf hfhf fér   éfhé f'}
           /> */}
        
            <Row>
                <div className='sp'>
                <Switch defaultChecked onChange={false} />
                </div>
                <div className='sp'>
                    <div className=''>
                    <h3> Email Notifications </h3>
                    </div>
                    <div className=''>
                    <span> his setting applies only to your email account</span>
                    </div>
                </div>              
            </Row>
    </div>
    </div>
 </div>

}