import React, { Fragment,useState,useEffect } from 'react';

import {Button,Modal,Select,Switch, Form,Col,message, Row,Input} from 'antd';


import { useRecoilValue } from 'recoil';

import { CloseOutlined, CheckOutlined  } from '@ant-design/icons';
import UploadWidget from '../../../components/UploadWidget';
import { userInfos } from '../../../store';
import { useGetProducttypes,useGetBranch,useEditProduct,useGetVendors,useGetBrands,useAddProduct } from '../../../api/api';

const { TextArea } = Input;
const {Option} = Select;

export default function  AddProduit ({prod}) {

    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [product,setProduct] = useState(prod)
    const [categories,setCategories] = useState(null)
    const [brands,setBrands] = useState(null)
    const user = useRecoilValue(userInfos)

    const [vendors,setVendors] = useState(null)
    const [filters, setFilters] = useState();
    const [images,setImages] = useState([])

    const [onStock,setOnStock] = useState(product ? product.isInventorytracked :false)
    const [errored, updateError] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });
    const { data:cat, error:errorCat, isLoading:isloadingCat, refetch:refetchCat } = useGetProducttypes();
    const { data:brand, error:errorBrand, isLoading:isloadingBrand } = useGetBrands();
    const { data:vendor, error:errorVendor, isLoading:isloadingVendor } = useGetVendors();

    const { mutateAsync: addProduct } = useAddProduct();
    const { mutateAsync: editProduct } = useEditProduct(product?._id);

    const addImage = (image) => {
        setImages(current => [...current,image])
    }

    useEffect(() => {
       
        setCategories(cat?.data)
        setBrands(brand?.data)
        setVendors(vendor?.data)
      

      }, [cat,brand,vendor]);


      useEffect(() => {
        
        if(product != undefined ){
            console.log('le pi',product)
           // product.product_type = product?.product_type?._id
            product.inStock = product?.branches[0]?.stock_count
            product.stockAlert = product?.branches[0]?.alarm_count
            product.vendors = product?.suppliers_informations?.map(sup => sup.supplier)
        }

    }, [product]);

    const  handleOnUpload =   async (error, result, widget) => {
        if ( error ) {
          updateError(error);
          widget.close({
            quiet: true
          });
          return;
        }

        addImage( result?.info?.secure_url )
  
      }
      

    const onAdd = (val) => {

        setOnStock(val)

    }
 

    

   

    async function onSubmit ( values )  {


        values.isInventorytracked = onStock;


        values.branches = [];
        user.infos.branches.map((b)=> {
            values.branches.push({
                branch:b._id,
                sell_price:values.retail_price,
                stock_count: parseInt(values.inStock),
                alarm_count:parseInt(values.stockAlert)
            })
        })
        
        console.log('lestock',values.branches) 
        values.suppliers_informations = [];
        
        values.images = images;
        
        values.vendors?.map((v)=> {
        
            values.suppliers_informations.push({
        
        supplier : v,
        supplier_code: '',
        supplier_price: 0,
        
            })
        
        })
        



        try {
            setLoading(true)

            if( product ) {
              let rep =   await editProduct(values)
              setProduct(rep)
            }else{
                await addProduct(values)
            }


            setVisible(false)
            message.success('Enregistrement réussi!!')

            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{product ? 
    <div  onClick={()=>setVisible(true)} className="w100">

<span className="fs11 u-mar-left-xxs w100"> 
Modifier 
</span>

</div>
:
<Button type="primary"   className="rad4 w100" onClick={()=>setVisible(true)}>
<span className="fs13 fW500 w100">  Ajouter un produit</span>
</Button>
}

<Modal

    width={550}
    okButtonProps={{form:'produit_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {product? 'Modifier un produit':'Ajouter un produit'} </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:'#F7F7F9' }}
    okText= {product? 'Modifier':'Ajouter un produit'}
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-bottom-s" >

                            


<Form layout="vertical" id="produit_form" initialValues={product? product : null} onFinish={onSubmit} className="h100 justbtw  flex column" >
  






<Fragment>


<Row>

<div className="grille  block  cardpour flex u-pad-xs u-pad-s order2  rad12 " style={{height:'auto' }} >


<div className=" flex column ">



   



<div className="flex column itemcenter">



<div className="flex wrap">
{ images.length > 0 &&
images.map(file => 
<div style={{width:130,height:120,margin:'0 10px'}} ><img src={file} width="130" height="120" className="w100 rad8" alt="Uploaded image" /></div>

)
}
</div>

{errored && <p>{ errored }</p>}


<UploadWidget onUpload={handleOnUpload} multiple={false}>
{({ open }) => {
function handleOnClick(e) {
e.preventDefault();
open();
}
return (



<div className="flex itemcenter cursor rad8 btnfilter u-mar-bottom-s" onClick={handleOnClick}>

<span className="fs13" style={{color:'#1a1a1a'}}> 

Ajouter une image

</span>

</div>

)
}}
</UploadWidget>

</div>








   

</div>


</div>

</Row>

                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="name"
                                                        label="Désignation"
                                                        rules={[{ required: true, message: 'entrez la Désignation' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>


                                                <Col span={12}>
                                                <Form.Item
                                                name={['product_type','_id']}
                                                label="Catégorie"
                                                rules={[{ required: true, message: 'choisir une categorie' }]}
                                                >
                                                <Select>

                                            {  categories != null &&

                                                categories.map(categorie=>(


                                                <option  value={categorie._id} > {categorie.name} </option>


                                                ))   }


                                                </Select>
                                                </Form.Item>
                                                </Col>

                                                

                                            </Row>

                                            


                                             



                                               <Row gutter={16} >
                                                   <Col span={12}>
                                                       <Form.Item
                                                           name="retail_price"
                                                           label="Prix de vente"
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: 'Entrez le prix de vente en chiffre',
                                                               },
                                                           ]}
                                                       >
                                                           <Input type={"number"} />
                                                       </Form.Item>
                                                   </Col>

                                                   <Col span={12}>
                                                       <Form.Item
                                                           name="supply_price"
                                                           label="prix d'achat"

                                                       >
                                                           <Input type={"number"} />
                                                       </Form.Item>
                                                   </Col>











                                                 {/*   {branches != null && branches.length > 1 &&
                                                   <span className="av-heavy"> + Ajouter un prix different pour une branche </span>
                                                   } */}
                                               </Row>



                                               <Row gutter={16}>
    <Col span={12}>
        <Form.Item
            name="brand"
            label="Marque"
        >
            
            <Select>

{  brands != null &&

    brands.map(brand=>(


    <option  value={brand._id} > {brand.name} </option>


    ))   }


    </Select>

        </Form.Item>
    </Col>

    <Col span={12}>
    <Form.Item
        name="sku"
        label="Code barre"
    >
        <Input  />
    </Form.Item>
</Col>

    <Col span={24}>
        <Form.Item
            name="vendors"
            label="Fournisseurs"
        >
            <Select mode="multiple" allowClear  >



                {  vendors != null &&

vendors.map(vendor=>(


<Option  value={vendor.id} > {vendor.name} </Option>


))   }

            </Select>
        </Form.Item>
    </Col>

</Row>



<Row gutter={16}>



    <Col span={24}>
        <Form.Item
            name="description"
            label="Description"
        >
            <Input.TextArea />
        </Form.Item>
    </Col>


</Row>


<Row gutter={16}>
                                                <Col span={6}>
                                                    <Form.Item
                                                        name="isInventorytracked"
                                                        label="Gérer le stock"

                                                    >
                                                        <Switch checked={onStock} onChange={onAdd}/>
                                                    </Form.Item>
                                                </Col>





                                                {onStock &&
                                                <>
                                                <Col span={9}>
                                                <Form.Item
                                                name="inStock"
                                                label="Stock actuel"
                                                rules={[
                                                {
                                                    required: onStock,
                                                    message: 'Entrez le stock en chiffre',
                                                },
                                            ]}
                                                >
                                                <Input type={"number"} />
                                                </Form.Item>
                                                </Col>

                                                <Col span={9}>
                                                <Form.Item
                                                name="stockAlert"
                                                label="stock mininale"
                                                >
                                                <Input type={"number"} />
                                                </Form.Item>
                                                </Col>


                                                </>

                                            }









                                            </Row>

                                          






                                           </Fragment>
       




</Form>







</div>







</Modal>

</>
) }