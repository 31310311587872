import {Redirect} from "react-router";

   export  const Logout = async () => {
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('tenant')

            return <Redirect to={"/login"} />

        } catch (error) {
            console.log('error signing out: ', error);
        }
    };


