import  React , {Fragment, useEffect, useState} from "react";
import {Upload,InputNumber, Layout,Modal, DatePicker,Form, Col, Row, Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';

import moment from 'moment';
import 'moment/locale/fr';
import FormProd from './step2formpur'

import { PlusOutlined } from '@ant-design/icons';
import FormCustomer from "../../clients/form";
import AddCustomer from "../../clients/component/AddCustomer";

const {Option} = Select;
const { TextArea } = Input;
export default function Step1Form({form,customers,products,changeProd,changeFee,order }) {
    const [visibleClient,setVisibleClient] = useState(false)



 
      
     order ? order.delivery_date = moment(order.delivery_date) : order = null ;

  

    
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
      };

      const format = 'HH:mm';

      const close = (res) => {

        
       
        form.setFields([{name:"customer", value:res.data.id }]);

        setVisibleClient(false)
    }

    const fillAdress = (v,t) => {


form.setFields(  [{name:"address", value:customers?.filter(c => c.id == v)[0].street}]   );

    }

  return (
      <>
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={order? order : null}
    >
    


    <Fragment>

        <Row gutter={16}>

        <Col span={16}>

    <div className="blok rad8 u-pad-s w100 hauto" style={{minHeight:500}}>
    <FormProd products={products} order={order} changeProduct={changeProd} changeFee={changeFee}  />


    </div> 


  

</Col>

<Col span={8}>

<div className="blok rad8 u-pad-s w100 hauto" style={{minHeight:500}}>

<Row gutter={16}>




<Col span={24}>
<Form.Item
name="customer"
label="Client"
className="rel"
rules={[{
required: true,
message: 'Selectionner un client'
}]}
>
<Select showSearch allowClear 
onSelect={(v,t)=>fillAdress(v,t)}
optionFilterProp="children"

dropdownRender={(menu) => (
    <>
      {}
   
   
     <div className="u-mar-left-s u-mar-top-xxs">
        <AddCustomer headless={true} />
        </div>
        <Divider
        style={{
          margin: '8px 0',
        }}
      />
      
     {menu}
    </>
  )}

>




{  customers != null &&

customers.map(customer=>(


<Option  value={customer.id} > {customer.name} </Option>


))   }

</Select>
</Form.Item>
</Col>





<Col span={24} >
<Form.Item
name="address"
label="Adresse de livraison"
rules={[{
required: true,
message: 'Définir une adresse'
}]}
>
<TextArea rows={2} 

/>




</Form.Item>
</Col>





<Col span={24} className="u-mar-top-xxs">
<Form.Item
            name='delivery_date'
            label="Date et heure de livraison"
            className="w100"
        >

<DatePicker showTime={{ format: 'HH:mm' }} disabledDate={disabledDate}  size={"large"} className="w100" />

</Form.Item>
</Col>



<Col span={24} className="flex column">
<span>Paiement</span>
<div className="flex" style={{marginTop: '4px',
marginLeft: '-11px'}}>

       <Col span={24}>
       <Form.Item
            name="pay_model"
            className="w100 u-pad-left-xxs"
            rules={[{
                required: true,
                message: 'selectionner un modèle'
            }]}
        >

<Select  >

<Option value={'Reglé'}>Paiment reglé</Option>
{/* <Option value={'Partiel'}>Paiment Partiel</Option> */}
<Option value={'A la livraison'}>A la livraison</Option>
<Option value={'A credit'}>A credit</Option>
</Select>
</Form.Item>
       </Col>




   


{/*                     <Col span={24}>
       <Form.Item
            name="date_payment"
            className="w100 u-pad-left-xxs"
        >

<DatePicker   size="large" />
</Form.Item>
       </Col> */}

       </div>

</Col>  



<Col span={24} className="flex column">
       <span>Ajouter une note</span>
       <Form.Item
name="note"

>
<TextArea rows={4}
/>

</Form.Item>
</Col>



</Row>

</div>


    </Col>


</Row>
                                      

                                             



                                           



                                           </Fragment>
                                           







 
    </Form>
  
  

   


  </>
  );
}
