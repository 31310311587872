import React, { useState,useEffect,useRef } from 'react';

import {Button,Modal,Select,Spin, Form,Col,message, Row,Input} from 'antd';


import { useGetCustomersGroups,useAddCustomer,useEditCustomer } from '../../../api/api';


const {Option} = Select;
const { TextArea } = Input;



export default function  AddCustomer ({user,headless}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [customersGroup,setCustomersGroup] = useState(null)
    const { data, error, isLoading, refetch } = useGetCustomersGroups({}, {});
    const { mutateAsync: addCustomer } = useAddCustomer();
    const { mutateAsync: editCustomer } = useEditCustomer(user?.id);

    const inputTagRef = useRef(null);

    useEffect(() => {
        if (inputTagRef.current) {
          inputTagRef.current.focus();
        }
      }, [inputTagRef.current]);


    useEffect(() => {


        setCustomersGroup(data?.data);
       

    }, [data]);

    useEffect(() => {

        if(user != undefined && user.customers_groups){
            user.customers_groups = user?.customers_groups[0]?.id
        }

    }, [user]);


    async function onSubmit ( values )  {

if( values.customers_groups && values.customers_groups[0].name){
        values.customers_groups = values.customers_groups[0].id
    }

        try {
            setLoading(true)

           if( user ) {
            await editCustomer(values)
        }else{
            await addCustomer(values)
        }

            setVisible(false)
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{user ? 
    <div  onClick={()=>setVisible(true)} className="w100">

<span className="fs11 u-mar-left-xxs w100"> 
Modifier 
</span>



</div>
:
<Button type={headless ? "gost":"primary"}   className="rad4" onClick={()=>setVisible(true)}>
<span className="fs13 fW500">  Ajouter un client</span>
</Button>
}



<Modal

    width={500}
    okButtonProps={{form:'customer_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {user? 'Modifier un client' : "Ajouter un client"}  </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:"#F7F7F9" }}
    okText={user? 'Modifier' : "Ajouter un client"}  
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s" >

                            



<Form layout="vertical" initialValues={user? user : null} id="customer_form"  onFinish={onSubmit} className="h100 justbtw flex column" >
  
<div>                
    <Row gutter={16}>
        <Col span={24}>
            <Form.Item
                name="name"
                label="Nom et prénom "
                rules={[{ required: true, message: 'Veuillez entre le nom' }]}
            >
                <Input disabled={loading} autoFocus ref={inputTagRef} />
            </Form.Item>
        </Col>

    </Row>


    <Row gutter={16}>


        <Col span={12}>
            <Form.Item
                name="phones"
                label="Téléphone"
                rules={[{ required: true, message: 'entrer un numéro' }]}
            >
                <Input  disabled={loading} />
            </Form.Item>
        </Col>

        <Col span={12}>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ type:'email', message: 'Veuillez entre l email' }]}
            >
                <Input  disabled={loading} />
            </Form.Item>
        </Col>



    </Row>


    <Row gutter={16}>

    <Col span={12}>
        <Form.Item
        name="type"
        label="type de client"
        rules={[{ required: true, message: 'choisir un type' }]}

        >
        <Select disabled={loading}>

    

        <Option  value={"individual"} > Personne </Option>
        <Option  value={"corporate"} > Entreprise </Option>




        </Select>
        </Form.Item>
        </Col>


    <Col span={12}>
        <Form.Item
        name="customers_groups"
        label="Groupe de client"
        >
        <Select disabled={loading}>

    {  customersGroup != null &&

        customersGroup.map(customerGroup=>(


        <option  value={customerGroup._id} > {customerGroup.name} </option>


        ))   }


        </Select>
        </Form.Item>
        </Col>

</Row>
    <Row gutter={16}>





        <Col span={24}>

            <Form.Item
                name="street"
                label="Adresse complete"
                
            >
              <TextArea disabled={loading}
                  autoSize={{ minRows: 3, maxRows: 3 }}
              />

            </Form.Item>


        </Col>

    </Row>


</div>




<Row>


</Row>



</Form>







</div>







</Modal>

</>
) }