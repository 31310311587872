
import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { SubNav } from '../../components/SubNav';
import { productNav } from '../../config/navItems';
import {Button,Select, Layout,Dropdown,message, Drawer,Radio,Checkbox,Modal,Spin, Menu,Tag, Table,Input, Divider} from 'antd';
import { useMediaQuery } from 'react-responsive'
import TabProductPlaceholder from './components/tabproductplaceholder'; 

import AddProduct from './components/AddProduct'
import ProductExport from './productexport';
import simple from './images/simple_product.png';
import variant from './images/variant_product.png';
import combo from './images/bundle_product.png';

import AddForm from './addbasicproduct';
import { Link } from "react-router-dom";
import noneimg from "./images/product-none.png";
import LayoutPage from '../layout';
//import Details from './details';
import { useGetProducts,useEditProduct, useGetProducttypes } from '../../api/api';
import {CaretDownOutlined,PlusCircleOutlined, EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';

import iconexport from '../../images/icon-export.png'
import iconimport from '../../images/icon-import.png'
import iconbarcode from '../../images/icon-barcode.png'

const {  Content } = Layout;
const {confirm} = Modal


export default function  Products () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
const isDesktop = useMediaQuery({ query: '(min-width: 993px)' })


    const [visibleModal,setVisibleModal] = useState(false)
    const [visibleExport,setVisibleExport] = useState(false)

    const [visibleEdit,setVisibleEdit] = useState(false)
    const [products,setProducts] = useState()

    const [productId,setProductId] = useState(null)
    const [filters, setFilters] = useState({stock:'all'});
    const [searchText, setSearchText] = useState("");
    const [product,setProduct] = useState(null)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });
    const { data, error, isFetching, refetch } = useGetProducts({}, filters);
    const { mutateAsync: editProduct } = useEditProduct(product?._id);


 

    useEffect(() => {
        setProducts(data?.data);
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });

      }, [data]);
    
       useEffect(() => {
        refetch();
      }, [filters]); 



      const handleChange = (value) => {

setFilters({...filters,stock:value})
      
         
      };


      const showDeleteConfirm =  () => {
        confirm({
          title: "Supression du produit - " +product.name,
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer la supression de ce produit ?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
            await editProduct({status:"delete"})
            message.error ('Suppression réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };   
      
      
     const items = [

        /*  {
           key: '1',
           label: (
             <span className="fs11 flex itemcenter"  >
           <EyeOutlined className="u-mar-right-xxs" />  Plus de détails
             </span>
           ),
         },
         {
           key: '4',
           label: (
             <span className="fs11 flex itemcenter w100" >
           <PlusCircleOutlined className="u-mar-right-xxs" />  Historique de stock
             </span>
           ),
         }, */
           {
             key: '2',
             label: (
               <span className="fs11 flex itemcenter w100">
             <EditOutlined />  <AddProduct prod={product} /> 
               </span>
             ),
           },
       
           {
             key: '3',
             label: (
               <span className="nega fs11" onClick={showDeleteConfirm}>
                 <DeleteOutlined /> Supprimer le produit
               </span>
             ),
           }
         ];
  

 
  







   


    




    const change  =  (r)  => {
       console.log(r)
       setVisibleEdit(true)
       setProduct(r)
        
            }

            const close = () => {

                setVisibleEdit(false)
                setVisibleModal(false)
                refetch()
        
            }

            const closeExport = () => {

                setVisibleExport(false)
        
            }


    const columns = [

        {
            title: '',
            dataIndex: '',
            width: 50,
            render: item =>
                <>
                {item.images.length > 0 ?
                <div className="rad8 bgwhite flex itemcenter justcenter">
            <img src={item.images[0]} width="35" height="35" className="rad8" />
            </div>
            :
            <div className="rad8 bgwhite flex itemcenter justcenter">
<img src={noneimg} width="40" height="40" />
</div>
            }

                </>

        },
        {
            title: 'Designation',
            dataIndex: 'name',
            width: 220,
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.product_type?.name).toLowerCase().includes(value.toLowerCase())
            }
        
        },
        {
            title: 'Prix vente',
            dataIndex: 'retail_price',
            width: 140,
        },
        {
            title: "Prix d'achat",
            dataIndex: 'supply_price',
            width: 140,
        },
      
        {
            title: 'Stock',
            dataIndex: '',
            width: 100,
            render: p => (
                <>
{ p.isInventorytracked ? <span className={p.branches.reduce((n, {stock_count}) => n + stock_count, 0) <= p.branches.reduce((n, {alarm_count}) => n + alarm_count, 0) ? 'nega' :' '} > {p.branches.reduce((n, {stock_count}) => n + stock_count, 0) } </span>  : '--' }

                </>
            ),
        },
         {
            title: 'Catégorie',
            dataIndex: 'product_type',
            render: product_type => (
                <>

                                {product_type?.name}

                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: '',
            width: 130,
            render: p => (
              <>
              { p.status == 'active' ?   
              
              <div class="flex itemcenter"><div className=" fBQcfS"></div><span>Actif</span></div>
              
              : <div class="flex itemcenter"><div className=" fBQcfS bgray"></div><span>Désactivé</span></div> }
              
                              </>
            ),
        },
        {
            title: '',
            key: 'action',
            width: 40,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
   setProduct(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },
     
    ];





    const columnsRes = [

    
        {
            title: 'Designation',
            dataIndex: 'name',
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.product_type.name).toLowerCase().includes(value.toLowerCase())
            }
        
        },
        {
            title: 'Prix vente',
            dataIndex: 'retail_price',
        },
      
        {
            title: 'Stock',
            dataIndex: '',
            render: p => (
                <>
{ p.isInventorytracked ?   p.branches.reduce((n, {stock_count}) => n + stock_count, 0) : '--' }

                </>
            ),
        },
    
     
    ];







    return (




<LayoutPage>

<SubNav items={productNav}  /> 

<div className="container">
                            <div className="grille w100  u-mar-top-l  hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

         <div className='flex grille itemcenter noneres'>
       
       


         <div className='w30 u-mar-right-s'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher dans les produits" style={{minWidth:'250px',height:'45px!important'}} />

</div>


                                                <Select className='w20 u-mar-right-s' onChange={handleChange}  defaultValue="all">
                                                <option value={"all"}>Tous les stocks</option>
                                          


                                                <option  value={"valide"} > Stock valide   </option>
                                                <option  value={"critique"} > Stock critique   </option>
                                                <option  value={"epuisé"} > Stock epuisé   </option>
                                               


                                                </Select>



         </div>



<div className="flex itemcenter">
      
      <span className="fs14 cursor fW500 u-mar-right-s " onClick={()=>setVisibleExport(true)}>Exporter</span>


                                <AddProduct />

                                </div>
                           










{/* <Drawer
                                    
                                    height={"100%"}
                                    placement={"bottom"}
                                    onClose={()=>setVisibleEdit(false)}
                                    visible={visibleEdit}
                                    closable={false}
                                    className={"bgapp"}
                                    
                                    bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0,backgroundColor:"#eff4fa" }}
                                    destroyOnClose={true}

                                    >

                                      <Details close={close} product={product}/>

                                    </Drawer> */}



<Drawer

height={"100%"}
placement={"bottom"}
onClose={()=>setVisibleExport(false)}
visible={visibleExport}
closable={false}
bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
destroyOnClose={true}

>
{ <ProductExport  onClose={closeExport} />
}
</Drawer>



                                </div>





                                <Table columns={ isTabletOrMobile ? columnsRes : columns} locale={{emptyText:<></>}}  loading={{indicator:<TabProductPlaceholder/>,spinning:isFetching}} dataSource={products} onRow={(record) => ({
                                           //onClick: () => { change(record) }
                                       })}  
                                       pagination={{
                                        pageSize: 10,
                                      } }
                                      
                                      scroll={{
                                        y: 650,
                                      }}
                                      />


                            </div>
</div>
                           

                            </LayoutPage>



    );


}
