/**
 * Created by sinclaireric on 19/02/2018.
 */

 import React, {Fragment} from 'react';
 import {
  useRecoilValue
} from 'recoil';
import moment from 'moment';
import 'moment/locale/fr';
import NumberFormat from "react-number-format";
import {tenantInfos} from '../../store';



const Print = React.forwardRef(({order}, ref) => {
  const tenant =  useRecoilValue(tenantInfos)

console.log(order.sale)

return <div className=" print-source" ref={ref}>


{order.sale != null  &&
        <Fragment>

          
    
        <div className="flex">
        
        <div className="grille21 flex itemcenter u-pad-top-s  column" style={{overflow:'auto',height:'auto'}}>
        
        
        
        
        
        <div className="w98  bgwhite  rel"   >
        <h3 style={{textAlign:'center',margin:'0'}} > {tenant.name} </h3>
          <h3 style={{textAlign:'center',margin:'0'}} > {tenant.address} </h3>
          <h4 style={{marginBottom:'5px',textAlign:'center',marginTop:'0'}}> NIU: {tenant.niu}  </h4>

          <h4 style={{marginBottom:'20px',textAlign:'center',marginTop:'0'}}> Tel :  {tenant.phone}   </h4>
        <h2 className="center av-medium fs16 u-mar-bottom-xxs" style={{textAlign:'center',margin:'0'}}> {order.sale.status == "complété" ? ' Facture' : 'Facture en cours' } {order.sale.sale_id} </h2>
        <h2 className="center av-medium fs16 u-mar-bottom-xxs" style={{textAlign:'center',margin:'0'}}> Commande {order.order_id} - {order.status} </h2>

        <h4 className="collab fs11" style={{textAlign:'center',margin:'0 0 10px  '}}> { moment(order.sale.createdAt).format('Do MMMM Y HH:m')} </h4>
        
        
        
        
        
        <ul className="cart-list" style={{
          listStyle: 'none',
        padding: '0',
        margin: '0'
          }}>
        
        
        

      {  order.sale?.items?.map( row => 


<li class="section-cart flex itemcenter justbtw " style={{height:'25px'}}>
             <span  className="grille2 av-medium fs12 ">
                 <span style={{width:'20px'}} className="u-mar-right-xs">{row.quantity}   </span>	{row.product?.name}
</span>
         <div className="flex grille itemcenter flexend" >



           <span className="av-medium fs12 ">
           {  Intl.NumberFormat('fr-FR').format(row.total) }
             </span>
         </div>
     </li>
 ) }






        
        </ul>
        
        
        
        
        <div  style={{borderTop:'1px solid #dee3e7',display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Client </span>
        
        
          { order.sale.customer != null &&
        
        
        
                              <h3 className="av-medium fs15 nomar" style={{margin:'0'}} >
            {order.sale.customer.name} {order.sale.customer.lastname}
          </h3>
        
        
          }
        
        
        </div>
              
        <div  style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Vendeur: </span>
        
        
          { order.sale.user != null &&
        
        
        
                              <h3 className="av-heavy fs15 nomar" style={{margin:'0'}} >
            {order.sale.user.infos?.firstname}  {order?.sale.user.infos?.lastname}
          </h3>
        
        
          }
        
        
        </div>
        
        <div style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span> Réglèment </span>
        <h3 className="av-heavy fs13" style={{margin:'0'}}> {order.payment_status} </h3>
        </div>


        <div style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span> Frais de livraison</span>
        <h3 className="av-heavy fs13" style={{margin:'0'}}> {order.exp_fee} </h3>
        </div>
          
        <div className="flex  justbtw u-pad-vertical-xs" style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}} >
        <span className="fs15"> TOTAL </span>
        <h2 className="fs18 av-heavy" style={{margin:'0'}}> 
        {  Intl.NumberFormat('fr-FR').format( parseInt(order.sale.total) + parseInt(order.exp_fee ? order.exp_fee : 0)) }
       Fcfa</h2>
        </div>
        
        
        
        
        <h4 style={{textAlign:'center',margin:'0',fontSize:'10px'}}>  Logiciel de gestion Onebaz ( +237 676 01 39 24 )</h4>
        
        
        
        
        
        
        </div>
        
        
        
        
        </div>
        
        </div>
        
        
        
        </Fragment>

        }
</div>


});




export default Print;
