
import React, { useState,useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import axios from "axios";
import { useRecoilValue,useRecoilState } from 'recoil';

import {Button,DatePicker,Upload,Switch,Select,Form,Col,message, Row,Divider,Input} from 'antd';
import {URL} from "../../urlapi";
import ImgCrop from 'antd-img-crop';
import { CloseOutlined, CheckOutlined,UploadOutlined  } from '@ant-design/icons';
import { userInfos } from '../../store';


const {Option} = Select;
export default function  FormEdit ({data}) {

    const [form] = Form.useForm()

    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)

  const [isAccess,setIsAccess] = useState(false)
  const user = useRecoilValue(userInfos)

  const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

  







  


    function onChange(checked) {
        setIsAccess(checked)
    
      }

      const [fileList,setFileList] = useState([])

    
      
      
  
   
  


    async function onSubmit ( values )  {

       

        let tvalues = {...values}
    
        console.log(tvalues)



       let infos = {
            firstname: tvalues.firstname,
            lastname: tvalues.lastname,
            role:  tvalues.roles,
            email: tvalues.email,
            phones: tvalues.phone,
            birthDate: tvalues.birthDate,
            employmentContractType: values.employmentContractType,
            dateOfBeginning: values.dateOfBeginning,
            dateOfTermination: values.dateOfTermination,
            employment: values.employment,
            qualification: values.qualification,
            level: values.level,
            salary: values.salary,
            branches: user.infos.branches[0]
          };

console.log("isacces",isAccess)

        let  app_use_settings = {
            is_app_user: isAccess,
            credentials: {
              email: tvalues.email,
              password: tvalues.password, 
            },
            send_email_reports: false
          }


          console.log(infos)
          console.log(app_use_settings)


        try {
            values.branches = [];
            setLoading(true)

            const result = await axios.post(URL + '/employees',
            {infos,app_use_settings},{
                headers: {
                    'tenant_user_api_key': token             
                }
            }
            )

           // close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }





  const handleSubmit = async () => {




    form.validateFields()
        .then(async function (values) {
    console.log(values)


    let infos = {
        firstname: values.firstname,
        lastname: values.lastname,
        role:  values.roles,
        email: values.email,
        phones: values.phone,
        birthDate: values.birthDate,
        employmentContractType: values.employmentContractType,
        dateOfBeginning: values.dateOfBeginning,
        dateOfTermination: values.dateOfTermination,
        employment: values.employment,
        qualification: values.qualification,
        level: values.level,
        salary: values.salary,
        branches: user.infos.branches[0]
      };

console.log("isacces",isAccess)

    let  app_use_settings = {
        is_app_user: isAccess,
        credentials: {
          email: values.email,
          password: values.password, 
        },
        send_email_reports: false
      }


      console.log(infos)
      console.log(app_use_settings)


    try {
        values.branches = [];
        setLoading(true)

        const result = await axios.post(URL + '/employees',
        {infos,app_use_settings},{
            headers: {
                'tenant_user_api_key': token             
            }
        }
        )

       // close()
        message.success('Enregistrement réussi!!')
        setLoading(false)









        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }




    })
    .catch((errorInfo) => {
      console.log( errorInfo );
    });




}








    return (

                                <div className="flex  column rel h100">

                               



<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s" style={{top:0,borderBottom:'1px solid #DDD',paddingBottom:10}}>






<div className="flex itemcenter abs" style={{right:34}}>





<Button type="primary" size="large" loading={loading} className="rad8" onClick={()=>handleSubmit()} >
    <span className="fs16 av-heavy">  Valider </span>
</Button>

</div>









</div>










<div className="u-mar-vertical-xxl  w100 mauto">



  <Form layout="vertical"        className="rel"
       form={form}
       initialValues={{firstname:data?.infos.firstname}}
 style={{ padding:"0px 15px" }} onFinish={onSubmit} >



            
                  <>




<Row gutter={16}>




                                                <Col span={12}>
                                                    <Form.Item
                                                        name="firstname"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="lastname"
                                                        label="Prénom"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>
     
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Téléphone"
                                                        rules={[{  message: 'entrer un numéro' }]}
                                                    >
                                                        <Input placeholder="téléphone " />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
<Form.Item
                                                        name="adresse"
                                                        label="Adresse"
                                                    >
                                                        <Input placeholder="adresse " />
                                                    </Form.Item>
</Col>

                                            </Row>

                                            <Row gutter={16}>




<Col span={12}>
                                            <Form.Item
                                                        name="birthDate"
                                                        label="Date de naissance"
                                                        className="w100 "
                                                    >

                                            <DatePicker  size={"large"} className="w100" />

</Form.Item>
</Col>



                       </Row>


                       <div >



  <Row className="u-mar-top-m">

<span className="fs18 av-roman u-mar-bottom-m  u-mar-right-l">

L'employé à accès à l'application ?

</span>

  <span>   

<Switch onChange={onChange} defaultChecked={isAccess}  size="large"

checkedChildren={<CheckOutlined />}
unCheckedChildren={<CloseOutlined />}
/>

</span>
      </Row>

      {isAccess &&

<Row gutter={16} >

                     

<Col span={24}>
<Form.Item
name="password"
label="Mot de passe"
rules={[{required: true,message: 'Entrer un mot de passe' }]}
>
<Input type={'password'} />
</Form.Item>
</Col>









</Row>

}

  </div>

  








</>
                
                



  


                          






                                         




                                        </Form>


                                        </div>




                                </div>




    );


}
