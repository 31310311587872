import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 48,
      }}
      spin
    />
  );

const SuspendFallbackLoading = () => {
  return (
      <div style={{height:'98vh',width:'98vw',margin:'0',display:'flex',alignItems:'center',justifyContent:'center',opacity:'0.5'}}>
    <Spin  indicator={antIcon} tip="Chargement...">

    </Spin>
    </div>
  );
};

export default SuspendFallbackLoading;