
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button,DatePicker,Upload, Layout,Select,Form,Col,Spin,message, Row,Divider,Input} from 'antd';
import axios from "axios";
import {useHistory} from "react-router";
import {  CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input';
import fr from 'world_countries_lists/data/countries/fr/world.json';

import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import LayoutPage from '../layout';

const { Content } = Layout;
const { Option  } = Select;
export default function  Profile () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const history = useHistory()

    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const [member,setMember] = useState(null)
  

 


    useEffect(() => {


        const getMember = async () => {

try {
    const result = await axios.get(URL+'/employees/'+JSON.parse(localStorage.getItem('user')).id, {
        headers: {
            'tenant_user_api_key': token             
        }
    })
    setLoading(false)

    setMember(result.data);

} catch (e) {
   if(e.response && e.response.status == 401){
       Logout()
   }
}



        };




        getMember();


    }, [reload]);



    async function onSubmit ( values )  {

        console.log(values)

    }


    async function onSubmitPass ( values )  {

        console.log(values)

    }



    const checkPhone = (_, value) => {

        let regex = new RegExp('^[0-9]+$')
     
     
         if (  regex.test(value.phone) && value.phone.length > 6 ) {
           return Promise.resolve();
         }
     
         return Promise.reject(new Error('Veuillez entrez un numero correct'));
     
     
     
       };

    return (





      <LayoutPage>
       
                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                          


                            <div className="grille w100  u-pad-vertical-s noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                              

                              <div className="u-pad-vertical-s u-pad-horizontal-jumbo">

<h3 className="av-heavy fs20 colprim u-mar-bottom-l"> Profil</h3>


<Spin tip="Chargment..." size="large" spinning={loading }>

{ member != null &&

<>
<div>

<svg viewBox="0 0 200 200" width="80" height="80"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>

<h4 className="fs16"> {member.infos.email} </h4>

<span className="av-heavy">Branches</span>

    </div>

    <ConfigProvider locale={fr}>


<Form layout="vertical" style={{marginTop:20}} onFinish={onSubmit} initialValues={{firstname:member.infos.firstname,lastname:member.infos.lastname,phone: {short:"CM",phone:member.infos.phones[0]}}} >
                                           
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="firstname"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="lastname"
                                                        label="Prénom"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>
                                                <Col span={12}>
                                                <Form.Item
          label="Téléphone"
          name="phone"
          initialValue={{
            short:  "CM",
          }}

          rules={[
            {
              validator: checkPhone,
            },
          ]}

        >
          <CountryPhoneInput />
        </Form.Item>
                                                </Col>



                                                
                                            </Row>

<Row>
<Col span={24} className="flex flexend">
                                                <Form.Item >
                                            <Button type="primary" size="large"  className="rad8">
                                    <span className="fs16 av-heavy">  Modifier le profil </span>
                                </Button>
                                </Form.Item>
                                </Col>


                                </Row>

                                            </Form>

                                            </ConfigProvider>
                                            </>
}

</Spin>



  <Divider className="nomartop" />


  <h3 className="av-heavy fs20 colprim u-mar-bottom-l u-mar-top-l"> Mot de passe </h3>




  <Form layout="vertical" style={{marginTop:20}} onFinish={onSubmitPass}  >
                                           
                                           <Row gutter={16}>
                                               <Col span={12}>
                                                   <Form.Item
                                                       name="oldpassword"
                                                       label="Ancien mot de passe"
                                                       rules={[{ required: true, message: 'Veuillez entrez l ancien mot de passe' }]}
                                                   >

                                                       <Input.Password placeholder="Ancien mot de passe " />

                                                   </Form.Item>
                                               </Col>



                                               <Col span={12}>
                                                   <Form.Item
                                                       name="newpassowrd"
                                                       label="Nouveau mot de passe"
                                                   >
                                                <Input.Password placeholder="Nouveau mot de passe " />

                                                   </Form.Item>
                                               </Col>
                                           </Row>


              <Row>
<Col span={24} className="flex flexend">
                                                <Form.Item >
                                            <Button type="primary" size="large"  className="rad8">
                                    <span className="fs16 av-heavy">  Changer de mot de passe </span>
                                </Button>
                                </Form.Item>
                                </Col>


                                </Row>                             

      </Form>









                                  </div>

                             





                            </div>

                            



                            </LayoutPage>

    );


}
