import React, { Fragment,useState,useEffect } from 'react';

import {Button,Modal,Select,Spin, Form,Col,message, Row,Input} from 'antd';


import { useRecoilValue } from 'recoil';

import { CloseOutlined, CheckOutlined  } from '@ant-design/icons';
import UploadWidget from '../../../components/UploadWidget';
import { userInfos } from '../../../store';
import { useGetProducts,useEditPurchase,useGetVendors,useAddPurchase } from '../../../api/api';
import Step2Form from '../form/step2formpur';
import Step1Form from '../form/step1formpur';
const { TextArea } = Input;
const {Option} = Select;

export default function  AddPurchase ({purchase}) {

    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [products,setProducts] = useState(null)
    const [productcart,setProductcart] = useState([])

    const user = useRecoilValue(userInfos)

    const [vendors,setVendors] = useState(null)
    const [filters, setFilters] = useState();
    const [images,setImages] = useState([])

    const [errored, updateError] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });
    const { data:prod, error:errorProd, isLoading:isloadingProd, refetch:refetchProd } = useGetProducts();
    const { data:vendor, error:errorVendor, isLoading:isloadingVendor } = useGetVendors();

    const { mutateAsync: addPurchase } = useAddPurchase();
    const { mutateAsync: editPurchase } = useEditPurchase(purchase?._id);


  
    useEffect(() => {
       
        setProducts(prod?.data)
        setVendors(vendor?.data)
      

      }, [prod,vendor]);


      useEffect(() => {
        if(purchase != undefined ){
         
        }

    }, [purchase]);


 
 
    const setCart = (cart) => {
        console.log(cart)
setProductcart(cart)
    }

    

   

    async function onSubmit ( values )  {






        let valuesfin = {}


valuesfin.infos = {
    purchase_number: values.purchase_number,
    expected_arrival_date: values.expected_arrival_date,
    vendor: values.vendor,
    branch_to_be_shipped: user.infos?.branches[0]._id,
    invoice_number: values.invoice_number,
    billing_date: values.billing_date,
    description:  values.description
}


var total = 0;
  for ( var i = 0, _len = productcart.length; i < _len; i++ ) {
      total += productcart[i]["num"]*productcart[i]["supply_price"]
  }

  valuesfin.total_price = total


valuesfin.products = []




productcart.map((p)=> {
    valuesfin.products.push({
        
        id: p._id,
        name: p.name,
        quantity: p.num,
        unit_price: p.supply_price,
        sub_total: p.num*p.supply_price




    })
})



valuesfin.cart_discount = 0




        try {
            setLoading(true)
console.log('les val',valuesfin)
            if( purchase ) {
                await editPurchase(valuesfin)
            }else{
                await addPurchase(valuesfin)
            }


            setVisible(false)
            message.success('Enregistrement réussi!!')

            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{purchase ? 
    <div  onClick={()=>setVisible(true)} className="w100">

<span className="fs11 u-mar-left-xxs w100"> 
Modifier 
</span>

</div>
:
<Button type="primary"   className="rad4 w100" onClick={()=>setVisible(true)}>
<span className="fs13 fW500 w100">  Ajouter un approvisionnement</span>
</Button>
}

<Modal

    width={1080}
    okButtonProps={{form:'produit_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {purchase? 'Modifier un approvisionnement':'Ajouter un approvisionnement'} </span>}
    bodyStyle={{ paddingBottom: 0,minHeight:600,paddingTop:12,backgroundColor:'#F7F7F9' }}
    okText= {purchase? 'Modifier':'Ajouter un approvisionnement'}
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-bottom-s" >

                            
<Spin tip="Chargment..." size="large" spinning={isloadingProd && isloadingVendor }>


<Form layout="vertical" id="produit_form" initialValues={purchase? purchase : null} onFinish={onSubmit} className="h100 justbtw  flex column" >
  


<Row gutter={32}>
    <Col span={16} className='blok hauto'>
        <h3 className='fW500'>Produits</h3>
        <Step2Form products={products} setCart={setCart} />
    </Col>

    <Col span={8} >
        <Step1Form  vendors={vendors}  />
    </Col>
</Row>





</Form>

</Spin>
 




</div>







</Modal>

</>
) }