/**
 * Created by sinclaireric on 19/02/2018.
 */

import React,{Component,useEffect,useState, Fragment} from 'react';
import { Link } from "react-router-dom";
import Clock from 'react-live-clock';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import { useRecoilValue,useRecoilState } from 'recoil';
import 'moment/locale/fr';
import {useHistory} from "react-router";
import axios from "axios";
import {URL} from "../../urlapi";
import  {Logout} from "../../helpers/logout";
import { userInfos } from '../../store';
import { useGetParkSales } from '../../api/api';

export default function  Pos () {
  const [sales,setSales] = useState(null)
  const history = useHistory()
  const user = useRecoilValue(userInfos)


  const { data, error, isLoading, refetch } = useGetParkSales();


  useEffect(() => {
      setSales(data?.data);
    }, [data]);

    useEffect(() => {
      refetch();
    }, []); 




  async function logout () {
    try {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('tenant')
        localStorage.removeItem('recoil-persist')
        history.push('/login')
    } catch (error) {
        console.log('error signing out: ', error);
    }
}


        return (





            <div className="flex  term rescolumnrev">
<Helmet title="Pos"/>

<div className="  hv100 column justbtw u-pad-vertical-l u-pad-bottom-s  flex u-pad-left-m u-pad-horizontal-xs-res">

<div className="flex column ">
<Link to={'/pos/sale/new/add'} className="btn btn-blue btn-pos av-medium fs16 w100res" style={{width:'220px',}}> Nouvelle Vente </Link>


</div>


<div className="flex column ">

<Link to={'/pos/tickets'} className="white rel av-light u-mar-vertical-xs fs18 tickpos cursor u-pad-left-s">
  Ventes en cours
  <span className="abs flex justcenter itemcenter white fs11" style={{backgroundColor: 'red',
    borderRadius: '50%',
    right: '22px',
    height: '20px',
    width: '20px',
    top: '5px'}}> {sales?.length} </span>


</Link>


<Link to={'/pos/orders'} className="white av-light u-mar-vertical-xs fs18 tickpos cursor u-pad-left-s"> Commandes </Link>


<Link to={'/pos/transaction'} className="white av-light u-mar-vertical-xs fs18 tickpos cursor u-pad-left-s"> Transactions </Link>
<Link to={'/pos/rapport'}  className="white av-light u-mar-vertical-xs fs18 tickpos cursor u-pad-left-s"> Rapport de vente </Link>




</div>

<div className="flex column">
<Link to={'/'}  className="white av-light  fs18 tickpos cursor u-pad-left-s"> Paramètres </Link>

  <Link to={'/'}  className="white av-light u-mar-vertical-xs fs18 tickpos cursor u-pad-left-s"> Retour Dashboard</Link>

  </div>







</div>

<div className="grille flex itemcenter justcenter column">

<div className="flex itemcenter justcenter u-pad-left-s-res u-mar-top-s-res">
<h1 className="fs30 white av-light nomar u-mar-right-s"> Bonjour     { user.infos.lastname ? user.infos.lastname : user.infos.firstname}
   </h1>

<span onClick={logout } className="cursor">
  <svg   style={{marginLeft: '20px',
    marginTop: '9px'}} fill="none" width="28" height="28" viewBox="0 0 24 24"  stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path data-v-be0a6d40="" d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline data-v-be0a6d40="" points="16 17 21 12 16 7"></polyline><line data-v-be0a6d40="" x1="21" y1="12" x2="9" y2="12"></line></svg>
</span>
</div>


<h1 className="fs90  white av-light fW600 u-pad-horizontal-l w70 center u-mar-bottom-xs noneres">
       <Clock format="HH:mm:ss" ticking={true} interval={1000} />
   </h1>

   <h3 className="fs20 white av-light nomar " > {moment().format('dddd , Do MMMM  YYYY')}   </h3>



</div>



            </div>



        );

    



}


