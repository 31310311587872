
import React, {useEffect,Fragment, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { Link } from "react-router-dom";


import { Spin, Button, Menu,message, Tabs, Row, Col, Table} from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import axios from "axios";
import {URL} from "../../urlapi";
import {useHistory,useParams} from "react-router";


const antIcon = <LoadingOutlined style={{ fontSize: 49 }} spin />;

export default function  DetailsFacture ({sale}) {
 


    const history = useHistory()
    const [facture,setFacture] = useState(sale)










    return (





        facture != null  ?
        <Fragment>
      
        
        
        <Link to={'/pos/sale/new/'+facture._id} className="flex cursor">
        
        <div className=" flex itemcenter u-pad-top-s w100 column " >
        
        
        
        
        
        <div className="w100  block bgwhite fact rel"  id="fact"  >

        <h4 className="collab fs12" style={{textAlign:'center',margin:'0 0 10px  '}}> { moment(facture.createdAt).format('Do MMMM Y HH:m')} </h4>
        
        
        
        
        
        <ul className="cart-list" style={{
          listStyle: 'none',
        padding: '0',
        margin: '0'
          }}>
        
        
        

      {  facture.items.map( row => 


<li class="section-cart flex itemcenter justbtw ">
             <span  className="grille2 av-medium fs12 ">
                 <span style={{width:'20px'}} className="u-mar-right-xs">{row.quantity}   </span>	{row.product?.name}
</span>
         <div className="flex grille itemcenter flexend" >



           <span className="av-medium fs12 ">
           {  Intl.NumberFormat('fr-FR').format(row.total) } 

             </span>
         </div>
     </li>
 ) }






        
        </ul>
        
        
        
        
        <div  style={{borderTop:'1px solid #dee3e7',display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Client </span>
        
        
          { facture.customer != null &&
        
        
        
                              <h3 className="av-medium fs12 nomar" style={{margin:'0'}} >
            {facture.customer.name} {facture.customer.lastname}
          </h3>
        
        
          }
        
        
        </div>
              
        <div  style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Vendeur: </span>
        
        
          { facture.user != null &&
        
        
        
                              <h3 className="av-heavy fs12 nomar" style={{margin:'0'}} >
            {facture.user.infos.firstname}  {facture.user.infos.lastname}
          </h3>
        
        
          }
        
        
        </div>
        
        <div style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span> Réglèment </span>
        <h3 className="av-heavy fs12" style={{margin:'0'}}> {facture.payment} </h3>
        </div>
          
        <div className="flex  justbtw u-pad-vertical-xs" style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}} >
        <span className="fs13"> TOTAL </span>
        <h2 className="fs13 av-heavy" style={{margin:'0'}}> 
        
        {  Intl.NumberFormat('fr-FR').format(facture.total) }  Fcfa</h2>
        </div>
        
        
        
        
        
        
        
        
        
        
        </div>
        
        
        
        
        </div>
        
        </Link>
        
        
        
        </Fragment>
        :
        
        <div className="hv100 flex itemcenter column  rel" >
        
        <div style={{height:'50px',borderBottom:'1px solid #dee3e7',top:0}} className="abs w100 flex bgwhite itemcenter justcenter rel">
        
          <h1 className="av-heavy fs18 nomar fs18res"> Details Facture  </h1>
        
        
        
        </div>
        
        
        <Spin indicator={antIcon} className="w100 u-mar-top-xxl" />
        
        </div>
        
        
        
        
      
        


    );


}
