
import React, { useState,useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import axios from "axios";
import { useRecoilValue,useRecoilState } from 'recoil';

import {Button,DatePicker,Upload,Switch,Select,Form,Col,message, Row,Divider,Input} from 'antd';
import {URL} from "../../urlapi";
import ImgCrop from 'antd-img-crop';
import { CloseOutlined, CheckOutlined,UploadOutlined  } from '@ant-design/icons';
import { userInfos } from '../../store';


const {Option} = Select;
export default function  FormEmploye ({close}) {

    const [form] = Form.useForm()

    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [current, setCurrent] = useState(0);
    const [step1val,setStep1val] = useState()
    const [step2val,setStep2val] = useState()
  const [isAccess,setIsAccess] = useState(false)
  const [branches,setBranches] = useState(null)
  const user = useRecoilValue(userInfos)

  const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

  



  useEffect(() => {

    const getBranches = async () => {

        const result = await axios.get(URL+'/branches', {
            headers: {
                'tenant_user_api_key': token 

            }
        })

        setBranches(result.data.data);
    };




    getBranches();


}, []);



  


    function onChange(checked) {
        setIsAccess(checked)
    
      }

      const [fileList,setFileList] = useState([])

      const  getBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        }
      
      
  
     const handlePreview = async file => {
         
            file.preview = await getBase64(file.originFileObj);
      
        };
      const dum = ({ file, onSuccess }) =>{
          onSuccess("ok");
      }
  
  
      const handleChange = ({ fileList }) => setFileList( fileList );
  
  
      const normFile = (e) => {
          console.log('Upload event:', e);
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        };




    async function onSubmit ( values )  {

       

        let tvalues = {...values,...step1val,...step2val}
    
        console.log(tvalues)



       let infos = {
            firstname: tvalues.firstname,
            lastname: tvalues.lastname,
            role:  tvalues.roles,
            email: tvalues.email,
            phones: tvalues.phone,
            birthDate: tvalues.birthDate,
            employmentContractType: values.employmentContractType,
            dateOfBeginning: values.dateOfBeginning,
            dateOfTermination: values.dateOfTermination,
            employment: values.employment,
            qualification: values.qualification,
            level: values.level,
            salary: values.salary,
            branches: user.infos.branches[0]
          };

console.log("isacces",isAccess)

        let  app_use_settings = {
            is_app_user: isAccess,
            credentials: {
              email: tvalues.email,
              password: tvalues.password, 
            },
            send_email_reports: false
          }


          console.log(infos)
          console.log(app_use_settings)


        try {
            values.branches = [];
            setLoading(true)

            const result = await axios.post(URL + '/employees',
            {infos,app_use_settings},{
                headers: {
                    'tenant_user_api_key': token             
                }
            }
            )

            close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }





  const handleSubmit = async () => {




    form.validateFields()
        .then(async function (values) {
    console.log(values)


    let infos = {
        firstname: values.firstname,
        lastname: values.lastname,
        role:  values.roles,
        email: values.email,
        phones: values.phone,
        birthDate: values.birthDate,
        employmentContractType: values.employmentContractType,
        dateOfBeginning: values.dateOfBeginning,
        dateOfTermination: values.dateOfTermination,
        employment: values.employment,
        qualification: values.qualification,
        level: values.level,
        salary: values.salary,
        branches: user.infos.branches[0]
      };

console.log("isacces",isAccess)

    let  app_use_settings = {
        is_app_user: isAccess,
        credentials: {
          email: values.email,
          password: values.password, 
        },
        send_email_reports: false
      }


      console.log(infos)
      console.log(app_use_settings)


    try {
        values.branches = [];
        setLoading(true)

        const result = await axios.post(URL + '/employees',
        {infos,app_use_settings},{
            headers: {
                'tenant_user_api_key': token             
            }
        }
        )

        close()
        message.success('Enregistrement réussi!!')
        setLoading(false)









        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }




    })
    .catch((errorInfo) => {
      console.log( errorInfo );
    });




}








    return (

                                <div className="flex  column rel h100">

                               



<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s" style={{top:0,borderBottom:'1px solid #DDD',paddingBottom:10}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:34}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600"> Nouveau employé  </h2>








<div className="flex itemcenter abs" style={{right:34}}>





<Button type="primary" size="large" loading={loading} className="rad8" onClick={()=>handleSubmit()} >
    <span className="fs16 av-heavy">  Valider </span>
</Button>

</div>









</div>










<div className="u-mar-vertical-xxl  w50 mauto">



  <Form layout="vertical"        className="rel"
       form={form}
 style={{ padding:"0px 150px" }} onFinish={onSubmit} >



            
                  <>




<Row gutter={16}>




                                                <Col span={12}>
                                                    <Form.Item
                                                        name="firstname"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="lastname"
                                                        label="Prénom"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[{ type:'email',required: true, message: 'Veuillez entre l email' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Téléphone"
                                                        rules={[{  message: 'entrer un numéro' }]}
                                                    >
                                                        <Input placeholder="téléphone " />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={16}>

<Col span={12}>
<Form.Item
                                                        name="adresse"
                                                        label="Adresse"
                                                    >
                                                        <Input placeholder="adresse " />
                                                    </Form.Item>
</Col>


<Col span={12}>
                                            <Form.Item
                                                        name="birthDate"
                                                        label="Date de naissance"
                                                        className="w100 "
                                                    >

                                            <DatePicker  size={"large"} className="w100" />

</Form.Item>
</Col>



                       </Row>


                       <div >


<Row gutter={32}>

{/* <Col span={24}>

<Form.Item
                                        name="branches"
                                        label="Assigné un lieu de travail"
                                        rules={[{ required: true, message: 'Veuillez choisir un lieu de travail' }]}
                                    >
{branches &&
<Select
mode="multiple"
allowClear
style={{ width: '100%' }}
placeholder="selectionner une branche"
>
{
branches.map((b) => 

<Option value={b.id}>{b.business_name}</Option>
)

}


</Select>
}

</Form.Item>

</Col> */}

<Col span={24}>
<Form.Item
name="roles"
label="roles"
rules={[{ required: true, message: 'Veuillez choisir un role' }]}
>


<Select name="roles" >
<Option value="Manager">Manager</Option>
<Option value="Vendeur">Cassier</Option>
<Option value="Gestionnaire de stock">Gestionnaire de stock</Option>
<Option value="Comptable">Comptable</Option>
</Select>



</Form.Item>
</Col>

    </Row>


  <Row className="u-mar-top-m">

<span className="fs18 av-roman u-mar-bottom-m  u-mar-right-l">

L'employé à accès à l'application ?

</span>

  <span>   

<Switch onChange={onChange} defaultChecked={isAccess}  size="large"

checkedChildren={<CheckOutlined />}
unCheckedChildren={<CloseOutlined />}
/>

</span>
      </Row>

      {isAccess &&

<Row gutter={16} >

                     

<Col span={24}>
<Form.Item
name="password"
label="Mot de passe"
rules={[{required: true,message: 'Entrer un mot de passe' }]}
>
<Input type={'password'} />
</Form.Item>
</Col>









</Row>

}

  </div>

  

{/* <Row>
                                            <Col span={24}>
                                            <Form.Item
                                                        name="employmentContractType"
                                                        label="Type contrat"
                                                    >
                                                        <Select>
                                                            <Option value="CDI"> CDI </Option>
                                                            <Option value="CDD"> CDD </Option>
                                                            <Option value="Stagiaire"> Stagiaire </Option>
                                                            <Option value="Extra"> Extra </Option>


                                                        </Select>

                                                        </Form.Item>

                                                </Col>

                                            <Col span={24}>
                                            <Form.Item
                                                        name="dateOfBeginning"
                                                        label="Date de début"
                                                        className="w100"
                                                    >

                                            <DatePicker  size={"large"} className="w100" />

</Form.Item>
</Col>
                                                </Row>

                     <Row gutter={16}>

                     <Col span={24}>
                                                    <Form.Item
                                                        name="employment"
                                                        label="emploi"
                                                    >
                                                        <Input placeholder={"comptable"} />
                                                    </Form.Item>
                                                </Col>



                                                



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="qualification"
                                                        label="Qualification"
                                                    >
                                                        
                         
                                                        <Select name="qualification" >
                                                            <Option value="Niveau 1 (Employé)">Niveau 1 (Employé)</Option>
                                                            <Option value="Niveau 2 (Employé)">Niveau 2 (Employé)</Option>
                                                            <Option value="Niveau 3 (Employé)">Niveau 3 (Employé)</Option>
                                                            <Option value="Niveau 4 (Agent de maîtrise ou cadre)">Niveau 4 (cadre)</Option>
                                                            <Option value="Niveau 5 (Cadre)">Niveau 5 (Cadre)</Option>
                                                            </Select>



                                                    </Form.Item>
                                                </Col>
                                              


                                                <Col span={12}>
                                                    <Form.Item
                                                        name="level"
                                                        label="Niveau"
                                                    >
                                                        
                                                        <Select name="level">
                                                            <Option value="1">Échelon 1</Option>
                                                            <Option value="2">Échelon 2</Option>
                                                            <Option value="3">Échelon 3</Option>
                                                            <Option value="4">Échelon 4</Option>
                                                            <Option value="5">Échelon 5</Option>
                                                            <Option value="6">Échelon 6</Option>
                                                            <Option value="7">Échelon 7</Option>
                                                            <Option value="8">Échelon 8</Option>
                                                            <Option value="9">Échelon 9</Option>
                                                        </Select>


                                                    </Form.Item>
                                                </Col>







                         </Row>   

                         <Row>
                         <Col span={24}>
                                                    <Form.Item
                                                        name="salary"
                                                        label="salaire brut"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>



                             </Row>

 */}




                             <div className="grille u-mar-top-s flex u-pad-xs  order2 rad8 abs" style={{right:-45,top:0}} >


<div className=" flex column  w100">



    <ImgCrop rotate>
        <Upload
            onChange={handleChange} 
            customRequest={dum}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
        >
            {fileList.length < 1 && '+ Upload'}
        </Upload>
    </ImgCrop>





</div>


</div>





</>
                
                



  


                          






                                         




                                        </Form>


                                        </div>




                                </div>




    );


}
