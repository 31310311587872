








import React, {Fragment, useEffect, useState,useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Upload, Layout,Steps, Form, Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import ImgCrop from 'antd-img-crop';
import Step1Form from './form/step1formpur';
import Step2Form from './form/step2formpur';
import Step3Form from './form/step3formpur';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import {useHistory} from "react-router";
import LayoutPage from '../layout';


const {Option} = Select;
const { Step } = Steps;
const { TabPane } = Tabs;
export default function  AddPurchase ({close}) {
    const history = useHistory()

    const [loading,setLoading] = useState(false)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [current,setCurrent]= useState(0)


    const [loadingproducts,setLoadingproducts] = useState(true)
    const [loadingbranches,setLoadingbranches] = useState(true)
    const [loadingvendors,setLoadingvendors] = useState(true)
    const [form] = Form.useForm();
    const [data, setData] = useState({});
    const [data1, setData1] = useState({});

    const [products,setProducts] = useState(null)
    const [branches,setBranches] = useState(null)
    const [vendors,setVendors] = useState(null)

  
    useEffect(() => {


        const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

        const getProducts = async () => {


            const result = await axios.get(URL+'/products', {
                headers: {
                    'tenant_user_api_key': token             
                }
            })



            setProducts(result.data.data.map(v => ({...v, num: 1})));
            setLoadingproducts(false)
        };


        const getBranches = async () => {


            const result = await axios.get(URL+'/branches', {
                headers: {
                    'tenant_user_api_key': token             
                }
            })

            setBranches(result.data.data);
            setLoadingbranches(false);
        };









        const getsupliers= async () => {

    
                const result = await axios.get(URL+'/vendors', {
                    headers: {
                        'tenant_user_api_key': token             
                }
            })

            setVendors(result.data.data);
            setLoadingvendors(false)

    


        };




        getsupliers();
        getProducts();
        getBranches();

    }, []);


    
  
  

    const handleNextStep = useCallback(
        (data) => {

            let tvalues = {...data1,...data}
    
            console.log(tvalues)

            setData1(tvalues);

         setCurrent(current+1)


        },
        [current]
      );
    
      const handlePrevStep = useCallback(
        (data) => {
          setData({...data});
          console.log(data)
          setCurrent(current-1)
        },
        
        [current]
      );
    


      const  handleSubmit =  useCallback(async (data) => {
        console.log("Data", data);

        console.log(data1);

        let values = {...data1,...data}

        setData1(values);







let valuesfin = {}


valuesfin.infos = {
    purchase_number: values.purchase_number,
    expected_arrival_date: values.expected_arrival_date,
    vendor: values.vendor,
    branch_to_be_shipped: values.branch,
    invoice_number: values.invoice_number,
    billing_date: values.billing_date,
    description:  values.description
}





valuesfin.products = []




values.prod.map((p)=> {
    valuesfin.products.push({
        
        id: p._id,
        name: p.name,
        quantity: p.num,
        unit_price: p.supply_price,
        sub_total: p.num*p.supply_price




    })
})


valuesfin.total_price = values.total

valuesfin.cart_discount = 0
        
console.log(valuesfin)      

      //  values.isInventorytracked = onStock;



        const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));


        try {
           
            setLoading(true)
            const result = await axios.post(URL + '/purchases',
            valuesfin,{
                    headers: {
                        'tenant_user_api_key': token             
                    }
                }
            )

            message.success('Enregistrement réussi!!')
            close()
            history.push('/products/orders')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }





      }, [current]);
    





    return (






                            <div className="flex  column rel h100">

                          
                            <div
                            className="justcenter itemcenter flex column  w100   w100" >

                            <span className="av-heavy fs18"> Nouvel approvisionnement  </span>

                            <Divider className="u-mar-vertical-xs" />

                        </div>
                       

                            <Spin tip="Chargment..." size="large" spinning={loadingproducts && loadingbranches && loadingvendors }>



                                <Row gutter={32}  className=" w100  u u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres ">

                                <Col span={22} offset = {1} className=" w100  u-pad-top-s u-pad-horizontal-s   h100 blok rad16 u-mar-top-xl-res nopadres noradres ">


                                    <div className="flex u-mar-left-m  column rad8 grille25 bgwhite  nomarres u-pad-s-res" style={{height:'max-content'}}>



                                   
                                    <Steps size="small" className="u-mar-bottom-l"  current={current}>
    <Step title="Infos générales" />
    <Step title="Produits/ingrédients" />
    <Step title="Resumé" />
  </Steps>
                                           
                                           
                                           
                                       
                                           {current == 0 && <Step1Form data={data1}  vendors={vendors} branches={branches} onSuccess={handleNextStep} />}
                                           {current == 1 && <Step2Form data={data1}  products={products} onSuccess={handleNextStep} onBack={handlePrevStep} />}
                                           {current == 2 && <Step3Form data={data1}  loading={loading} onSuccess={handleSubmit} vendors={vendors} branches={branches} onBack={handlePrevStep} />}

                                      
                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

                              
                                </Row>

                            </Spin>

</div>
             
                            

    );


}
