
import React, { useState } from 'react';

import { NavLink } from "react-router-dom";

import {Input, Menu,Dropdown,Drawer } from 'antd';
import Mes from '../components/Components-messages/Message';
import Noti from '../components/Components-messages/Notif';
import Dcon from '../components/Components-messages/Drawer_conf';
import Cardset from '../components/Components-messages/settingCom';
import Layout from 'antd/lib/layout/layout';
import Rmessage from '../components/Components-messages/R_message';
import Chatmessage from '../components/Components-messages/Chat_message';

import {useHistory} from "react-router";
import { Link} from "react-router-dom";

import { PlusOutlined, CaretDownOutlined, LogoutOutlined} from '@ant-design/icons';

import notif from './notif.svg'
import {useAppContext} from "../libs/contextLib";
import styleCom from './styleCom.css';

export default function  Header () {

 const [loading,setLoading] = useState(false)
 const [visible,setVisible] = useState(false)
 const [visiblenotif,setVisiblenotif] = useState(false)
 const[viewcon, setViewconf]= useState(false)
 const [viewmessage, setViewmessage]= useState(false)
 const [viewmchat, setViewmchat]= useState(false)

    const history = useHistory()


    async function logout () {
        try {
            localStorage.removeItem('user')
            localStorage.removeItem('tenant')
            localStorage.removeItem('token')
            localStorage.removeItem('recoil-persist')
            history.push('/login')
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }



    const menu = (
        <Menu style={{width:'200px'}} >
            <Menu.Item key="0" className="flex itemcenter">


                <div className="flex column ">
                <span className="fs14"> { localStorage.getItem('user') && ( JSON.parse(localStorage.getItem('user')).infos.firstname + JSON.parse(localStorage.getItem('user')).infos.lastname ) } </span>
                    <span className="av-light fs12" style={{color:'#65676b'}}>  { localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).infos.email}  </span>

                </div>

            </Menu.Item>
            <Menu.Divider />

            <Menu.Item key="1" className="flex itemcenter rad8 ">

                <Link to={"/settings/compte"}  className="  itemcenter flex">

              
                <span className="fs13">Mon compte</span>


                </Link>




            </Menu.Item>

            <Menu.Item key="2" className="flex itemcenter rad8 ">

                <Link to={"/settings/preferences"}  className="  itemcenter flex">

              
                <span className="fs13">Paramètres</span>


                </Link>




            </Menu.Item>



            <Menu.Item key="3" className="flex itemcenter rad8 " onClick={logout}>

                <div className="itemcenter flex">

                    <span className="fs13">Déconnexion</span>

                </div>




            </Menu.Item>

        </Menu>
    );


    const menunotif = (
        <Menu style={{width:'360px'}} >


                <div className="flex column u-mar-left-s">
                    <span className="av-heavy fs20">Notifications</span>

                </div>

            <div className="flex column itemcenter">

                <img  height="112"
                     src={notif}
                     width="112" alt="" className="u-mar-vertical-l" />

            </div>

        </Menu>
    );





    return (





                <div className="header  flex  fixed justend itemcenter u-mar-bottom-s">



                <div className='container'>

                   <div className="flex itemcenter justbtw">


                  <div className='flex nav itemcenter'>
                    
     
                  <NavLink to={"/"} >
      <img src={'/logo.png'} height={34} className="u-mar-right-s"  />
</NavLink>
     
     
       




        <NavLink to={"/sales"}  activeClassName="selected"  className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Ventes</NavLink>

        <NavLink to={"/orders"}  activeClassName="selected" exact className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Commandes</NavLink>
        <NavLink to={"/customers"}  activeClassName="selected" className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Clients</NavLink>
        <NavLink to={"/products"}  activeClassName="selected"  className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Produits</NavLink>
        <NavLink to={"/reports"}  activeClassName="selected"  className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Rapports</NavLink>
        <NavLink to={"/treasury"}  activeClassName="selected" exact className=" white fW400 u-pad-horizontal-xs u-pad-vertical-xxs fs12  ">Tresorerie</NavLink>






                    </div>     




<div className="flex itemcenter  nopadres">





{/* <div className="u-mar-top-xs  u-mar-right-s  ">
                           <Link to={'/pos'} className=" fs12 flex itemcenter justcenter nomarvertical fs15res   whiteres rad50" style={{background:'#0050dc',color:'#fff',padding:'4px 16px'}}>
<span>

<svg data-v-3965b872="" width="20" height="20" viewBox="0 0 24 24" fill="none" class="icon"><path data-v-3965b872="" d="M3.99968 16V4H1.99968V2H4.99968C5.2649 2 5.51925 2.10536 5.70679 2.29289C5.89432 2.48043 5.99968 2.73478 5.99968 3V15H18.4377L20.4377 7H7.99968V5H21.7197C21.8717 5 22.0217 5.03466 22.1583 5.10134C22.2949 5.16801 22.4145 5.26495 22.508 5.38479C22.6015 5.50462 22.6665 5.6442 22.698 5.79291C22.7295 5.94162 22.7266 6.09555 22.6897 6.243L20.1897 16.243C20.1355 16.4592 20.0106 16.6512 19.8349 16.7883C19.6591 16.9255 19.4426 17 19.2197 17H4.99968C4.73446 17 4.48011 16.8946 4.29257 16.7071C4.10504 16.5196 3.99968 16.2652 3.99968 16ZM5.99968 23C5.46925 23 4.96054 22.7893 4.58547 22.4142C4.21039 22.0391 3.99968 21.5304 3.99968 21C3.99968 20.4696 4.21039 19.9609 4.58547 19.5858C4.96054 19.2107 5.46925 19 5.99968 19C6.53011 19 7.03882 19.2107 7.41389 19.5858C7.78897 19.9609 7.99968 20.4696 7.99968 21C7.99968 21.5304 7.78897 22.0391 7.41389 22.4142C7.03882 22.7893 6.53011 23 5.99968 23ZM17.9997 23C17.4692 23 16.9605 22.7893 16.5855 22.4142C16.2104 22.0391 15.9997 21.5304 15.9997 21C15.9997 20.4696 16.2104 19.9609 16.5855 19.5858C16.9605 19.2107 17.4692 19 17.9997 19C18.5301 19 19.0388 19.2107 19.4139 19.5858C19.789 19.9609 19.9997 20.4696 19.9997 21C19.9997 21.5304 19.789 22.0391 19.4139 22.4142C19.0388 22.7893 18.5301 23 17.9997 23Z" fill="white"></path></svg>
</span>

                    <span className="fs14 u-mar-left-xs av-roman white"> </span>

                </Link>
                           </div> */}





                           <div className="btnaction u-mar-horizontal-s justcenter itemcenter flex cursor" onClick={()=>setVisible(true)}>

                           <svg aria-labelledby="ember165__title" role="img" height="48" width="48" viewBox="-15 -15 48 48" id="ember165" class="ember-view"><title id="ember165__title">Messages</title>
  <defs>
    <mask id="messenger__icon__badge-mask" width="18" height="18">
      <rect x="0" y="0" width="18" height="18" fill="white"></rect>

      <circle cx="21" cy="-4" r="12" fill="white"></circle>
    </mask>
  </defs>
  <path class="messenger__icon__chat-bubble" mask="" fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H3V16.9992C3 17.8234 3.94104 18.2938 4.60024 17.7991L8.33 15H16C17.1046 15 18 14.1046 18 13V2C18 0.895431 17.1046 0 16 0H2ZM8.33 13H16V2H2V13H3H8.33Z"></path>


</svg>
                           </div> 






                        
{/* 
                           <div className="btnaction  justcenter u-mar-right-xxs itemcenter flex cursor" onClick={()=>setVisiblenotif(true)}>

                           <svg aria-labelledby="ember168__title" role="img" height="48" width="48" viewBox="-12 -12 48 48" id="ember168" class="notifications-icon notifications-icon--inverse  ember-view"><title id="ember168__title">Notifications</title>

<defs>
  <mask id="notifications-icon__badge-mask" width="33" height="30">
    <rect x="0" y="0" width="33" height="30" fill="white"></rect>

    <circle cx="24" cy="-1" r="12" fill="white"></circle>
  </mask>
</defs>

<path class="notifications-icon__bell" mask="" fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M13.6947 4.18805C13.7308 4.04805 13.75 3.90127 13.75 3.75C13.75 2.7835 12.9665 2 12 2C11.0335 2 10.25 2.7835 10.25 3.75C10.25 3.90126 10.2692 4.04804 10.3053 4.18804C7.17959 4.90293 5 7.58475 5 11V14C5 14.6994 4.63475 15.4951 4.16796 16.1953C3.94541 16.5291 3.72139 16.8093 3.55326 17.0055C3.46965 17.103 3.40103 17.1784 3.35491 17.2278C3.33188 17.2525 3.31455 17.2706 3.30388 17.2816L3.29259 17.2932L3.29173 17.2941C3.00669 17.5801 2.92158 18.0095 3.07615 18.3827C3.23093 18.7564 3.59557 19 4.00003 19H20C20.4045 19 20.7691 18.7564 20.9239 18.3827C21.0786 18.0091 20.9932 17.5792 20.7075 17.2932L20.6962 17.2816C20.6889 17.2741 20.6786 17.2634 20.6656 17.2496L20.6451 17.2278C20.599 17.1784 20.5304 17.103 20.4468 17.0055C20.2786 16.8093 20.0546 16.5291 19.8321 16.1953C19.3653 15.4951 19 14.6994 19 14V11C19 7.58475 16.8204 4.90294 13.6947 4.18805ZM12 6C9.10051 6 7 8.10051 7 11V14C7 15.1446 6.50839 16.2146 6.02702 17H17.973C17.4916 16.2146 17 15.1446 17 14V11C17 8.10051 14.8995 6 12 6ZM12 23C10.6038 23 9.39852 22.1825 8.83679 21H15.1632C14.6014 22.1825 13.3962 23 12 23Z"></path>


</svg>
</div> */}



<div className="flex justcenter column cursor userb  usera u-pad-horizontal-xs h38p" style={{marginRight:'1px'}}>

<span className="fs10 fW500 u-mar-right-xs" style={{color:'rgb(161 161 170)'}} > 
{ localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).infos.firstname}
 </span>

 <span className="fs12 fW500 u-mar-right-xs white" > 
 { localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).infos.lastname}

 </span>


</div>

                       <Dropdown overlay={menu} class="cursor u-mar-right-xs" placement="bottomRight" trigger={['click']}>


                               <div className="flex itemcenter cursor userb u-pad-horizontal-xs h38p">

                                   <span className="fs15 flex itemcenter fW500 u-mar-right-xs white" > 
                                   <svg viewBox="0 0 200 200" width="22" height="22"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>

                                    </span>

                                   <span className="fs15 flex itemcenter" >

                               <CaretDownOutlined className='white' />

                           </span>


                               </div>


                           </Dropdown>


                           </div>




                   </div>


               </div>









{/* Drawer des messages */}
<Drawer
width={450}
onClose={()=>setVisible(false)}
visible={visible}
closable={false}
bodyStyle={{ paddingBottom: 0,paddingTop:1, margin: 25}}
destroyOnClose={true}
>
     
{
   !viewmessage ? <Mes
message={()=>setViewmessage(true)}
 visconf={()=>setViewconf(true)}
vis={()=>setVisible(false)}
/> : !viewmchat ? <Rmessage
info={()=>setViewmchat(true)}
 sends={()=>setViewmessage(false)}
/> : <Chatmessage 
// menu={opton}
m={menu}
data={localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).infos.firstname}
back={()=>setViewmchat(false)}
// option={ e => e.preventDefault()}
/>
}
 
</Drawer>



{/* Drawer des notifications */}
<Drawer
    onClose={()=>setVisiblenotif(false)}
    width={450}
    visible={visiblenotif}
    closable={false}
    bodyStyle={{ paddingBottom: 0,paddingTop:12, margin: 25}}
    destroyOnClose={true}
>
 <Noti
 notif={()=>setVisiblenotif(false)}

/> 
</Drawer>


{/* Drawer de parametre*/}
<Drawer
        
        placement={'bottom'}
        closable={false}
        onClose={()=>setViewconf(false)}
        visible={viewcon}
       height={'100vh'}
       bodyStyle={{ paddingBottom: 0,paddingTop:12,}}
       destroyOnClose={true}
      
      > 
         <Dcon
         close={()=>setViewconf(false)}
         
         />
      
</Drawer> 






















                </div>



            );


}
