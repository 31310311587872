
import React, { useState } from 'react';
import {useHistory,useParams } from "react-router";
import axios from "axios";

import LeftSide from '../sider/sider'
import { Layout, Modal,Button, Menu,Switch} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {URL} from "../../urlapi";


const { SubMenu } = Menu;
const {  Content } = Layout;

export default function  Home () {
 const [loading,setLoading] = useState(false)
 const [isCatalogOnline,setisCatalogOnline] = useState(true)
 const [modules,setModules] = useState(["cash_flow","products","payroll","orders","marketing"])

 let { token } = useParams();
 const history = useHistory()

 function onChange(checked) {
    setisCatalogOnline(checked)

  }

  function changeModule(module) {
    if ( modules.includes(module) )
    {
        setModules(modules.filter(u => u != module))

    } else {
        setModules(modules.concat(module))
    }
    }

  function activeModule(module) {
return modules.includes(module) 
}

async function handleOnboarding (  )  {




    try {
      setLoading(true)
 

    /*   const result = await axios.patch(URL+'/branches',
      {modules,isCatalogOnline},{
        headers: {
            'tenant_user_api_key':token
        }
    }
      ) */



  /*     const serializedState = JSON.stringify(token)
      localStorage.setItem('token',serializedState )



      const serializedStateUser = JSON.stringify(result.data.user)
      localStorage.setItem('user',serializedStateUser )

 */

     
      history.push("/login");

      setLoading(false)

  } catch(e) {
      console.log(e.response)
      setLoading(false)

  }


  }

    return (





            <Layout className=" bgapp u-pad-horizontal-l">

                <div className="u-mar-top-s">

                    <Layout className="bgapp" >
                        <Content  className="bgwhite box-shadow u-pad-m">


                        <Modal footer={null} closable={false} bodyStyle={{padding:"40px"}} width={"54%"} visible={true} maskClosable={false} >
        

                        <div className="get" >
                            <h1 className="av-heavy "> 1 - Qu'est ce que vous aimeriez faire sur Onebaz ?</h1>
                            <span className="fs18 av-light">C'est juste pour vous aider à démarrer. Vous pourrez modifier plustard.
                        </span>
                        </div>



<div className="tile-cabinet flex wrap u-mar-top-l justbtw">
    <div className="tileContainer tresor" onClick={()=>changeModule("cash_flow")}>
        <div className={"tileContent" +( activeModule("cash_flow") ? " ticked" : "") }  >
            <div className="tileElements">
                <div className="images">
                <div className="image checkmark"></div>
                <div className="image icon"></div>
                </div>
                <div className="textWrapper">
                    <label for="invoiceCustomers" className="tileText">Gerez votre trésorerie</label>
                </div>
            </div>
        </div>
    </div>
        
    <div className="tileContainer  retailSales" onClick={()=>changeModule("products")}>
    <div className={"tileContent" +( activeModule("products") ? " ticked" : "") }  >
            <div className="tileElements">
                <div className="images">
                    <div className="image checkmark">
                        </div><div className="image icon"></div></div>
                        <div className="textWrapper">
                            <label for="trackExpenses" className="tileText">Gestion de produits</label></div></div></div></div>



                            <div className="tileContainer invoiceCustomers" onClick={()=>changeModule("services")}>
                
                
                            <div className={"tileContent" +( activeModule("services") ? " ticked" : "") }  >
                    
                    
                    <div className="tileElements"><div className="images"><div className="image checkmark"></div><div className="image icon"></div></div>
            
            
            <div className="textWrapper"><label for="retailSales" className="tileText">Gestion de services</label></div></div></div>
            
            </div>     

                            
       <div className="tileContainer trackSalesTax" onClick={()=>changeModule("marketing")}>
           
       <div className={"tileContent" +( activeModule("marketing") ? " ticked" : "") }  >
               
               <div className="tileElements"><div className="images">
           <div className="image checkmark"></div><div className="image icon"></div></div><div className="textWrapper">
               <label for="trackSalesTax" className="tileText">Gestion marketing</label></div></div></div>
               </div>
               


               
          
            
            <div className="tileContainer payEmployees" onClick={()=>changeModule("payroll")} >
            <div className={"tileContent" +( activeModule("payroll") ? " ticked" : "") }  >
                    
                    <div className="tileElements">
                <div className="images"><div className="image checkmark"></div><div className="image icon"></div></div><div className="textWrapper"><label for="payEmployees" className="tileText">Gestion de paie</label></div></div></div>
                </div>
                
                
                <div className="tileContainer trackInventory" onClick={()=>changeModule("orders")} >
                <div className={"tileContent" +( activeModule("orders") ? " ticked" : "") }  >
                        
                        <div className="tileElements"><div className="images"><div className="image checkmark"></div>
                <div className="image icon"></div></div><div className="textWrapper"><label for="trackInventory" className="tileText">Gestion de commande</label></div></div></div>
                </div>
                
                
                <div className="tileContainer manageBills" onClick={()=>changeModule("reservations")}>
                <div className={"tileContent" +( activeModule("reservations") ? " ticked" : "") }  >
                        <div className="tileElements"><div className="images"><div className="image checkmark"></div><div className="image icon"></div></div><div className="textWrapper"><label for="manageBills" className="tileText">Gestion de réservations</label></div></div></div></div>
                


             <div className="tileContainer consignes" onClick={()=>changeModule("consignement")}>
             <div className={"tileContent" +( activeModule("consignement") ? " ticked" : "") }  >
            <div className="tileElements">
                <div className="images">
                <div className="image checkmark"></div>
                <div className="image icon"></div>
                </div>
                <div className="textWrapper">
                    <label for="invoiceCustomers" className="tileText">Gerez les consignations</label>
                </div>
            </div>
        </div>
    </div>      
                
                
                </div>





    <div className="get u-mar-top-l" >
                            <h1 className="av-heavy "> 2 - Aimeriez vous avoir un catalogue en ligne ?</h1>
                            <span className="fs18 av-light">Vous avez la possibilité de montrer à vos clients  vos produits
                        </span>

<br/> <br/>
<span className="fs18 av-light u-mar-right-s"> {window.location.href.split('')[0].split('.')[0]+'myonebaz.com'} </span>
<span>   
    
     <Switch defaultChecked onChange={onChange} 

checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
/>

 </span>
  


                        </div>


<div className="w100 u-mar-top-s right">
<Button type="primary" size="large" className="w25 colwhite rad4 u-mar-top-s" loading={loading} onClick={() => handleOnboarding() } >
          Commencer 
        </Button>
</div>


                        </Modal>
                        


                        </Content>
                    </Layout>
                </div>
            </Layout>



            );


}
