import {  Row ,Input} from 'antd';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

function Rmessage ({sends,info}) {


  return (
    <div className=''>
         <Row justify="">
    <div  className='deco justcenter itemcenter flex' onClick={sends}>
    
    <svg data-testid="close-icon" width="24" height="24" viewBox="0 0 24 24" role="img"  title="Fermer"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M5.75 5.75L18.25 18.25M18.25 5.75L5.75 18.25"></path></svg>
     
    </div>
    <div className='pp'>
    <span className="st-not"> New messages  </span>
    </div>
    
    </Row>
    
    <div className='center space'>
    <div className='rd'>
  <div className='cv'>
      <svg aria-labelledby="ember436__title" className='' role="img" height="20" width="20" viewBox="0 0 24 24">
           <path  fill-rule="evenodd" clip-rule="evenodd" d="M5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5ZM10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.2105 18 13.7873 17.4274 15.0491 16.4633L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L16.4633 15.0491C17.4274 13.7873 18 12.2105 18 10.5C18 6.35786 14.6421 3 10.5 3Z"></path>
      </svg> 
      </div>
  <div className='ip'>
  <Input size="large" placeholder="large size" className='' />
  </div>
</div>
    </div>
    <div className='space'>
        <Row className='form-ms ' onClick={info}>
            <div className='box justcenter itemcenter flex'>
            <span className='center'>S</span>
            </div>
            <div className='h-space'>
               <div className=''>
               <span className='title'>ivan</span>
               </div>
               <div className=''>
               <span className='desc'>ivankouamou21@gmail.com</span>
               </div>

            </div>
        </Row>

    </div>
  
  
    </div>
  );
};

export default Rmessage; 