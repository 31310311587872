/**
 * Created by sinclaireric on 14/05/2018.
 */

/* export const URL = 'https://api.monsieurtravaux.ci';
 */

 export const URL = `https://backendsempos-production.up.railway.app/v1/${window.location.host.split('.')[0]}`;
 ////export const URL = `https://onebaz.uc.r.appspot.com/v1/complexendzengue`;
 
