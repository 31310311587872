







import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { SubNav } from '../../components/SubNav';
import { customerNav } from '../../config/navItems';
import {Dropdown,Spin,Modal,message, Table,Input} from 'antd';
import { useGetCustomersGroups } from '../../api/api';
import LayoutPage from '../layout';
import AddCustomerGroup from './component/AddCustomerGroup';
import TabGroupPlaceholder from './component/tabgroupplaceholder'
import {EllipsisOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';


export default function  CustomerGroups () {

    const [searchText, setSearchText] = useState("");
    const [customersGroup,setCustomersGroup] = useState(null)
    const [group,setGroup] = useState(null)
    const {confirm} = Modal

    const { data, error, isLoading, refetch } = useGetCustomersGroups({}, {});


    useEffect(() => {


        setCustomersGroup(data?.data);



    }, [data]);


    const showDeleteConfirm =  () => {
        confirm({
          title: "Supression du groupe "+group.name,
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer la supression de ce groupe?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
            //await editCustomer({status:"delete"})
            message.error ('Suppression réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    const items = [

        
        
          {
            key: '2',
            label: (
              <span className="fs11 flex itemcenter">
            <EditOutlined />  <AddCustomerGroup group={group}/>
              </span>
            ),
          },
      
          {
            key: '3',
            label: (
              <span className="nega fs11" onClick={showDeleteConfirm}>
                <DeleteOutlined /> Supprimer le groupe
              </span>
            ),
          }
        ];

  

    const columns = [

     
        {
            title: 'Nom',
            dataIndex: 'name',
            width: 150,
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.description).toLowerCase().includes(value.toLowerCase()) 
            }            
            
        },

     

        {
            title: 'Description',
            dataIndex: 'description',
            width: 150,
        },
        {
            title: 'nombre de clients',
            dataIndex: 'clients',
            width: 40,
        },
        {
            title: '',
            key: 'action',
            width: 10,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
    setGroup(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },
    ];



          
  



    return (





        <LayoutPage>

                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                        


                            <SubNav items={customerNav}  />         

       


<div className="container">

                            <div className="grille w100    u-mar-top-l   noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                <Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par nom et description" style={{width:'250px',height:'45px!important'}} />

                                </div>

                                  
                                  <AddCustomerGroup />


                                


                                </div>

                                <Table columns={columns} locale={{emptyText:<></>}}  loading={{indicator:<TabGroupPlaceholder/>,spinning:isLoading}} dataSource={customersGroup}

                                     
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>
</div>
                            


                                    </LayoutPage>



    );


}
