import React, { useState,useEffect } from 'react';

import {Button,Modal,DatePicker,Select,Spin, Form,Col,message, Row,Input, InputNumber} from 'antd';

import locale from 'antd/es/date-picker/locale/fr_FR';

import { useAddPaymentCustomer } from '../../../api/api';


const {Option} = Select;
const { TextArea } = Input;


export default function  AddPayment ({user}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)

    const { mutateAsync: addPaymentCustomer } = useAddPaymentCustomer(user?.id);





    async function onSubmit ( values )  {

        console.log(values)



        try {
            setLoading(true)

          let res = await addPaymentCustomer(values)

            setVisible(false)
            message.success('Transaction ajoutée')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

<div  onClick={()=>setVisible(true)}>

          <span className="fs11 u-mar-left-xxs"> 
          Ajouter un paiement
</span>
</div>

<Modal

    width={450}
    okButtonProps={{form:'payment_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> Ajouter un paiement - {user?.name}  </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:'#F7F7F9' }}
    okText="Ajouter"
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s" style={{ overflow: "auto"}}>

                            



<Form layout="vertical" id="payment_form"  onFinish={onSubmit} className="h100 justbtw  flex column" >


                                            <Row>
                                                <Col span={24}>
                                                <Form.Item
                                                name="operation_type"
                                                label="Type d'opération"
                                                rules={[{ required: true, message: 'choisir un type' }]}

                                                >
                                                <Select>

                                            

                                                <option  value={"collect"} > Crédit </option>
                                                <option  value={"payment"} > Paiement</option>




                                                </Select>
                                                </Form.Item>
                                                </Col>



                                            </Row>

                                            <Row>

                                            <Col span={24}>
                                                <Form.Item
                                                name="payment_type"
                                                label="Moyen de paiment"
                                                rules={[{ required: true, message: 'choisir un type' }]}

                                                >
                                                <Select>

                                            

                                                <option  value={"cash"} > Espèce </option>
                                                <option  value={"mobile_money"} > Mobile money </option>
                                                <option  value={"orange_money"} > Orange money </option>
                                                <option  value={"bank_transfer"} > Virement bancaire </option>
                                                <option  value={"cheque"} > Chèque</option>




                                                </Select>
                                                </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row>


                                                <Col span={24}>
                                                <Form.Item
                                                        name="date"
                                                        label="Date "
                                                        className="w100"
                                                        rules={[{ required: true, message: 'choisir une date' }]}

                                                    >

                                            <DatePicker locale={locale}   className="w100" />

</Form.Item>
                                                </Col>

                                            </Row>

                                            <Row>
                                            <Col span={24}>
                                                    <Form.Item
                                                        name="amount"
                                                        label="Montant"
                                                        rules={[{ required:true, message: 'Veuillez entrer le montant' }]}
                                                    >
                                                        <InputNumber  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

<Row gutter={16}>





                                                <Col span={24}>

                                                    <Form.Item
                                                        name="note"
                                                        label="Note"
                                                        
                                                    >
                                                      <TextArea 
                                                          autoSize={{ minRows: 3, maxRows: 5 }}
                                                      />

                                                    </Form.Item>


                                                </Col>

                                            </Row>












                                        </Form>












</div>







</Modal>

</>
) }