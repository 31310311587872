
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { SubNav } from '../../components/SubNav';
import { reportNav } from '../../config/navItems';
import TabPlaceholder from './components/tabplaceholder'; 

import {Select, Table,Typography,Input} from 'antd';
import { useGetOrders } from '../../api/api';
import {
    useRecoilValue,
  } from 'recoil';

import {useHistory} from "react-router";

import LayoutPage from '../layout';

import SelectDate from '../../components/selectdate';

import { activeStartDate } from "../../store";

moment.locale('fr')

const { Text } = Typography;
export default function  Orders () {

    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [visibleD,setVisibleD] = useState(false)
    const [order,setOrder] = useState(null)
    const history = useHistory()
    const [searchText, setSearchText] = useState("");

    const [orders,setOrders] = useState(null)
    const [factureId,setFactureId] = useState(null)
    const Startdate = useRecoilValue(activeStartDate);


    const close = () => {

        setVisible(false)
        setVisibleD(false)
        setReload(reload+1)

    }

    const { data, error, isLoading,isFetching, refetch } = useGetOrders({}, {});


    useEffect(() => {

     
        setOrders(data?.data);

    }, [data]);


    useEffect(() => {
        refetch();
      }, [Startdate]);



    const change  =  (r)  => {
        setVisibleD(true)
        setOrder(r)
        
            }





    const columns = [
        {
            title: 'Ref',
            width: 90,
            dataIndex: 'order_id',
            filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.order_id).toLowerCase().includes(value.toLowerCase()) || String(record.sale?.customer.name).toLowerCase().includes(value.toLowerCase()) || String(record.sale?.customer.lastname).toLowerCase().includes(value.toLowerCase()) || String(record.status).toLowerCase().includes(value.toLowerCase())
}



        },
        {
            title: 'Client',
            dataIndex: '',
            width: 170,
            render: item =>
            <>

{item.sale?.customer  != null &&

<div className="flex itemcenter">

<div class="flex column">
  
    
        <span > {item.sale?.customer.name}</span>
 
<span className="fs10 fW300" style={{color:'#7e838f'}}>{item.sale?.customer.phones}</span>
</div>

</div>

}

            </>

    
        },
  


        {
            title: 'Montant',
            dataIndex: '',
            width: 150,
            render: item =>
                <>
{Intl.NumberFormat('fr-FR').format(item.sale?.total + (item.exp_fee ? item.exp_fee : 0) ) }
<span className="fs10 u-mar-left-xxs">Fcfa</span>
                </>

        },



        {
            title: 'Adresse',
            dataIndex: 'address',
            width: 250,

    
        },

     /*    {
            title: 'Livreur',
            dataIndex: '',
            width: 150,

            render: item =>
            <>

{item.delivery_employee != null &&

<div className="flex itemcenter">
<svg viewBox="0 0 200 200" width="30" height="30"><circle fill="#F3F7FA" cx="100" cy="100" r="100"></circle><path fill="#BCC2D0" d="M141.9 128.3c-27.1-1.4-79.3-.5-97.5-.1-7.9.2-15.6 2.6-22 7.2-4 2.9-7.9 6.9-10.2 12.3C29.1 178.9 62.1 200 100 200c13.3 0 26.4-2.6 38.6-7.7 8.3-3.7 16.1-8.5 23.3-14.1 2.5-12.9 7.1-48.5-20-49.9z"></path><path fill="#E1E4EB" d="M75.3 105.5l-.3 35.7c0 2.9.6 5.8 1.8 8.5l.2.5c3.6 8.2 12.1 13.1 21 12.1 4.2-.5 8.2-2.2 11.4-5 6.2-5.4 10.1-12.9 11.2-21l1.6-11.6-46.9-19.2z"></path><path fill="#BCC2D0" d="M118.5 144.5c.7-1.5 1.4-3.8 2.2-8.3l1.6-11.6-47-19.1 16.3 22.3c3.4 4.7 7.6 8.8 12.5 12 4.2 2.8 9.4 5.2 14.4 4.7z"></path><path fill="#E1E4EB" d="M148.5 82.1c-1.8 25-10.4 69.7-45 51.6 0 0-12.3-5.2-22.7-19.1-2.9-3.9-7.1-11.5-8.7-16.1L67.7 85c-6.1-16.6-3.3-35.4 8-49.1C82 28.2 95 22.2 108 22.3c22.8.2 31.2 10.4 36.1 24 3.2 8.8 5 26.5 4.4 35.8z"></path><path fill="#BCC2D0" d="M82 96.5c-1.5-5.3-10 2.1-10 2.1.9 2.4 2 4.7 3.2 7v3.1c5.2-1.6 8.3-7 6.8-12.2z"></path><path fill="#97A0B6" d="M83.3 11.4c-28.2 7.8-44.7 37.3-36.8 65.7 4.2 15.1 14.8 27.7 29.1 34.3v-10.7l4.5-9s38.5-14.6 53.2-49.3c0 0 5.4 15.1 11.8 27.6 1.2 2.5 2.5 4.9 3.7 7.1.1-.5.3-1 .4-1.5 2.1-8.9 2-18.1-.5-26.9-7.8-28.5-37.1-45.1-65.4-37.3z"></path><circle fill="#E1E4EB" cx="77.7" cy="95.8" r="9.9"></circle></svg>




<div class="flex column u-mar-left-xs">
  
    
        <span className="coltext  av-heavy fs13"> {item.delivery_employee.infos.firstname}</span>
 
<span className="coltext fs13"> {item.delivery_employee.infos.lastname}</span>

</div>

</div>

}

            </>

    
        }, */

        {
            title: 'Paiement',
            dataIndex: 'payment_status',
            width: 175,

            render: item =>
            <>
{ item == "Reglé" ? <span className="u-pad-xxs greenstatus rad4 fs12"> {item} </span>  : item == "Attente de paiement" ? <span className="u-pad-xxs yellowstatus rad4 fs12"> {item} </span> : <span className="u-pad-xxs  redstatus rad4 fs12"> {item} </span> }

            </>

        }
,

        {
            title: 'Status',
            dataIndex: 'status',
            width: 165,

            render: item =>
            <>

{ item == "Livrée" ? <span className="u-pad-xxs greenstatus rad4 fs12"> {item} </span> : item == "Non traitée" ? <span className="u-pad-xxs graystatus rad4 fs12"> {item} </span> : item == "Traitée" ? <span className="u-pad-xxs bluestatus rad4 fs12"> {item} </span> : item == "Livraison en cours" ? <span className="u-pad-xxs yellowstatus rad4 fs12"> {item} </span> : <span className="u-pad-xxs redstatus rad4 fs12"> {item} </span> }

            </>

        }


    ];







    return (





  
       
<LayoutPage>

                            <SubNav items={reportNav}  />     

                            <div className="container">
                            <div className="grille w100  u-mar-top-l  hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw itemcenter u-mar-bottom-s">




                                <div className='flex grille itemcenter noneres'>
                                <div className='w25'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par ID..." style={{minWidth:'210px',height:'32px!important'}} />

</div>

<Select className='w20 u-mar-left-s'  defaultValue="produit">
                                                <option value={"produit"}>Toutes les commandes</option>
                                          


                                                <option  value={"terminé"} > Commandes terminées  </option>
                                                <option  value={"encours"} > Commandes en cours   </option>
                                                <option  value={"annulé"} > Commandes annulées  </option>
                                           

                                                </Select>
</div>

                           
                                 
<div className='flex itemcenter'>


                                    <div className="flex itemcenter " >
                                        


                


                <div className="separator nonedisplayres"></div>


                                    </div>


                                
                                    <SelectDate />

                                </div>
                      


                                </div>

                                <Table columns={columns} locale={{emptyText:<></>}}   loading={{indicator:<TabPlaceholder/>,spinning:isFetching}} pagination={false} dataSource={orders} scroll={{y: 500}} pagination={false}

                                       onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}
                                       size="default" 
                                       
                                       summary={(pageData) => {
   
                                        return (
                                          
                                          <Table.Summary fixed>
                                         
                                            <Table.Summary.Row >
                                              <Table.Summary.Cell index={0} colSpan={2} className='fW600'>{orders && 'Resumé'}</Table.Summary.Cell>
                                              <Table.Summary.Cell index={1} colSpan={2}>
                                                <Text className='fW600' >---</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={2}>
                                                <Text className='fW600'>---</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={3}>
                                                <Text className='fW600'>---</Text>
                                              </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                    
                                          </Table.Summary>
                                        );
                                      }}
                                       
                                       
                                       />


                            </div>

                            </div>


              
                            </LayoutPage>

    );


}
