export const customerNav = {
    heading:'Clients', 
    nav: [
       
        {
            title:"Répertoire clients",
            href:"/customers"
        },
        {
            title:"Groupe de clients",
            href:"/customers/groups"
        }
    ]

    
    }

    export const salesNav = {
        heading:'Ventes', 
        nav: [
           
            {
                title:"Liste des ventes",
                href:"/sales"
            }/* ,
            {
                title:"Objectifs",
                href:"/sales/goals"
            } */
        ]
    
        
        }


        export const orderNav = {
            heading:'Commandes', 
            nav: [
               
                {
                    title:"Liste des commandes",
                    href:"/orders"
                }
            ]
        
            
            }

            export const tresorNav = {
                heading:'Tresorerie', 
                nav: [
                    {
                        title:"Apercu",
                        href:"/treasury"
                    },
                   /*  {
                        title:"Encaissement",
                        href:"/treasury"
                    },
                    {
                        title:"Decaissement",
                        href:"/treasury"
                    },
                    {
                        title:"Catégories",
                        href:"/treasury"
                    } */
                ]
            
                
                }

            export const productNav = {
                heading:'Produits', 
                nav: [
                   
                    {
                        title:"Produits",
                        href:"/products"
                    },
                    {
                        title:"Catégories",
                        href:"/products/categories"
                    },
                    {
                        title:"Fournisseurs",
                        href:"/products/supliers"
                    },
                    {
                        title:"Marques",
                        href:"/products/brands"
                    },
                    {
                        title:"Approvisionnement",
                        href:"/products/orders"
                    }/* ,
                    {
                        title:"Déstockage",
                        href:"/products/destock"
                    } */
                ]
            
                
                }


                export const reportNav = {
                    heading:'Rapports', 
                    nav: [
                       
                        {
                            title:"Ventes",
                            href:"/reports"
                        },
                        {
                            title:"Stock",
                            href:"/reports/stock"
                        },
                        {
                            title:"Commandes",
                            href:"/reports/orders"
                        }
                        /* ,
                        {
                            title:"Approvisionnement",
                            href:"/reports/appro"
                        }
                        ,
                        {
                            title:"Paiements",
                            href:"/reports/payments"
                        } */
                    ]
                
                    
                    }
            



            export const settingNav = {
                heading:'Paramètres', 
                nav: [
                   
                    {
                        title:"Entreprise",
                        href:"/settings/preferences"
                    },
                    {
                        title:"Employés",
                        href:"/settings/team"
                    },
                    {
                        title:"Roles",
                        href:"/settings/roles"
                    },
                    {
                        title:"Licences",
                        href:"/settings/licences"
                    }
                ]
            
                
                }