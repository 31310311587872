import { createContext,useContext, ReactNode } from 'react';
import Axios, { AxiosInstance, AxiosTransformer } from 'axios';
import { notification } from 'antd';
import { createBrowserHistory } from 'history';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import qs from 'qs';
import {URL} from '../urlapi'; 

const history = createBrowserHistory();

const axios = Axios.create({
    baseURL: URL + '',
    timeout: 500000,
    headers: {
        'Content-Type': 'application/json',
    },
});

axios.interceptors.request.use((config) => {
    // Read token for anywhere, in this case directly from localStorage
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    if (token) {
        config.headers.tenant_user_api_key = token;
    }

    return config;
});

// response interceptor
axios.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }

        notification.error({
            message: ` ${response.statusText}: ${response}`,
            description: data || response.statusText || 'Error',
        });

        if (response.status === 401) {
            window.location.href = '/login';
        }

        return Promise.reject(new Error(response.statusText || 'Error'));
    },
    (error) => {
        console.log('err:', error, error.response); // for debug
        let msg = "请求错误";
        if (error.response && error.response.status) {
            switch (error.response.status) {
                
                case 401:
                    window.location.href = '/login';

                    break;
                
                case 403:
                    window.location.href = '/login';
                    break;
                // 404              
                case 404:
                    notification.error({
                        message: `ERREUR`,
                        description: error.response.data?.message || 'Error',
                    });
                    break;
                case 406:
                    notification.error({
                        message: `ERREUR`,
                        description: error.response.data?.msg || 'Error',
                    });
                    break;
                default:
                    notification.error({
                        message: `ERREUR`,
                        description: error.response.data?.message || 'Erreur rencontrée',
                    });

            }
        }

        // throw new Error(error);
        return Promise.reject(error);
    },
);

export const AxiosContext = createContext(
    new Proxy(axios, {
        apply: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
        get: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
    }),
);

export const useAxios = () => {
    return useContext(AxiosContext);
}

const transformPagination = (pagination) => {
    if (!pagination) return;

    const current = pagination.current ? pagination.current : pagination.defaultCurrent;
    const pageSize = pagination.pageSize ? pagination.pageSize : pagination.defaultPageSize;

    let offset = 0;
    if (current && pageSize) {
        offset = (current - 1) * pageSize;
    }

    return {
        offset,
        limit: pageSize,
    }
}

const transformFilters = (filters) => {
    console.log(filters)
    if (!filters) return;
    let result = [];
    for (let key in filters) {
        if (!filters[key] || filters[key] === null) continue;
        result = [...result, [key + ':eq:' + filters[key]]]
    }
    return result;
}

const transformSorter = (sorter) => {
    if (!sorter) return;

    let result = '';
    if (sorter.field && sorter.order) {
        let order = 'desc';
        if (sorter.order === 'ascend') order = 'asc';
        result = sorter.field + ' ' + order;;
    }

    return result;
}

/* type listParams = {
    limit?: number;
    offset?: number;
    filter?: string[];
    order?: string;
} */
const useGetList = (key, url, pagination, filters, sorter) => {
    const axios = useAxios();

    const service = async () => {
        let params = {};

        params = { ...transformPagination(pagination) };
        params.filter = transformFilters(filters);
        params.order = transformSorter(sorter);

        const transformRequest = (data, headers) => {

        }
        console.log('params: ', params);
        const data = await axios.get(
            `${url}`, {
            params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            },
            transformRequest,
        });

        return data;

    }

    return useQuery(key, () => service());

}

const useGetOne = (key, url) => {
    const axios = useAxios();

    const service = async () => {
        const data = await axios.get(
            `${url}`
        );

        return data;

    }
    return useQuery(key, () => service(),{cacheTime:0});

}

const useCreate = (key,url) => {
    const axios = useAxios();
    const queryClient = useQueryClient()
    return useMutation(async (params) => {
        const data = await axios.post(
            `${url}`,
            params
        );
        return data;
    },{

        onSuccess: () => queryClient.invalidateQueries([key])

    });
}

const useUpdate = (key,url) => {
    const axios = useAxios();
    const queryClient = useQueryClient()
    return useMutation(async (item) => {
        const data = await axios.patch(
            `${url}`,
            item
        );
        return data;
    },
    
    {

        onSuccess: () => queryClient.invalidateQueries([key])

    });
}

const useDelete = (url) => {
    const axios = useAxios();
    const queryClient = useQueryClient()
    return useMutation(async (id) => {
        const data= await axios.delete(
            `${url}/${id}`,
        );
        return data;
    });
}

const useBatch = (url) => {
    const axios = useAxios();
    const queryClient = useQueryClient()
    return useMutation(async (ids) => {
        const data = await axios.post(
            `${url}`,
            { idList: ids },
        );
        return data;
    });
}

export {
    useGetOne,
    useGetList,
    useUpdate,
    useCreate,
    useDelete,
    useBatch,
};

export default axios;
