import React, { useState,useEffect } from 'react';

import {Button,Modal, Form,Col,message, Row,Input} from 'antd';


import { useAddCustomerGroup,useEditCustomerGroup } from '../../../api/api';


const { TextArea } = Input;


export default function  AddCustomer ({group}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)

    const { mutateAsync: addCustomerGroup } = useAddCustomerGroup();
    const { mutateAsync: editCustomerGroup } = useEditCustomerGroup(group?._id);


    



    async function onSubmit ( values )  {




        try {
            setLoading(true)

            if( group ) {
                await editCustomerGroup(values)
            }else{
                await addCustomerGroup(values)
            }


            setVisible(false)
            message.success('Enregistrement réussi!!')

            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }



return(

<>

{group ? 
    <div  onClick={()=>setVisible(true)}>

<span className="fs11 u-mar-left-xxs"> 
Modifier le groupe
</span>

</div>
:
<Button type="primary"   className="rad4" onClick={()=>setVisible(true)}>
<span className="fs13 fW500">  Ajouter un groupe de clients</span>
</Button>
}

<Modal

    width={500}
    okButtonProps={{form:'customer_form', key: 'submit', htmlType: 'submit', loading:loading}}
    onCancel={()=>setVisible(false)}
    open={visible}
    title= {<span className=" fs17"> {group? 'Modifier un groupe':'Ajouter un groupe de clients'} </span>}
    bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:'#F7F7F9' }}
    okText= {group? 'Modifier':'Ajouter un groupe'}
    cancelText="Annuler"
    
    destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s" style={{ overflow: "auto"}}>

                            



<Form layout="vertical" id="customer_form" initialValues={group? group : null} onFinish={onSubmit} className="h100 justbtw  flex column" >
  
<div>                
    <Row gutter={16}>
        <Col span={24}>
            <Form.Item
                name="name"
                label="Nom "
                rules={[{ required: true, message: 'Veuillez entre le nom' }]}
            >
                <Input  />
            </Form.Item>
        </Col>



       
    </Row>


    <Row gutter={16}>


    <Col span={24}>

<Form.Item
    name="description"
    label="Description du groupe"
    
>
  <TextArea 
      autoSize={{ minRows: 3, maxRows: 5 }}
  />

</Form.Item>


</Col>

       

    </Row>


    

</div>




<Row>


</Row>



</Form>







</div>







</Modal>

</>
) }