
import { useBatch, useCreate, useDelete, useGetList, useGetOne, useUpdate } from "./request";

// products
import {
    useRecoilValue,
  } from 'recoil';

  import { activeStartDate,activeEndDate,userInfos } from "../store";


export const useGetProducts = (pagination, filters) => {
    return useGetList(
        "Products",
        `/products?stock=${filters?.stock}`,
        pagination,
       
    );
}

export const useGetProduct= (id) => {
    return useGetOne(
        "MyProduct",
        `/products/${id}`

      );
}


export const useEditProduct = (id) => {
    return useUpdate("Products",`/products/${id}`);
}



export const useAddProduct = () => {
    return useCreate("Products",`/products`);
}

//puchases

export const useAddPurchase = () => {
    return useCreate("Purchases",`/purchases`);
}

export const useGetPurchases = (pagination, filters) => {
    return useGetList(
        "Purchases",
        `/purchases?status=${filters?.status}`,
        pagination,
       
    );
}

export const useEditPurchase = (id) => {
    return useUpdate("Purchases",`/purchases/${id}`);
}


// sales 


export const useGetSales = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/sales?start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 
        pagination,
        filters
    );
}

export const useGetSale= (id) => {
    return useGetOne(
        "Sale",
        `/sales/${id}`

      );
}


export const useDeleteSale= () => {
    return useDelete(`/sales`);
}



export const usePaySale = (id) => {
    return useUpdate("Sales",`/sales/pay/${id}`);
}




// Report 


export const useGetReportSalesProduct = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=products&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


export const useGetReportSalesCategory = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=categories&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


export const useGetReportSalesBrand = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=brands&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


export const useGetReportSalesEmployes = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=users&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


export const useGetReportSalesCustomers = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=customers&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


export const useGetReportSalesPayments = (pagination, filters) => {
    return useGetList(
        "Sales",
        `/reports/sales?sales_to_report=payments&start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}`, 

    );
}


// Orders


export const useGetOrders = (pagination, filters) => {
    return useGetList(
        "Orders",
        `/orders?status=${filters?.status}`, 
        pagination,
    );
}

export const useGetOrder= (id) => {
    return useGetOne(
        "Order",
        `/orders/${id}`

      );
}


export const useAddOrder = () => {
    return useCreate("Orders",`/orders`);
}

export const useEditStatusOrder = (id) => {
    return useUpdate("Orders",`orders/${id}/general`);
}

export const useEditStatusPayment = (id) => {
    return useUpdate("Orders",`orders/${id}/payment`);
}

// team

export const useGetTeam = (pagination, filters) => {
    return useGetList(
        "Team",
        "/employees",
        pagination,
        filters
    );
}



export const useGetUserInfos = () => {
    return useGetOne(
        "UserInfos",
        "/employees/details"

      );
}


export const useUpdateUser = () => {
    return useUpdate("/employees/changeprofile");
}


export const useUpdatePwd = () => {
    return useUpdate("/employees/change-password");
}

export const useGetEmployee= (id) => {
    return useGetOne(
        "EmployeeDetails",
        `/employees/${id}`

      );
}

// sales  history


export const useGetSalesHistory= () => {
    return useGetOne(
        "SalesHistory",
        `/sales/sales-history?start_date=${useRecoilValue(activeStartDate)}&end_date=${useRecoilValue(activeEndDate)}` 
      );
}

export const useGetParkSales= () => {
    return useGetList(
        "ParkSales",
        `/sales?user=${useRecoilValue(userInfos).id}&status=en cours`,
        {},
        {}
    );
}






// tenant-infos


export const useGetTenantInfos = () => {
    return useGetOne(
        "MyProduct",
        "/employees/tenant-infos"

      );
}


export const useUpdateTenant = () => {
    return useUpdate("tenants","/tenants");
}



//Supplier

export const useGetSupliers = (pagination, filters) => {
    return useGetList(
        "Supliers",
        "/vendors",
        pagination,
        filters
    );
}


export const useGetSuplier= (id) => {
    return useGetOne(
        "Suplier",
        `/vendors/${id}`

      );
}

export const useAddSuplier = () => {
    return useCreate("Supliers",`/vendors`);
}

export const useEditSuplier = (id) => {
    return useUpdate("Supliers",`/vendors/${id}`);
}



// clients 


export const useGetCustomers = (pagination, filters) => {
    return useGetList(
        "Customers",
        "/customers",
        pagination,
        filters
    );
}


export const useGetCustomersGroups = (pagination, filters) => {
    return useGetList(
        "Customersgroups",
        "/customersgroups",
        pagination,
        filters
    );
}


export const useGetCustomer= (id) => {
    return useGetOne(
        "Customer",
        `/customers/${id}`

      );
}

export const useAddCustomer = () => {
    return useCreate("Customers",`/customers`);
}

export const useAddCustomerGroup = () => {
    return useCreate("Customersgroups",`/customersgroups`);
}
export const useEditCustomerGroup = (id) => {
    return useUpdate("Customersgroups",`/customersgroups/${id}`);
}


export const useAddPaymentCustomer = (id) => {
    return useUpdate("Customers",`/customers/${id}/transactions`);
}


export const useEditCustomer = (id) => {
    return useUpdate("Customers",`/customers/${id}`);
}



// categories

export const useGetProducttypes = (pagination, filters) => {
    return useGetList(
        "Producttypes",
        "/producttypes",
        pagination,
        filters
    );
}


export const useGetCategoriesWithProds = (pagination, filters) => {
    return useGetList(
        "CategoriesWithProds",
        "/producttypes/withprods",
        pagination,
        filters
    );
}


export const useAddCategory = (pagination, filters) => {
    return useCreate(
        "Producttypes","/producttypes"
    );
}

export const useEditCategory = (id) => {
    return useUpdate("Producttypes",`/producttypes/${id}`);
}


// Supplier

export const useGetVendors = (pagination, filters) => {
    return useGetList(
        "Vendors",
        `/vendors`,
        pagination,
        filters
    );
}








// brands

export const useGetBrands = (pagination, filters) => {
    return useGetList(
        "Brands",
        "/brands",
        pagination,
        filters
    );
}




export const useAddBrand = (pagination, filters) => {
    return useCreate(
        "Brands","/brands"
    );
}

export const useEditBrand = (id) => {
    return useUpdate("Brands",`/brands/${id}`);
}




// branch

export const useGetBranch = (pagination, filters) => {
    return useGetList(
        "Branches",
        `/branches`,
        pagination,
        filters
    );
}

export const useGetUserBranch = (pagination, filters) => {
    return useGetList(
        "Userbranch",
        `/employees/${useRecoilValue(userInfos).id}/branches`,
        pagination,
        filters
    );
}
