import { NavLink } from "react-router-dom";

export  function SubNav({items}) {

  return (


    <div className="u-pad-top-m" style={{backgroundColor:'rgb(244 244 245)'}}>
    <div className="container h80 flex justbtw column ">
    <h2 className='fW600 fs22'> {items.heading} </h2>
    
    {items.nav?.length ? (
        <div className="flex">
          {items.nav?.map((item, index) => (
    
    <NavLink to={item.href} key={index}  activeClassName="selectedSub" exact className=" fs13 fW500 u-pad-bottom-xs u-mar-right-m"> {item.title} </NavLink>
    
    ))} 
    </div>
    ): null} 
    </div>
    </div>


  )
}
