
import React, { useState,useEffect,useRef } from 'react';
import {
    useRecoilValue,
  } from 'recoil';
import moment from 'moment';
import 'moment/locale/fr';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {Layout,Spin,Select, Table,Input,Typography} from 'antd';

import SelectDate from '../components/selectdate';
import LayoutPage from './layout';
import { activeStartDate,activeEndDate } from "../store";
import { useGetSalesHistory} from '../api/api';
import { useGetReportSalesProduct } from '../api/api';





moment.locale('fr')
const { Text } = Typography;





const {  Content } = Layout;
export default function  Home () {
    const Startdate = useRecoilValue(activeStartDate);
    const EndDate = useRecoilValue(activeEndDate);

    const [sales,setSales] = useState()
    const [salesR,setSalesR] = useState()
    const { data, error, isLoading,isFetching, refetch } = useGetSalesHistory();


const [chartOptions,setChartOptions] = useState(

     {
        chart: {
            type: 'areaspline',
                backgroundColor: 'transparent',

        },
     
        tooltip: {
            shared: true,
            valueSuffix: ' Fcfa'
        },
        credits: {
            enabled: false
        },

        title: {
            text: '',
                enabled:false,
        },

        legend:{
            enabled: false,
        },


        yAxis: {
            gridLineColor: 'rgba(0,0,0,0.05)',
                allowDecimals: false,
                title: {
                enabled:false,
            },
            labels: {
                style: {
                    color: 'rgba(0,0,0,0.8)'
                }
            }
        },


        xAxis: {
            categories: ['0', '0', '0','0','0','0','0'],
                labels: {
              
            }
        },
        series: [
            { data: [0,0,0,0,0,0,0],
            },
        ],


    }


)



const columns = [

     
    {
        title: 'Produit',
        dataIndex: 'product',
onFilter:(value,record)=>{
return String(record.product).toLowerCase().includes(value.toLowerCase())
}
    },
    {
        title: 'Qté',
        dataIndex: 'quantity',
        width:'60px'
    },
    {
        title: 'Montant',
        dataIndex: 'total',
        render: item =>
        <>
{  Intl.NumberFormat('fr-FR').format(item) }
Fcfa
        </>
    },
    {
        title: 'Benef',
        dataIndex: 'gross_profit',
        render: item =>
        <>
{  Intl.NumberFormat('fr-FR').format(item) }
Fcfa
        </>
    }



];



const { data:dataR, isFetching:isFetchingR, refetch:refetchR} = useGetReportSalesProduct({}, {});


useEffect(() => {

    setSalesR(dataR?.data.sort((a, b) => b.quantity - a.quantity));



}, [dataR,Startdate]);




useEffect(() => {
    setSales(data);



    setChartOptions(

        {
            ...chartOptions,
    
            xAxis: {
                categories: sales?.sales_history.label,
                    labels: {
                    style: {
                        color: 'rgba(0,0,0,0.8)'
                    }
                }
            },
            series: [
                { data: data?.sales_history.data,
                    fillColor: {
                        linearGradient: [0, 0, 0, 350],
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    lineWidth: 3
                },
            ],
    
    
        }
    
    )
    

  }, [data,Startdate]);

  useEffect(() => {
    refetch();
    refetchR()
  }, [Startdate]);







    return (





          
<LayoutPage>

<>
    <div className="container nopadres">
                                <div className="flex itemcenter  justbtw nopadres u-mar-top-l  flexendres  ">


                                    <div className="textcenterres noneres">
                                        <h2 className=" u-mar-right-s colhead av-roman u-mar-top-xxs fs20 fs18res u-mar-bottom-xxs nomarres"> Bienvenue, <span
                                            className="av-heavy">                                    { JSON.parse(localStorage.getItem('user')).infos.lastname}
                                            </span></h2>
                                        <span
                                            className="fs15 fs12res colhead"> {moment().format('dddd , Do MMMM  YYYY')} </span>
                                    </div>


                                    <div className="flex itemcenter " >
                                        <div className="separator nonedisplayres"></div>








                <SelectDate />




                                    </div>
                                </div>
                               




                                <div className="grille blokdash flex  itemcenter column ">




                                    <div className=" nopadres u-mar-top-m u-mar-bottom-s hauto rad16">

                                      
                                        <div className=" flex  order2  itemcenter justbtw w100">


<div className=" w100 simplegrid grap32" >





<div className="block  cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  flex justbtw u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >

<div>
    <h4 className="nomar colhead av-medium ">Chiffre d'affaire</h4>

{isFetching ?

<div className="placeholder u-mar-right-s u-mar-top-m" style={{height:'18px',width:'120px'}}>
<div className="animated-background"></div>
</div>

:

<h2 className=" u-mar-top-s u-mar-bottom-xs-res  fs20 fs15res fW500 colhead " >
       
{ sales ? Intl.NumberFormat('fr-FR').format(sales?.amount)+' Fcfa' : '--/--' }
</h2>


}
 

  


</div>


<div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(233, 251, 245)'}}>

    <svg class="sidebar-options-img vert-align-teb " width="22" height="26" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.48471 9.5C6.56812 9.5 6.62373 9.43523 6.62373 9.32273V8.90682C7.42447 8.84886 8 8.32727 8 7.46477C8 6.71818 7.60241 6.34318 6.84059 6.13182L6.62373 6.07386V4.87386C6.89064 4.91818 7.0658 5.08523 7.17424 5.36477C7.26321 5.5625 7.38276 5.66136 7.55514 5.66136C7.76645 5.66136 7.90547 5.51477 7.90547 5.28977C7.90547 5.21818 7.89435 5.15 7.87766 5.08182C7.74421 4.55682 7.28545 4.15795 6.62373 4.10341V3.67727C6.62373 3.56477 6.56812 3.5 6.48471 3.5C6.4013 3.5 6.34569 3.56477 6.34569 3.67727V4.10341C5.56997 4.15114 5.0139 4.68295 5.0139 5.50455C5.0139 6.24091 5.41149 6.63977 6.13994 6.84091L6.34569 6.89886V8.13977C5.99537 8.10227 5.8202 7.90795 5.73123 7.64205C5.65338 7.44432 5.53661 7.33523 5.36423 7.33523C5.13624 7.33523 5 7.50227 5 7.74773C5 7.8125 5.01112 7.88409 5.03336 7.9625C5.1696 8.46023 5.63114 8.86591 6.34569 8.90682V9.32273C6.34569 9.43523 6.4013 9.5 6.48471 9.5ZM5.7924 5.41591C5.7924 5.13977 5.98146 4.91136 6.34569 4.87045V5.99545C5.95644 5.87273 5.7924 5.69205 5.7924 5.41591ZM7.22428 7.57045C7.22428 7.88409 7.0241 8.10227 6.62373 8.13977V6.97727C7.06858 7.11364 7.22428 7.26705 7.22428 7.57045Z" fill="#26D49B"></path><circle cx="6.5" cy="6.5" r="5.5" stroke="#26D49B"></circle></svg>
</div>


</div>



    <div className="block  cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  flex justbtw u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >

        <div>
            <h4 className="nomar colhead av-medium ">Bénefice Brute </h4>

            {isFetching?

<div className="placeholder u-mar-right-s u-mar-top-m " style={{height:'18px',width:'120px'}}>
<div className="animated-background"></div>
</div>

:

            <h2 className=" u-mar-top-s u-mar-bottom-xs-res  fs20 fs15res fW500 colhead " >
                  

            { sales ? Intl.NumberFormat('fr-FR').format(sales?.gross_profit)+' Fcfa' :  <span className="placeholder"></span> }
                
                 </h2>

            }

        </div>


        <div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(243, 249, 255)'}}>

        <svg viewBox="0 0 20 20" stroke="#0075FF"><path d="M8.0833333.66666667c.3221661 0 .5833334.26116723.5833334.58333333v7c0 .3221661-.2611673.5833333-.5833334.5833333H1.08333333C.76116723 8.8333333.5 8.5721661.5 8.25.5 4.06150056 3.8948339.66666667 8.0833333.66666667zm-.5833333 7V1.85948424c-3.07860905.27735172-5.52983071 2.72857338-5.80718242 5.80718243H7.5zM2.86419079 11.7943817c-.02451132-.3212323.21602857-.6015129.53726088-.6260242.3212323-.0245114.60151289.2160285.6260242.5372608C4.30474827 15.3394031 7.33739318 18.1666667 11 18.1666667c3.8661672 0 7-3.1338328 7-7 0-3.66260685-2.8272636-6.69525176-6.4610483-6.97252416-.3212323-.02451132-.5617722-.3047919-.5372609-.62602421.0245113-.3212323.3047919-.56177219.6260242-.53726087 4.240381.32355813 7.5389517 3.86174734 7.5389517 8.13580924 0 4.5104994-3.6561673 8.1666666-8.1666667 8.1666666-4.27406187 0-7.81225107-3.2985706-8.13580921-7.5389516z"></path></svg>
        </div>


    </div>



    <div className="block  cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  flex justbtw u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >

<div>
    <h4 className="nomar colhead av-medium ">Marge </h4>

    {isFetching?

<div className="placeholder u-mar-right-s u-mar-top-m" style={{height:'18px',width:'50px'}}>
<div className="animated-background"></div>
</div>

:

    <h2 className=" u-mar-top-s u-mar-bottom-xs-res  fs20 fs15res fW500 colhead " >

    {sales != null && sales.profit_margin != null ? sales.profit_margin : '---'}  %

        
        </h2>
}

</div>


<div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgba(255, 87, 87, 0.1)'}}>

<svg data-v-03fb5bad="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-03fb5bad="" d="M17.5 21C16.5717 21 15.6815 20.6313 15.0251 19.9749C14.3687 19.3185 14 18.4283 14 17.5C14 16.5717 14.3687 15.6815 15.0251 15.0251C15.6815 14.3687 16.5717 14 17.5 14C18.4283 14 19.3185 14.3687 19.9749 15.0251C20.6313 15.6815 21 16.5717 21 17.5C21 18.4283 20.6313 19.3185 19.9749 19.9749C19.3185 20.6313 18.4283 21 17.5 21ZM17.5 19C17.8978 19 18.2794 18.842 18.5607 18.5607C18.842 18.2794 19 17.8978 19 17.5C19 17.1022 18.842 16.7206 18.5607 16.4393C18.2794 16.158 17.8978 16 17.5 16C17.1022 16 16.7206 16.158 16.4393 16.4393C16.158 16.7206 16 17.1022 16 17.5C16 17.8978 16.158 18.2794 16.4393 18.5607C16.7206 18.842 17.1022 19 17.5 19ZM6.5 10C6.04037 10 5.58525 9.90947 5.16061 9.73358C4.73597 9.55769 4.35013 9.29988 4.02513 8.97487C3.70012 8.64987 3.44231 8.26403 3.26642 7.83939C3.09053 7.41475 3 6.95963 3 6.5C3 6.04037 3.09053 5.58525 3.26642 5.16061C3.44231 4.73597 3.70012 4.35013 4.02513 4.02513C4.35013 3.70012 4.73597 3.44231 5.16061 3.26642C5.58525 3.09053 6.04037 3 6.5 3C7.42826 3 8.3185 3.36875 8.97487 4.02513C9.63125 4.6815 10 5.57174 10 6.5C10 7.42826 9.63125 8.3185 8.97487 8.97487C8.3185 9.63125 7.42826 10 6.5 10ZM6.5 8C6.89782 8 7.27936 7.84196 7.56066 7.56066C7.84196 7.27936 8 6.89782 8 6.5C8 6.10218 7.84196 5.72064 7.56066 5.43934C7.27936 5.15804 6.89782 5 6.5 5C6.10218 5 5.72064 5.15804 5.43934 5.43934C5.15804 5.72064 5 6.10218 5 6.5C5 6.89782 5.15804 7.27936 5.43934 7.56066C5.72064 7.84196 6.10218 8 6.5 8ZM19.071 3.515L20.485 4.929L4.93 20.485L3.516 19.071L19.07 3.515H19.071Z" fill="#FFAC2F"></path></svg>
</div>


</div>


    <div className="block  cardpour u-pad-horizontal-s u-pad-horizontal-xs-res  flex justbtw u-pad-vertical-s u-pad-vertical-xs-res rad12 rad8res"  >

        <div className="w100">
            <h4 className="nomar colhead av-medium">Commandes</h4>

            {isFetching?

<div className="placeholder u-mar-right-s u-mar-top-m" style={{height:'15px',width:'40px'}}>
<div className="animated-background"></div>
</div>

:

            <h2 className="u-mar-top-s u-mar-bottom-xs-res u-mar-bottom-xs  fs20 fs15res semiboldfibra colhead " style={{borderBottom:'1px solid #eff4fa'}}>
                {/*<NumberFormat value={this.state.weekdata.totalcmd}  displayType={'text'} thousandSeparator={true}  />*/}
              {sales?.number_of_orders}  
            </h2>

}

           
        </div>


        


        <div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(255, 247, 234)'}} >



            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path  d="M4 16V4H2V2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3V15H18.438L20.438 7H8V5H21.72C21.872 5 22.022 5.03466 22.1586 5.10134C22.2952 5.16801 22.4148 5.26495 22.5083 5.38479C22.6019 5.50462 22.6668 5.6442 22.6983 5.79291C22.7298 5.94162 22.7269 6.09555 22.69 6.243L20.19 16.243C20.1358 16.4592 20.011 16.6512 19.8352 16.7883C19.6595 16.9255 19.4429 17 19.22 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16ZM6 23C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21C4 20.4696 4.21071 19.9609 4.58579 19.5858C4.96086 19.2107 5.46957 19 6 19C6.53043 19 7.03914 19.2107 7.41421 19.5858C7.78929 19.9609 8 20.4696 8 21C8 21.5304 7.78929 22.0391 7.41421 22.4142C7.03914 22.7893 6.53043 23 6 23ZM18 23C17.4696 23 16.9609 22.7893 16.5858 22.4142C16.2107 22.0391 16 21.5304 16 21C16 20.4696 16.2107 19.9609 16.5858 19.5858C16.9609 19.2107 17.4696 19 18 19C18.5304 19 19.0391 19.2107 19.4142 19.5858C19.7893 19.9609 20 20.4696 20 21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23Z" fill="#FFAC2F"></path></svg>

        </div>


    </div>










</div>


</div>




                                        <div className="block  cardvente u-pad-horizontal-s  u-mar-top-l  u-pad-top-s rad12 rad8res noneres" >
                                           
                                           <h2 className="fs20 av-medium colhead"> Graphique de vente </h2>
                                           
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions}
                                            />
                                        </div>




<div className="flex rescolumn">



<div className="block grille bgwhite cardvente u-pad-horizontal-s  u-mar-top-l  u-pad-top-s rad12 rad8res u-mar-right-s nomarright" >
                                       
                                       <div className="flex justbtw u-mar-bottom-l itemcenter">
                                        <h2 className="fs18 fW500 colhead nomar"> Rapport de ventes </h2>


                                        <div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(233, 251, 245)'}}>

                                        <svg data-v-1dac8743="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-1dac8743="" d="M14.515 5.00002L17.121 2.39302C17.2139 2.30004 17.3241 2.22628 17.4455 2.17596C17.5669 2.12563 17.6971 2.09973 17.8285 2.09973C17.9599 2.09973 18.09 2.12563 18.2114 2.17596C18.3328 2.22628 18.4431 2.30004 18.536 2.39302L22.778 6.63602C22.9655 6.82355 23.0708 7.07785 23.0708 7.34302C23.0708 7.60818 22.9655 7.86249 22.778 8.05002L19 11.828V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8947 18.2652 22 18 22H5.99999C5.73477 22 5.48042 21.8947 5.29288 21.7071C5.10534 21.5196 4.99999 21.2652 4.99999 21V11.828L1.22199 8.05002C1.03451 7.86249 0.929199 7.60818 0.929199 7.34302C0.929199 7.07785 1.03451 6.82355 1.22199 6.63602L5.46399 2.39302C5.55686 2.30004 5.66715 2.22628 5.78855 2.17596C5.90994 2.12563 6.04007 2.09973 6.17149 2.09973C6.3029 2.09973 6.43303 2.12563 6.55443 2.17596C6.67583 2.22628 6.78611 2.30004 6.87899 2.39302L9.48499 5.00002H14.515ZM15.343 7.00002H8.65699L6.17199 4.51502L3.34299 7.34302L6.99999 11V20H17V11L20.657 7.34302L17.828 4.51502L15.343 7.00002Z" fill="#26D49B"></path></svg>
</div>

                                        </div>


<Table locale={{emptyText:<></>}} loading={{indicator:<Spin/>,spinning:isFetchingR}} columns={columns} dataSource={salesR} scroll={{y: 500}} pagination={false}

summary={(pageData) => {
   
    return (
      
      <Table.Summary fixed>
     
        <Table.Summary.Row >
          <Table.Summary.Cell index={0} colSpan={2} className='fW600'>{salesR && 'Total'}</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Text className='fW600' >{ salesR && Intl.NumberFormat('fr-FR').format(salesR?.reduce((n, {total}) => n + total, 0))} {salesR && 'Fcfa'}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Text className='fW600'>{salesR && Intl.NumberFormat('fr-FR').format(salesR?.reduce((n, {gross_profit}) => n + gross_profit, 0))} {salesR && 'Fcfa'}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>

      </Table.Summary>
    );
  }}
 size="default"    />

</div>


                                {/*         <div className="block grille bgwhite cardvente u-pad-horizontal-s  u-mar-top-l  u-pad-top-s rad12 rad8res u-mar-right-s nomarright" >
                                       
                                       <div className="flex justbtw itemcenter">
                                        <h2 className="fs18 fW500 colhead nomar"> Les plus vendus </h2>


                                        <div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(233, 251, 245)'}}>

                                        <svg data-v-1dac8743="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-1dac8743="" d="M14.515 5.00002L17.121 2.39302C17.2139 2.30004 17.3241 2.22628 17.4455 2.17596C17.5669 2.12563 17.6971 2.09973 17.8285 2.09973C17.9599 2.09973 18.09 2.12563 18.2114 2.17596C18.3328 2.22628 18.4431 2.30004 18.536 2.39302L22.778 6.63602C22.9655 6.82355 23.0708 7.07785 23.0708 7.34302C23.0708 7.60818 22.9655 7.86249 22.778 8.05002L19 11.828V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8947 18.2652 22 18 22H5.99999C5.73477 22 5.48042 21.8947 5.29288 21.7071C5.10534 21.5196 4.99999 21.2652 4.99999 21V11.828L1.22199 8.05002C1.03451 7.86249 0.929199 7.60818 0.929199 7.34302C0.929199 7.07785 1.03451 6.82355 1.22199 6.63602L5.46399 2.39302C5.55686 2.30004 5.66715 2.22628 5.78855 2.17596C5.90994 2.12563 6.04007 2.09973 6.17149 2.09973C6.3029 2.09973 6.43303 2.12563 6.55443 2.17596C6.67583 2.22628 6.78611 2.30004 6.87899 2.39302L9.48499 5.00002H14.515ZM15.343 7.00002H8.65699L6.17199 4.51502L3.34299 7.34302L6.99999 11V20H17V11L20.657 7.34302L17.828 4.51502L15.343 7.00002Z" fill="#26D49B"></path></svg>
</div>

                                        </div>


                                        {isFetching?

<div className="placeholder u-mar-right-s u-mar-top-m" style={{height:'15px',width:'240px'}}>
<div className="animated-background"></div>
</div>

:




                                        <div className="u-mar-top-xs">

{


sales != null && sales.top_5_products.map((prod) => 

<div className="flex u-pad-top-s itemcenter u-pad-vertical-xs-res" style={{borderTop: '1px solid rgb(239, 244, 250)'}}>
    <div className="grille2 colhead fs15"> {prod.product.name}</div>  <div className="grille fs15 colblue"> {prod.quantity}  </div>   <div class="separator nonedisplayres"></div>  <div className="grille fs15"> {prod.total} <span className="collab fs12"> Fcfa</span>  </div>  </div>



)




}


                                            </div>

}
                                        </div>
 */}


                                        <div className="block  grille cardvente u-pad-horizontal-s  u-mar-top-l  u-pad-top-s rad12 rad8res u-mar-right-s nomarright" >
                                       
                                        <div className="flex justbtw itemcenter">
                                       
                                        <h2 className="fs18 fW500 colhead nomar"> Top catégorie </h2>

                                        <div  className="cardicon flex itemcenter rad8 justcenter" style={{backgroundColor: 'rgb(243, 249, 255)'}}>

                                        <svg data-v-1dac8743="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-1dac8743="" d="M10.9 2.09998L20.799 3.51498L22.213 13.415L13.021 22.607C12.8335 22.7944 12.5792 22.8998 12.314 22.8998C12.0488 22.8998 11.7945 22.7944 11.607 22.607L1.707 12.707C1.51953 12.5194 1.41422 12.2651 1.41422 12C1.41422 11.7348 1.51953 11.4805 1.707 11.293L10.9 2.09998ZM11.607 4.22198L3.828 12L12.314 20.485L20.092 12.707L19.032 5.28198L11.607 4.22198ZM13.727 10.586C13.3519 10.2107 13.1411 9.70176 13.1412 9.17112C13.1413 8.90838 13.1931 8.64822 13.2937 8.40549C13.3943 8.16277 13.5417 7.94223 13.7275 7.75648C13.9133 7.57072 14.1339 7.42338 14.3767 7.32288C14.6194 7.22238 14.8796 7.17067 15.1424 7.17072C15.673 7.17081 16.1819 7.38169 16.557 7.75698C16.9321 8.13226 17.1429 8.6412 17.1428 9.17183C17.1427 9.70246 16.9318 10.2113 16.5565 10.5865C16.1812 10.9616 15.6723 11.1723 15.1416 11.1722C14.611 11.1721 14.1022 10.9613 13.727 10.586Z" fill="#0075FF"></path></svg>

</div>
</div>



{isFetching?

<div className="placeholder u-mar-right-s u-mar-top-m" style={{height:'15px',width:'240px'}}>
<div className="animated-background"></div>
</div>

:




<div className="u-mar-top-xs">


{


sales != null && sales.top_5_categories.map((prod) => 

<div className="flex u-pad-top-s itemcenter u-pad-vertical-xs-res" style={{borderTop: '1px solid rgb(239, 244, 250)'}}>
    <div className="grille2 colhead fs15"> {prod.category}</div><div className="grille fs15"> {prod.total} <span className="collab fs12"> Fcfa</span>  </div> 
    </div>



)




}


                                            </div>

}
                                           </div>

</div>





















                                </div>



                                </div>
                     
</div>
</>         
                                </LayoutPage>

            );


}
