








import React, {Fragment, useEffect, useState,useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import DetailsForm from './form/detailsform';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import { useEditPurchase } from '../../api/api';

import {useHistory} from "react-router";
import LayoutPage from '../layout';



export default function  DetailsPurchase ({close,purchase}) {
    const history = useHistory()
    const [loading,setLoading] = useState(false)
    const [loadingPar,setLoadingPar] = useState(false)
    const [loadingDel,setLoadingDel] = useState(false)
    const { mutateAsync: editPurchase } = useEditPurchase(purchase?._id);



 


      const  handleSubmit =  useCallback(async (data) => {
        console.log("Data", data);

  let val = {...purchase}

  val.status = data





        try {
           
            if(data == "Livrée"){
                setLoading(true)
                await editPurchase(val)
            }else if (data == "Livrée partiellement") {
                setLoadingPar(true)
                await editPurchase(val)
            }else{
                setLoadingDel(true)
                await editPurchase(val)
            }
            
        

            message.success('Enregistrement réussi!!')
            close()
            setLoading(false)
            setLoadingDel(false)
            setLoadingPar(false)

        } catch(e) {
            setLoading(false)
            setLoadingDel(false)
            setLoadingPar(false)
            message.warning('Erreur enregistrement!')

        }





      }, []);
    





    return (






                            <div className="flex  column rel h100">

                          
                          
<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-s" style={{top:0,borderBottom:'1px solid #DDD'}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:34}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600"> Details de l'approvisionnement </h2>








<div className="flex itemcenter abs" style={{right:34}}>


<div className="flex itemcenter">
                 







{ purchase.status == 'Attente livraison' &&  

<div className="flex itemcenter">

<Button type="primary" loading={loading} disabled={loadingDel || loadingPar }  className="  u-pad-horizontal-xs u-mar-left-s fW500 rad4 fs13 flex justcenter" onClick={()=>handleSubmit("Livrée")}>
         Marquer comme livrée
        </Button>

<Button danger loading={loadingDel} disabled={loadingPar || loading }  className="  u-pad-horizontal-xs rad4 flex fW500 u-mar-left-s justcenter fs13 " onClick={()=>handleSubmit("Annulée")}>
          Annuler 
        </Button>

{/*         <Button  loading={loadingPar} disabled={loadingDel || loading } size="medium" className="  u-pad-horizontal-xs u-mar-left-s fs15  rad4 flex justcenter" onClick={()=>onSuccess("Livrée partiellement")}>
Livrée partiellement
        </Button> */}



      
</div>
       

}



      



</div>

</div>









</div>

    
                       

                            <Spin tip="Chargment..." size="large" spinning={false}>



                                <Row gutter={32}  className=" w100   u-pad-horizontal-s   h100  u-mar-top-xxl u-mar-top-xl-res nopadres noradres ">

                                <Col span={14} offset = {5} className=" w100  u-pad-top-s u-pad-horizontal-s   h100 blok rad16 u-mar-top-xl-res nopadres noradres ">


                                    <div className="flex u-pad-s  column rad8 grille25 bgwhite  nomarres u-pad-s-res" style={{height:'max-content'}}>



                                   
  
                                           
                                           
                                           
                                       
                                         <DetailsForm   purchase={purchase}   />

                                      
                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

                              
                                </Row>

                            </Spin>




                  

</div>
             
                            

    );


}
