
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined,HomeOutlined, NotificationOutlined } from '@ant-design/icons';
import Formrole from './formrole';
import axios from "axios";
import {useHistory} from "react-router";
import { SubNav } from '../../components/SubNav';
import { settingNav } from '../../config/navItems';
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import LayoutPage from '../layout';


export default function  Roles () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const history = useHistory()

    const [loadingrole,setLoadingrole] = useState(true)
    const [loadingbranches,setLoadingbranches] = useState(true)
    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const [Roles,setRoles] = useState(null)
  

 

    useEffect(() => {


        const getRoles = async () => {


            const result = await axios.get(URL+'/roles', {
                headers: {
                    'tenant_user_api_key': token             
                }
            })

            console.log(result)
            setRoles(result.data.data);
            setLoading(false)
        };




        getRoles();


    }, [reload]);

    const change  =  (r)  => {

        history.push('/rh/team/'+r.id)


    }

    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }

    const columns = [

        

        {
            title: 'Nom',
            dataIndex: 'name',
            width: 350,

    
        },
        

        {
            title: 'Description',
            dataIndex: 'description',
        }
    ];







    return (





      <LayoutPage>
       
       <SubNav items={settingNav}  /> 

<div className="container">


                            <div className="grille w100  u-mar-top-l hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                    <Input placeholder="Rechercher dans la table" style={{minWidth:'250px',height:'35px!important'}} />

                                </div>

                             {/*        <Button type="primary" size="large"  className="rad8"  onClick={()=>setVisible(true)}  >
                                    <span className="fs16 av-heavy">  Ajouter un rôle </span>
                                </Button> */}


                                    <Drawer

                                        width={900}
                                        onClose={()=>setVisible(false)}
                                        visible={visible}
                                        bodyStyle={{ paddingBottom: 0,paddingTop:12 }}
                                        destroyOnClose={true}

                                    >

                                      <Formrole  close={close}/>

                                    </Drawer>



                                </div>

                                <Table columns={columns}    onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })} dataSource={Roles} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>
</div>
                            



                            </LayoutPage>

    );


}
