import React from 'react';
import {Row,Tooltip,Input,Menu,Space,Dropdown} from 'antd';


export default function Chatmessage ({back,option,menu,}){
 
    const men = (
        <Menu style={{width:'200px', height:'200px', padding:'10px'}}>
            <Menu.Item key="0" className="">
                <div className="">
                <span className="av-heavy fs17"> 
                View Profil
                </span>
                </div>
        </Menu.Item>
          <Menu.Divider />
            <Menu.Item key="1" className="">
                  <div className="">
                   <span className="av-heavy  fs17"> 
                    Unloc
                   </span>
                   </div>
            </Menu.Item>
          <Menu.Divider />
        </Menu>    
          
          );

    return (
<div className='conten'>
    <div className='head'>
       <Row justify="space-between v-space">
          <div  className='deco justcenter itemcenter flex' onClick={back}>
          
          <svg data-testid="close-icon" width="24" height="24" viewBox="0 0 24 24" role="img"  title="Fermer"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M5.75 5.75L18.25 18.25M18.25 5.75L5.75 18.25"></path></svg>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16" slot="icon"><title>Back</title><path fill-rule="evenodd" clip-rule="evenodd" d="M.293 7.293a1 1 0 0 0 0 1.414l7 7 1.414-1.414L3.414 9H15V7H3.414l5.293-5.293L7.293.293l-7 7Z"></path></svg>
           */}
          </div>
           <div  className='align' >
                <h2 className='title'>ivan</h2>
            </div>
            <Dropdown overlay={men} trigger={['click']}> 
              <div  className='deco justcenter itemcenter flex'>
              <svg width="20" height="20" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" class=""><title>More</title><path d="M4 2C4 3.10455 3.10461 4 2 4C0.895386 4 0 3.10455 0 2C0 0.895447 0.895386 0 2 0C3.10461 0 4 0.895447 4 2Z" fill="#343B42"></path><path d="M12 2C12 3.10455 11.1046 4 10 4C8.89539 4 8 3.10455 8 2C8 0.895447 8.89539 0 10 0C11.1046 0 12 0.895447 12 2Z" fill="#343B42"></path><path d="M18 4C19.1046 4 20 3.10455 20 2C20 0.895447 19.1046 0 18 0C16.8954 0 16 0.895447 16 2C16 3.10455 16.8954 4 18 4Z" fill="#343B42"></path></svg>
              </div> 
           </Dropdown> 
        </Row>
     </div>

     <div className='content-mess'> 
        <Row className='space-align-block'>
            <Space align="end">
              <div className='deco3 justcenter itemcenter flex'>
              <span className='center'>S</span>
              </div>  
              <div className='p-mess s-ro mock-block '>
                  <div className='center'>
                    <span className='center'>24 juil. via Email | lefax</span>
                  </div>
                  <Tooltip placement="topLeft" title="13:24 on 24 juil">
                      <div className='block '>
                        <Row className='form-m'>
                            <div className='box justcenter itemcenter flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class=""><title>Positive</title><path fill="#000" fill-opacity=".9" fill-rule="evenodd" d="M3 12a9 9 0 1 1 18.001.001A9 9 0 0 1 3 12Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm4.24 1.11A2.98 2.98 0 0 0 12 14.99c1.21 0 2.3-.73 2.76-1.88l1.86.76A4.987 4.987 0 0 1 12 16.99c-2.03 0-3.84-1.22-4.62-3.12l1.86-.76ZM14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" clip-rule="evenodd"></path></svg>
                            </div>
                            <div className='h-space'>
                            <div className=''>
                              <span className='title'>Positive feedback</span>
                            </div>
                            <div className=''>
                              <span className='desc'>$5.00 sale on 24 juil</span>
                            </div>
                            </div>
                        </Row>
                        <div className='center'>
                          <p className='decs-mes'>Environment, Other, Quality, Wait time, Customer service cool</p>
                        </div>
                        <div className='btn-re-mes center'>
                            <span className='btn-text'>View transaction</span>
                        </div>
                    </div>
                  </Tooltip>      
              </div>
            </Space>
        </Row>
        <Row className='space-align-block'>
            <Space align="end">
              <div className='deco3 justcenter itemcenter flex'>
              <span className='center'>S</span>
              </div>  
              <div className='p-mess s-ro mock-block '>
                  <div className='center'>
                    <span className='center'>24 juil. via Email | lefax</span>
                  </div>
                  <Tooltip placement="topLeft" title="13:24 on 24 juil">
                      <div className='block '>
                        <Row className='form-m'>
                            <div className='box justcenter itemcenter flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><title>Appointment</title><path fill="#000" fill-opacity=".9" fill-rule="evenodd" d="M17 3h-2v2H9V3H7v2C5.34 5 4 6.34 4 8v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3V3Zm1 5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v1h12V8ZM6 11v7c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-7H6Z" clip-rule="evenodd"></path></svg>
                            </div>
                            <div className='h-space'>
                            <div className=''>
                              <span className='title'>Positive feedback</span>
                            </div>
                            <div className=''>
                              <span className='desc'>$5.00 sale on 24 juil</span>
                            </div>
                            </div>
                        </Row>
                        <div className='center'>
                          <p className='decs-mes'>Environment, Other, Quality, Wait time, Customer service cool</p>
                        </div>
                        <div className='btn-re-mes center'>
                            <span className='btn-text'>View transaction</span>
                        </div>
                    </div>
                  </Tooltip>      
              </div>
            </Space>
        </Row>
        <Row className='space-align-block'>
            <Space align="end">
              <div className='deco3'>
              </div>  
              <div className='p-mess s-ro mock-block '>
                  <div className='center'>
                    <span className='center'>24 juil. via Email | lefax</span>
                  </div>
                  <Tooltip placement="topLeft" title="13:24 on 24 juil">
                      <div className='block '>
                        <Row className='form-m'>
                            <div className='box'>
                            </div>
                            <div className='h-space'>
                            <div className=''>
                              <span className='title'>Positive feedback</span>
                            </div>
                            <div className=''>
                              <span className='desc'>$5.00 sale on 24 juil</span>
                            </div>
                            </div>
                        </Row>
                        <div className='center'>
                          <p className='decs-mes'>Environment, Other, Quality, Wait time, Customer service cool</p>
                        </div>
                        <div className='btn-re-mes center'>
                            <span className='btn-text'>View transaction</span>
                        </div>
                    </div>
                  </Tooltip>      
              </div>
            </Space>
        </Row>
        <Row className='space-align-block'>
            <Space align="end">
              <div className='deco3 justcenter itemcenter flex'>
              </div>  
              <div className='p-mess s-ro mock-block '>
                  <div className='center'>
                    <span className='center'>24 juil. via Email | lefax</span>
                  </div>
                  <Tooltip placement="topLeft" title="13:24 on 24 juil">
                      <div className='block '>
                        <Row className='form-m'>
                            <div className='box'>
                            </div>
                            <div className='h-space'>
                            <div className=''>
                              <span className='title'>Positive feedback</span>
                            </div>
                            <div className=''>
                              <span className='desc'>$5.00 sale on 24 juil</span>
                            </div>
                            </div>
                        </Row>
                        <div className='center'>
                          <p className='decs-mes'>Environment, Other, Quality, Wait time, Customer service cool</p>
                        </div>
                        <div className='btn-re-mes center'>
                            <span className='btn-text'>View transaction</span>
                        </div>
                    </div>
                  </Tooltip>      
              </div>
            </Space>
        </Row>
     </div>

    <div className='position'>
      <Row justify="space-between">
          <div  className='deco3 justcenter itemcenter flex'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><title>Create Menu</title><path d="M8 14V8H14V6H8V0H6V6H0V8H6V14H8Z" fill="black" fill-opacity="0.9"></path></svg>
    
          </div>
          <div  className='deco3 justcenter itemcenter flex'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class=""><title>Payment link</title><path fill="#000" fill-opacity=".9" d="M10.49 8.83c0-1.04 1.1-1.47 2.09-1.47.89 0 1.8.39 2.34.89l1.36-1.94c-1.01-.74-2.01-1.1-3.06-1.22V3H10.9v2.23c-1.67.46-3.02 1.74-3.02 3.61 0 1.53.85 2.46 1.92 3.08 1.82 1.06 4.34 1.28 4.34 3.02 0 .99-.81 1.7-1.97 1.7-1.24 0-2.42-.64-3.19-1.49l-1.7 1.78c1.06.99 2.3 1.64 3.64 1.9V21h2.32v-2.15c1.95-.39 3.52-1.9 3.52-3.95-.02-4.56-6.27-3.89-6.27-6.07Z"></path></svg>
    
          </div>
          <div  className=''>
          <Input placeholder="send via email" className='ip-mess' />
          </div>
        
          <div  className='deco3 justcenter itemcenter flex'>
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><title>Send</title><path d="M20 8L0 0L2 8L0 16L20 8ZM3.81 9H12.11L2.88 12.69L3.81 9ZM12.11 7H3.81L2.89 3.31L12.11 7Z" fill="currentColor" fill-opacity="0.9"></path></svg>
    
          </div>
      </Row>
    </div>
</div>
    );
}