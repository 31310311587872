
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import {Button, Layout,Dropdown,Drawer,Modal,message,Spin, Menu,Tag, Table,Input} from 'antd';
import { SubNav } from '../../components/SubNav';
import { productNav } from '../../config/navItems';
import {EllipsisOutlined,MinusCircleOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';
import TabCategoryPlaceholder from './components/tabcategoryplaceholder'; 
import AddBrand from './components/AddBrand';
import LayoutPage from '../layout';
import { useGetBrands,useEditBrand} from '../../api/api';

const {  Content } = Layout;
const {confirm} = Modal
export default function  Brands () {


    const [visible,setVisible] = useState(false)
    const [brands,setBrands] = useState(null)
    const [brand,setBrand] = useState(null)



    const [filters, setFilters] = useState();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
      });

    const { data, error, isFetching, refetch } = useGetBrands(pagination, filters);
    const { mutateAsync: editBrand} = useEditBrand(brand?._id);


    useEffect(() => {
        setBrands(data?.data);
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });
      }, [data]);
    
      useEffect(() => {
        refetch();
      }, [pagination.current, pagination.pageSize, filters]);






    const close = () => {

        setVisible(false)

    }


    const showDeleteConfirm =  () => {
        confirm({
          title: "Supression de la marque "+brand.name,
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer la supression de cette marque ?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
            await editBrand({status:"delete"})
            message.error ('Suppression réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };   
      
      

      const showDisableConfirm =  () => {
        confirm({
          title: brand.status == 'active' ? "Desactiver la marque -"+brand.name : "Activer la marque -"+brand.name,
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer vous cette action ?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
            if(brand.status == 'active') {
                await editBrand({status:"disable"})
            }else{
                await editBrand({status:"active"})
            }
            
            message.success ('Action réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }; 
  

      const items = [

        
      
          {
            key: '2',
            label: (
              <span className="fs11 flex itemcenter w100">
            <EditOutlined />  <AddBrand brand={brand} /> 
              </span>
            ),
          },
      
          {
            key: '4',
            label: (
              <span className=" fs11 w100" onClick={showDisableConfirm}>
                <MinusCircleOutlined /> Activer/Désactiver 
              </span>
            ),
          },
      
          {
            key: '3',
            label: (
              <span className="nega fs11 w100" onClick={showDeleteConfirm}>
                <DeleteOutlined /> Supprimer 
              </span>
            ),
          }
        ];
  


    const columns = [


        {
            title: 'Nom',
            dataIndex: 'name',
            width: 360,
        },

      
        {
            title: 'Description',
            dataIndex: 'description',
            width: 360,
        },
        {
            title: 'En vente',
            dataIndex: '',
            width: 120,
            render: p => (
                <>
{ p.for_sale ?  'Oui' : 'Non'}

                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: '',
            width: 190,
            render: p => (
                <>
{ p.status == 'active' ?   

<div class="flex itemcenter"><div className=" fBQcfS"></div><span>Actif</span></div>

: <div class="flex itemcenter"><div className=" fBQcfS bgray"></div><span>Désactivé</span></div> }

                </>
            ),
        },
        {
            title: '',
            key: 'action',
            width: 50,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
    setBrand(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },


    ];







    return (





        <LayoutPage>

<SubNav items={productNav}  /> 

<div className="container">
                            <div className="grille w100   u-mar-top-l  hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw itemcenter u-mar-bottom-s">

                                <div>
                                    <Input placeholder="Rechercher dans la table" style={{minWidth:'250px',height:'32px!important'}} />
                                </div>


                                    <AddBrand/>

                                 

                                   



                                </div>

                                <Table columns={columns} locale={{emptyText:<></>}}  loading={{indicator:<TabCategoryPlaceholder/>,spinning:isFetching}} dataSource={brands} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>

                  </div>         


             
</LayoutPage>


    );


}
