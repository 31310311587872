import React, { useState,Suspense, useMemo, useEffect } from 'react';
import {
    Route,
    Switch,
    Redirect,BrowserRouter as Router,
} from 'react-router-dom';
//import socketIOClient from "socket.io-client";
import { useClearCache } from 'react-clear-cache';

//import { AppContext } from "./libs/contextLib";
import axios, { AxiosContext } from "./api/request";
import {
  RecoilRoot,
} from 'recoil';

import Login from "./features/sign/Login"
import Onboarding from "./features/sign/onboarding"
import Register from "./features/sign/Signup"
import Reset from "./features/sign/reset"
import Home from "./features/home"


import Preferences from "./features/parametres/preferences"
import Compte from "./features/parametres/compte"
import Licences from "./features/parametres/licences"
import Notifications from "./features/parametres/notifications"
import Delivery from "./features/parametres/delivery"

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import Rh from "./features/rh/team"
import Roles from "./features/rh/roles"
import Planning from "./features/rh/planning"
import NotifRh from "./features/rh/notifications"

import RhMember from "./features/rh/teamdetails"

import Treasury from "./features/treasury/treasury"
import Marketing from "./features/marketing/marketing"

import Reports from "./features/reports/salesreports"


import Branches from "./features/parametres/branches"
import Pos from "./features/parametres/pos"
import Profile from "./features/profile/profile"


import Customers from "./features/clients/customer"
import CustomersDetails from "./features/clients/customerdetails"
import CustomersGroups from "./features/clients/customergroups"



import Products from "./features/product/product";
import Category from "./features/product/category";
import Supliers from "./features/product/suplier";
import Brands from "./features/product/brand";
import OrdersProd from "./features/product/purchase";
import DestockProd from "./features/product/destock";
import Transfers from "./features/product/transfer";


import VendorsDetails from "./features/product/vendordetails"


import AddBasic from "./features/product/addbasicproduct";


import Sales from "./features/sales/sale";
import Goals from "./features/sales/goals";
import Orders from "./features/orders/order";
import OrderInfo from "./features/orders/orderInfo";


import TerminalPos from "./features/pos/pos";
import PosSale from "./features/pos/sale1";
import PosOrders from "./features/pos/orders";
import Transaction from "./features/pos/transaction";
import Tickets from "./features/pos/ticketsession";
import Rapport from "./features/pos/rapportvente";



import SalesReport from "./features/reports/salesreports";
import SalesReportCat from "./features/reports/salesreportscat";
import SalesReportBrands from "./features/reports/salesreportsbrand";
import SalesReportEmployes from "./features/reports/salesreportsemployes";
import SalesReportPayments from "./features/reports/salesreportspayments";
import SalesReportCustomers from "./features/reports/salesreportscustomers";

import SalesReportTeam from "./features/reports/salesreportsemployes";


import StockReport from "./features/reports/stockreports";
import OrdersReport from "./features/reports/ordersreports";
import DiscountsReport from "./features/reports/discountsreports";
import TerminalReport from "./features/reports/terminalsreports";

import SuspendFallbackLoading from './components/fallback';

import 'antd/dist/antd.css';
import './App.css';











const AxiosProvider = ({ children }) => {
    const axiosValue = useMemo(() => {
      return axios;
    }, []);
  
    return (
      <AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>
    );
  };
  


const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        suspense: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: false,
      },
    },
  });
  



      function Base  () {

         


          const isAuth = () => localStorage.getItem("token") != null


          const PrivateRoute = ({ component: Component, ...rest }) => (
              <Route {...rest} render={props => {
                  return isAuth()
                      ? <Component {...props}/>
                      : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                      }}/>
              }}/>
          )


          
          const isAdmin =  () => {

             let roles =  JSON.parse(localStorage.getItem('user'))?.infos.roles 
             console.log(roles)

         if (roles?.includes('Manager') || roles?.includes('Comptable') || roles?.includes('Gestionnaire de stock') ) 
         {
          return true
         }else {
          return false
         }

          }


          
         

const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        return isAdmin() ? <Component {...props}/>
          :
          
          <Redirect to={{
              pathname: '/pos',
              state: { from: props.location }
          }}/>
    }}/>
)



const { isLatestVersion, emptyCacheStorage } = useClearCache();



          return(

              <div className="hv100 bgapp" style={{overflowY:'auto'}}>

<div>
      {!isLatestVersion && (
        <p className='center w100 u-pad-top-s fW600 fs15 green fixed' style={{zIndex:9999}} >
        <a
            href="#"
            onClick={e => {
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
          L'application a connu des evolutions :  Mettre à jour  !
          </a>
        </p>
      )}
    </div>


<AxiosProvider>

<QueryClientProvider client={queryClient}>
<Suspense fallback={<SuspendFallbackLoading />}>
<RecoilRoot>
                  <Router>

                      <Switch>

                          <PrivateRouteAdmin exact path="/" component={Home}/>
                          <PrivateRouteAdmin exact path="/settings/preferences" component={Preferences}/>
                          <PrivateRouteAdmin exact path="/settings/compte" component={Compte}/>
                          <PrivateRouteAdmin exact path="/settings/branches" component={Branches}/>
                          <PrivateRouteAdmin exact path="/settings/pos" component={Pos}/>
                          <PrivateRouteAdmin exact path="/settings/licences" component={Licences}/>
                          <PrivateRouteAdmin exact path="/settings/notifications" component={Notifications}/>
                          <PrivateRouteAdmin exact path="/settings/delivery" component={Delivery}/>



                          <PrivateRouteAdmin exact path="/settings/team" component={Rh}/>
                          <PrivateRouteAdmin exact path="/rh/team/:id" component={RhMember}/>
                          <PrivateRouteAdmin exact path="/settings/roles" component={Roles}/>

                          <PrivateRouteAdmin exact path="/rh/planning" component={Planning}/>
                          <PrivateRouteAdmin exact path="/rh/notifications" component={NotifRh}/>




                          <PrivateRouteAdmin exact path="/customers" component={Customers}/>
                          <PrivateRouteAdmin exact path="/customers/groups" component={CustomersGroups}/>

                          <PrivateRouteAdmin exact path="/customers/:id" component={CustomersDetails}/>



                          <PrivateRouteAdmin exact path="/treasury" component={Treasury}/>
                          <PrivateRouteAdmin exact path="/Marketing" component={Marketing}/>


                          <PrivateRouteAdmin exact path="/Reports" component={SalesReport}/>
                          <PrivateRouteAdmin exact path="/Reports/appro" component={SalesReportBrands}/>
                          <PrivateRouteAdmin exact path="/Reports/payment" component={SalesReportEmployes}/> 
                          <PrivateRouteAdmin exact path="/Reports/stock" component={StockReport}/>
                          <PrivateRouteAdmin exact path="/Reports/orders" component={OrdersReport}/>
                          <PrivateRouteAdmin exact path="/Reports/discounts" component={DiscountsReport}/>










                          <PrivateRoute exact path="/pos" component={TerminalPos}/>
                          <PrivateRoute exact path="/pos/transaction" component={Transaction}/> 
                          <PrivateRoute exact path="/pos/rapport" component={Rapport}/> 
                          <PrivateRoute exact path="/pos/orders" component={PosOrders}/> 
                          <PrivateRoute exact path="/pos/tickets" component={Tickets}/> 
                          <PrivateRoute exact path="/pos/sale/new/:id" component={PosSale}/>  


                          <PrivateRouteAdmin exact path="/sales" component={Sales}/> 
                          <PrivateRouteAdmin exact path="/sales/goals" component={Goals}/> 
                          <PrivateRouteAdmin exact path="/orders" component={Orders}/> 

                          <PrivateRouteAdmin exact path="/profile" component={Profile}/>

        
                          <PrivateRouteAdmin exact path="/products" component={Products}/>
                          <PrivateRouteAdmin exact path="/products/categories" component={Category}/>
                          <PrivateRouteAdmin exact path="/products/supliers" component={Supliers}/>
                          <PrivateRouteAdmin exact path="/products/brands" component={Brands}/>
                          <PrivateRouteAdmin exact path="/products/orders" component={OrdersProd}/>
                          <PrivateRouteAdmin exact path="/products/destock" component={DestockProd}/>
                          <PrivateRouteAdmin exact path="/products/transfers" component={Transfers}/>


                          <PrivateRouteAdmin exact path="/products/supliers/:id" component={VendorsDetails}/>



                          <PrivateRouteAdmin exact path="/produts/new/basic" component={AddBasic}/>

                          <Route exact path="/order/:id" component={OrderInfo}/>  

                          <Route exact path="/login" component={Login}/>
                          <Route exact path="/onboarding/:token" component={Onboarding}/>
                          <Route exact path="/signup" component={Register}/>
                          <Route exact path="/reset" component={Reset}/>


                      </Switch>
  
                  </Router>
    </RecoilRoot>
    
    </Suspense>
</QueryClientProvider>
</AxiosProvider>

              </div>

          )
      }

    export default Base;


