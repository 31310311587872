
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Drawer,Form,Col,Row,Select,Spin, Menu,Image,Tag,Divider,message, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import Formemploye from './form';
import axios from "axios";
import LayoutPage from '../layout';

import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";




const { SubMenu } = Menu;
const { Content } = Layout;
const { Option  } = Select;
export default function  Preferences () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)

    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));

    const [core,setCore] = useState(null)
  

 

    useEffect(() => {


        const getCore= async () => {


            const result = await axios.get(URL+'/employees/tenant-infos', {
                headers: {
                    'tenant_user_api_key': token 
                }
            })

            setCore(result.data);
            setLoading(false)
        };




        getCore();


    }, [reload]);


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }


    async function onSubmit ( values )  {


   

        console.log(values)



        try {
            setLoading(true)
            const token = localStorage.getItem('user') &&  JSON.parse(localStorage.getItem('user')).token;

            const result = await axios.post(URL + '/api/user/createstaff',
                values
            )

            close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }






    return (





        <LayoutPage>

                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                        

                            <div className="grille w100  u-pad-vertical-s noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                            <div className="flex column itemcenter u-mar-top-s column rel h100">




                            <svg xmlns="http://www.w3.org/2000/svg" width="226" height="226" viewBox="0 0 226 226" fill="none"><path d="M187.43 113c0 40.9-33.1 74-74 74s-74-33.1-74-74 33.1-74 74-74 74 33.1 74 74z" fill="#F0F1F2"/><path d="M31.452 53h164.096c4.112 0 7.452 3.36 7.452 7.494v105.012c0 4.135-3.34 7.494-7.452 7.494H31.452c-4.112.017-7.452-3.342-7.452-7.494V60.494C24 56.36 27.34 53 31.452 53z" fill="#E9AF58"/><path d="M203 96H24v19h179V96z" fill="#CD5747"/><path d="M122.22 95h-17.65C82.19 102.62 66 124.29 66 149.78v8.84h.29l9.27-7.57 8.88 7.26v-8.53c0-18.26 12.36-33.62 28.96-37.71 16.6 4.09 28.96 19.45 28.96 37.71v8.53l8.88-7.26 9.27 7.57h.29v-8.84c-.02-25.49-16.21-47.16-38.58-54.78z" fill="#EC856F"/><path d="M124 53h-21v120h21V53z" fill="#CD5747"/><path d="M155.522 73.372 121 96l38-1.246v-19.53c0-1.77-1.988-2.828-3.478-1.852zm-84.952 0L106 96l-39-1.246v-19.53c0-1.77 2.04-2.828 3.57-1.852z" fill="#CD5747"/><path d="M167.2 77.26 123 95.225V115h49V80.535c0-2.5-2.503-4.21-4.8-3.276zm-108.303 0L104 95.225V115H54V80.535c0-2.5 2.554-4.21 4.897-3.276z" fill="#EC856F"/><path d="M167.667 126.888 123 115h49v8.234c0 2.445-2.13 4.24-4.333 3.654zm-109.245 0L104 115H54v8.234c0 2.445 2.173 4.24 4.422 3.654z" fill="#CD5747"/><path d="M122.475 117h-17.95c-1.39 0-2.525-1.115-2.525-2.484V96.484c0-1.37 1.134-2.484 2.525-2.484h17.95c1.39 0 2.525 1.115 2.525 2.484v18.05c0 1.368-1.116 2.466-2.525 2.466z" fill="#DE6A5A"/></svg>                
                      <h1 className="av-heavy fs18 center"> Payez par Mobile money , carte bancaire ou Paypal votre abonnements sur Onebaz </h1>
                      <h2 className="av-light fs14"> Bientôt disponible </h2>






</div>

                            </div>

                           


              </LayoutPage>



    );


}
