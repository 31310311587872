import  React , {Fragment, useEffect, useState} from "react";
import {Upload, Layout, DatePicker,Form, Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import ImgCrop from 'antd-img-crop';
import { TwitterPicker } from 'react-color';


const {Option} = Select;
const { TextArea } = Input;
export default function Step1Form({onSuccess,vendors}) {

  

  return (
  
    


    <Fragment>
                                            <Row gutter={16}>
                                                
                                            <Col span={24}>
        <Form.Item
            name="vendor"
            label="Fournisseurs"
        >
            <Select  allowClear  >



                {  vendors != null &&

vendors.map(vendor=>(


<Option  value={vendor.id} > {vendor.name} </Option>


))   }

            </Select>
        </Form.Item>
    </Col>


                  




 

                                     

                                            </Row>

                          
                                               <Row gutter={16} className="u-mar-bottom-l">

                                               <Col span={12}>
                                            <Form.Item
                                                        name="expected_arrival_date"
                                                        label="Date de livraison"
                                                        className="w100 u-pad-left-xxs"
                                                    >

                                            <DatePicker  size={"large"} className="w100" />

</Form.Item>
</Col>

                                               {/* <Col span={12}>
                                                    <Form.Item
                                                        name="invoice_number"
                                                        label="Numéro de la facture"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col> */}

                                                   <Col span={12}>
                                                   <Form.Item
                                                        name="billing_date"
                                                        label="Date de paiement"
                                                        className="w100 u-pad-left-xxs"
                                                    >

                                            <DatePicker  size={"large"} className="w100" />

</Form.Item>
                                                   </Col>

                                             


                                                   <Col span={24}>

<Form.Item
    name="description"
    label="Note"
    
>
  <TextArea style={{marginTop:4}}
      autoSize={{ minRows: 5, maxRows: 8 }}
  />

</Form.Item>


</Col>







                                               </Row>



                                         


                                           </Fragment>
                                           







    
   
  );
}
