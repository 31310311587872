







import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { SubNav } from '../../components/SubNav';
import { productNav } from '../../config/navItems';
import {Drawer,Modal,message,Dropdown,Table,Input} from 'antd';
import DetailsSuplier from './vendordetails'
import LayoutPage from '../layout';
import { useGetSupliers,useEditSuplier } from '../../api/api';

import {PlusCircleOutlined,MinusCircleOutlined,EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';

import AddSuplier from './components/AddSuplier';
import TabSuplierPlaceholder from './components/tabsuplierplaceholder'
export default function  Suplier () {

    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [supliers,setSupliers] = useState(null)
    const [searchText, setSearchText] = useState("");
    const [suplier,setSuplier] = useState(null)
    const [visibleD,setVisibleD] = useState(false)
  
const {confirm} = Modal
    const { data, error, isLoading, refetch } = useGetSupliers({}, {});
    const { mutateAsync: editSuplier } = useEditSuplier(suplier?.id);

    const showDeleteConfirm =  () => {
      confirm({
        title: "Supression de le fournisseur "+suplier.name,
        icon: <ExclamationCircleFilled />,
        content: 'Confirmer la supression de ce fournisseur ?',
        okText: 'Oui',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: async () => { 
          await editSuplier({status:"delete"})
          message.error ('Suppression réussie!!')
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };        


    const showDisableConfirm =  () => {
        confirm({
          title: suplier.status == 'active' ? "Desactiver le forunisseur -"+suplier.name : "Activer le forunisseur -"+suplier.name,
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer vous cette action ?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
            if(suplier.status == 'active') {
                await editSuplier({status:"disable"})
            }else{
                await editSuplier({status:"active"})
            }
            
            message.success ('Action réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };


    const items = [

      {
        key: '1',
        label: (
          <span className="fs11 flex itemcenter" onClick={()=>change(suplier)}>
        <EyeOutlined className="u-mar-right-xxs" />  Plus d'infos
          </span>
        ),
      },
      {
        key: '4',
        label: (
          <span className=" fs11" onClick={showDisableConfirm}>
            <MinusCircleOutlined /> Activer/Désactiver 
          </span>
        ),
      },
        {
          key: '2',
          label: (
            <span className="fs11 flex itemcenter">
          <EditOutlined />  <AddSuplier user={suplier}/>
            </span>
          ),
        },
    
        {
          key: '3',
          label: (
            <span className="nega fs11" onClick={showDeleteConfirm}>
              <DeleteOutlined /> Supprimer 
            </span>
          ),
        }
      ];

    useEffect(() => {

        setSupliers(data?.data);


    }, [data]);


     useEffect(() => {
        refetch();
      }, [reload]); 


    const change  =  (r)  => {
       
        setVisibleD(true)
        setSuplier(r)
        
            }


 
            

    const close = () => {

        setVisibleD(false)
        refetch()


    }

    const columns = [

        {
            title: 'Nom ',
            dataIndex: '',
            width: 250,
            render: item =>
            <>

            {
            
            <div className="flex itemcenter">
            
            
            
            
            <div class="flex">
              
                
                    <span > {item.name}</span>
             
            
            </div>
            
            </div>
            
            }
            
                        </>,

           filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.name).toLowerCase().includes(value.toLowerCase())  || String(record.street).toLowerCase().includes(value.toLowerCase())
}            

        },
        

        {
            title: 'Telephone',
            dataIndex: 'phone',
            width: 205,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 205,
        },
        
        {
            title: 'Adresse',
            dataIndex: 'address',
            width: 255,
        },
        {
            title: 'Status',
            dataIndex: '',
            width: 150,
            render: p => (
                <>
{ p.status == 'active' ?   

<div class="flex itemcenter"><div className=" fBQcfS"></div><span>Actif</span></div>

: <div class="flex itemcenter"><div className=" fBQcfS bgray"></div><span>Désactivé</span></div> }

                </>
            ),
        },
        {
            title: '',
            key: 'action',
            width: 55,
            render: (_, record) => (
                
<div className="abs opt flex itemcenter justcenter t0 l0 h100 w100" onClick={(e) => { 
    setSuplier(record)
           e.stopPropagation();}}>
                <Dropdown menu={{ items }} className='w100 h100 flex itemcenter justcenter' trigger={['click']} placement="bottomRight">
                <EllipsisOutlined />
               
              </Dropdown>
              </div>

                
            ),
          },
    ];







    return (





        <LayoutPage>


                              

                            




                   <SubNav items={productNav}  />         

       


                            <div className="container">

                            <div className="grille w100  u-mar-top-l    hauto  u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div className='u-mar-bottom-xs w30'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par nom ou adresse" style={{idth:'250px',height:'32px!important'}} />

</div>

                               <AddSuplier />



                                </div>

                            <Table columns={columns}  locale={{emptyText:<></>}}  loading={{indicator:<TabSuplierPlaceholder/>,spinning:isLoading}} dataSource={supliers}

                                       onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />

                               

                            </div>

</div>



                      




        
                           


                                    <Drawer
                                    


                                    height={"100%"}
                                    placement={"bottom"}
                                    onClose={()=>setVisibleD(false)}
                                    
                                    visible={visibleD}
                                    closable={false}
                                    className={"bgapp"}
                                    
                                    bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0,backgroundColor:"#eff4fa" }}
                                    destroyOnClose={true}

                                    >

                                      <DetailsSuplier close={close} suplierData={suplier}/>

                                    </Drawer>










        </LayoutPage>



    );


}
