
import React, { useState,useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import axios from "axios";

import {Button, Layout,Select,Form,Upload, Col,message, Row,Divider,Input} from 'antd';
import {URL} from "../../urlapi";
import { UploadOutlined } from '@ant-design/icons';



const {  Content } = Layout;
const {Option} = Select;
export default function  Formpos ({close}) {


    const [loading,setLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [branches,setBranches] = useState(false)

    const [fileList,setFileList] = useState([])
    const token = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).accessToken;



    useEffect(() => {

        const getBranches = async () => {

            const result = await axios.get(URL+'/api/branch', {
                headers: {
                    'tenant_user_api_key':  token
                }
            })

            setBranches(result.data);
            setLoading(false)
        };




        getBranches();


    }, []);


    const  getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
    
    

   const handlePreview = async file => {
       
          file.preview = await getBase64(file.originFileObj);
    
      };
    const dum = ({ file, onSuccess }) =>{
        onSuccess("ok");
    }


    const handleChange = ({ fileList }) => setFileList( fileList );


    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };



    async function onSubmit ( values )  {


   

        console.log(values)


        const data = new FormData()
        values.businessName != undefined && data.append("businessName",values.businessName)
        values.businessType != undefined && data.append("businessType",values.businessType)
        values.town != undefined && data.append("town",values.town)
        values.branch != undefined && data.append("branch",values.branch)
        values.NIU != undefined && data.append("NIU",values.NIU)
        values.RCCM != undefined && data.append("RCCM",values.RCCM)
        values.adress != undefined && data.append("adress",values.adress)
        values.phone != undefined && data.append("phone",values.phone)
        values.email != undefined && data.append("email",values.email)
        if(values.upload){
        data.append("image",values.upload.file.originFileObj)
        }



        try {
            setLoading(true)

            const result = await axios.post(URL + '/api/pos',
                data,{
                    headers: {
                        'Content-Type':'multipart/form-data',
                        'tenant_user_api_key':  token

                    }
                }
            )

            

            close()
            message.success('Enregistrement réussi!!')
            setLoading(false)

        } catch(e) {
            setLoading(false)
            message.warning('Erreur enregistrement!')

        }

    }








    return (

                                <div className="flex  column rel h100">

                                    <div
                                        className="justcenter itemcenter flex column  w100 abs  w100" style={{top:0}}

                                    >

                                        <span className="av-heavy fs22"> Ajouter un point de vente  </span>

                                        <Divider/>

                                    </div>



                                        <Form layout="vertical" style={{marginTop:80}} onFinish={onSubmit} >
                                            
                                            <Row>

                                            <Col span={12}>
                                                    <Form.Item
                                                        name="branch"
                                                        label="choisir la branche"
                                                    >
                                                  <Select placeholder="Choisir la branche" >

{branches  && 

branches.map(branch => 

    <Option value={branch._id}> {branch.businessName} </Option>


)


}
</Select>
                                                    </Form.Item>
                                                </Col>

                                                </Row>
                                            
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="businessName"
                                                        label="Nom"
                                                        rules={[{ required: true, message: 'Veuillez entre le nom' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="town"
                                                        label="Ville"
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[{ type:'email',message: 'Veuillez entre l email' }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>



                                                <Col span={12}>
                                                    <Form.Item
                                                        name="phone"
                                                        label="Téléphone"
                                                        rules={[{ message: 'entrer un numéro' }]}
                                                    >
                                                        <Input placeholder="téléphone " />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                          
                                            <Row gutter={16}>


                                                <Col span={12}>
                                                    <Form.Item
                                                        name="adress"
                                                        label="adresse"
                                                    >
                                                        <Input type={'text'} />
                                                    </Form.Item>
                                                </Col>


                                                <Col span={12}>
                                                    <Form.Item
                                                        name="role"
                                                        label="Type de business"
                                                    >
                                                  <Select placeholder="Type de business" >

<Option value="Boutique généraliste"> Boutique généraliste</Option>
<Option value="Boutique ecommerce"> Boutique ecommerce</Option>
<Option value={"Institut de beauté"}>Institut de beauté  </Option>
<Option value={"Pressing"}> Pressing </Option>
<Option value={"Restaurant/Cafe"}> Restaurant/Cafe </Option>
<Option value={"Pizerria"}> Pizerria </Option>
<Option value={"Prêt à porter"} > Prêt à porter </Option>
<Option value="Autres">Autres </Option>

</Select>
                                                    </Form.Item>
                                                </Col>

                                            </Row>


                                            <Row>
                                            <Col span={12}>
                                            <Form.Item
        name="upload">  

            <Upload customRequest={dum}  onChange={handleChange} listType="picture"
          onPreview={handlePreview}>
              {fileList.length == 0 && <Button icon={<UploadOutlined />}>Ajouter le logo de la branche</Button> }
    


        </Upload>

        </Form.Item>
</Col>
                                                </Row>



                                           




                                            <Form.Item className="w100 abs " style={{bottom:0,right:0}}>




                                                    <Divider/>
                                                    <div style={{textAlign:'right'}}>
                                                        <Button onClick={()=>setVisible(false)} size={'large'} className="rad8 u-pad-horizontal-l" style={{ marginRight: 12 }}>
                                                            Annuler
                                                        </Button>
                                                        <Button  size={'large'} loading={loading} type="primary" htmlType="submit" className="rad8 u-pad-horizontal-l" type="primary">
                                                            Enregistrer
                                                        </Button>
                                                    </div>



                                            </Form.Item>




                                        </Form>







                                </div>




    );


}
