








import React, {Fragment, useEffect, useState,useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import {Upload, Layout,Steps, Form, Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import ImgCrop from 'antd-img-crop';
import Step1Form from './form/step1form';
import { useRecoilValue,useRecoilState } from 'recoil';
import { useGetProducttypes,useGetBranch, useGetVendors,useAddProduct } from '../../api/api';
import axios from "axios";
import {URL} from "../../urlapi";
import {useHistory} from "react-router";
import {CloseOutlined} from '@ant-design/icons';
import { userInfos } from '../../store';


import LayoutPage from '../layout';


const {Option} = Select;
const { TabPane } = Tabs;
export default function  AddBasicProduct ({close}) {
    const history = useHistory()
    const user = useRecoilValue(userInfos)

    const [loading,setLoading] = useState(false)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [onStock,setOnStock] = useState(false)
    const [key,setKey] = useState('1')
    const [current,setCurrent]= useState(0)
    const [background,setBackground] = useState('#ABB8C3')
    const [step1val,setStep1val] = useState()
  
    const [images,setImages] = useState([])


    const [form] = Form.useForm();
    const [data, setData] = useState({});
    const [data1, setData1] = useState({});

    const [categories,setCategories] = useState(null)
    const [branches,setBranches] = useState(null)
    const [vendors,setVendors] = useState(null)
    const [filters, setFilters] = useState();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });

    const { data:cat, error:errorCat, isLoading:isloadingCat, refetch:refetchCat } = useGetProducttypes(pagination, filters);
    const { data:branch, error:errorBranch, isLoading:isloadingBranch } = useGetBranch(pagination, filters);
    const { data:vendor, error:errorVendor, isLoading:isloadingVendor } = useGetVendors(pagination, filters);

    const { mutateAsync: addProduct } = useAddProduct();


    const addImage = (image) => {
        setImages(current => [...current,image])
    }

    useEffect(() => {
       
        setCategories(cat?.data)
        setBranches(branch?.data)
        setVendors(vendor?.data)
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });

      }, [cat,branch,vendor]);

  

       const  handleSubmit  =  async () => {
     

      



        form.submit()
        
        form.validateFields()
        .then(async function (values) {
          values.branches = [];
          user.infos.branches.map((b)=> {
              values.branches.push({
                  branch:b,
                  sell_price:values.retail_price,
                  stock_count: values.inStock,
                  alarm_count:values.stockAlert
              })
          })
          
          
          values.suppliers_informations = [];
          values.images = images;
          
          values.vendors?.map((v)=> {
          
              values.suppliers_informations.push({
          
          supplier : v,
          supplier_code: '',
          supplier_price: 0,
          
              })
          
          })
                    
                  
          
        values.isInventorytracked = onStock;
          
          
          
          
          
                  try {
                      console.log(values)
                      setLoading(true)
                       await addProduct(values)
          
                      message.success('Enregistrement réussi!!')
                      close()
                      setLoading(false)
          
                  } catch(e) {
                      console.log(e)
                      setLoading(false)
                      message.warning('Erreur enregistrement!')
          
                  }








        })
        .catch((errorInfo) => {
          console.log( errorInfo );
        });




    }




















    return (




        <div className="flex  column rel h100">
                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                            <div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s" style={{top:0,borderBottom:'1px solid #DDD',paddingBottom:10}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:34}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600"> Nouveau produit  </h2>








<div className="flex itemcenter abs" style={{right:34}}>





<Button type="primary" size="large" loading={loading} className="rad8" onClick={()=>handleSubmit()} >
    <span className="fs16 av-heavy">  Valider </span>
</Button>

</div>









</div>

                       

                            <Spin tip="Chargment..." size="large" spinning={isloadingCat && isloadingVendor && isloadingBranch }>



                                <Row gutter={32}  className=" w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m  h100  u-mar-top-xl-res nopadres noradres ">

                                <Col span={16} offset = {4} className=" w100  u-pad-top-s u-pad-horizontal-s   h100 blok rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex mauto w40 column u-mar-top-xl  bgwhite  nomarres u-pad-s-res" style={{height:'max-content'}}>



                                   

                                           
                                           
                                           
                                       
                                           <Step1Form  form={form} addImage = {addImage} images={images} categories={categories} vendors={vendors}   />
               {/*                             {current == 1 && <Step2Form data={data1}  vendors={vendors} onSuccess={handleNextStep} onBack={handlePrevStep} />}
                                           {current == 2 && <Step3Form data={data1} branches={branches} loading={loading} onSuccess={handleSubmit} onBack={handlePrevStep} />}
 */}
                                      
                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

                              
                                </Row>

                            </Spin>

                            </div>
             
                           

    );


}
