
import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { SubNav } from '../../components/SubNav';
import { reportNav } from '../../config/navItems';
import {Button,Select, Layout,Dropdown,message, Drawer,Radio,Checkbox,Modal,Spin, Menu,Tag, Table,Input, Divider} from 'antd';
import { useMediaQuery } from 'react-responsive'


import ProductExport from './productexport';


import { Link } from "react-router-dom";
import LayoutPage from '../layout';
import Details from './details';
import { useGetProducts,useGetProducttypes } from '../../api/api';
import {CaretDownOutlined,PlusCircleOutlined, EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,ExclamationCircleFilled } from '@ant-design/icons';

import iconexport from '../../images/icon-export.png'
import iconimport from '../../images/icon-import.png'
import iconbarcode from '../../images/icon-barcode.png'

const {  Content } = Layout;
const {confirm} = Modal
export default function  Products () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
const isDesktop = useMediaQuery({ query: '(min-width: 993px)' })


    const [visibleModal,setVisibleModal] = useState(false)
    const [visibleExport,setVisibleExport] = useState(false)

    const [visibleEdit,setVisibleEdit] = useState(false)
    const [products,setProducts] = useState()
    const [categories,setCategories] = useState(null)

    const [productId,setProductId] = useState(null)
    const [filters, setFilters] = useState();
    const [searchText, setSearchText] = useState("");

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });

    const { data, error, isLoading, refetch } = useGetProducts({}, {});
    const { data:cat, error:errorCat, isLoading:isloadingCat, refetch:refetchCat } = useGetProducttypes(pagination, filters);


    useEffect(() => {
        setProducts(data?.data);
        setCategories(cat?.data)
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });

      }, [data,cat]);
    
       useEffect(() => {
        refetch();
      }, []); 


      const showDeleteConfirm =  () => {
        confirm({
          title: "Supression de l'utilisateur ",
          icon: <ExclamationCircleFilled />,
          content: 'Confirmer la supression de ce produit ?',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Annuler',
          onOk: async () => { 
           // await editCustomer({status:"delete"})
            message.error ('Suppression réussie!!')
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };        
  

      const items = [

        {
          key: '1',
          label: (
            <span className="fs11 flex itemcenter"  >
          <EyeOutlined className="u-mar-right-xxs" />  Plus de détails
            </span>
          ),
        },
        {
          key: '4',
          label: (
            <span className="fs11 flex itemcenter" >
          <PlusCircleOutlined className="u-mar-right-xxs" />  Historique de stock
            </span>
          ),
        },
          {
            key: '2',
            label: (
              <span className="fs11 flex itemcenter">
            <EditOutlined />  Modifier le produit
              </span>
            ),
          },
      
          {
            key: '3',
            label: (
              <span className="nega fs11" onClick={showDeleteConfirm}>
                <DeleteOutlined /> Supprimer le produit
              </span>
            ),
          }
        ];
  






      const menu = (
        <Menu style={{width:'230px'}} >
        

            <Menu.Item key="1" className="flex itemcenter rad8 u-pad-vertical-xs" onClick={()=>setVisibleExport(true)}>

                <div className="  itemcenter justbtw flex w100">

             
                <span className="fs12">Exporter le catalogue</span>

                <img src={iconexport} height={16} width={16}  />


                </div>




            </Menu.Item>



         {/*    <Menu.Item key="2" className="flex itemcenter rad8 u-pad-vertical-s" >

            <Link to={"/settings/compte"}  className="  itemcenter justbtw flex">

             
<span className="av-heavy fs14">importer le catalogue</span>

<img src={iconimport} height={16} width={16}  />


</Link>




            </Menu.Item> */}




       {/*      <Menu.Item key="1" class="flex itemcenter rad8 u-pad-vertical-s" >

            <Link to={"/settings/compte"}  className="  itemcenter justbtw flex">

             
<span className="av-heavy fs14">imprimer les étiquettes</span>

<img src={iconbarcode} height={16} width={16}  />


</Link>




            </Menu.Item> */}

        </Menu>
    );




   


    




    const change  =  (r)  => {
       
        setVisibleEdit(true)
        setProductId(r._id)
        
            }

            const close = () => {

                setVisibleEdit(false)
                setVisibleModal(false)
                refetch()
        
            }

            const closeExport = () => {

                setVisibleExport(false)
        
            }


    const columns = [

        {
            title: 'Designation',
            dataIndex: 'name',
            width: 220,
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.product_type.name).toLowerCase().includes(value.toLowerCase())
            }
        
        },
        {
            title: 'Prix vente',
            dataIndex: 'retail_price',
            width: 140,
        },
        {
            title: "Prix d'achat",
            dataIndex: 'supply_price',
            width: 140,
        },
      
        {
            title: 'Stock',
            dataIndex: '',
            width: 100,
            render: p => (
                <>
{ p.isInventorytracked ?   p.branches.reduce((n, {stock_count}) => n + stock_count, 0) : '--' }

                </>
            ),
        },
         {
            title: 'Stock critique',
            dataIndex: '',
            width: 150,
            render: p => (
                <>

{ p.isInventorytracked ?   p.branches.reduce((n, {alarm_count}) => n + alarm_count, 0) : '--' }

                </>
            ),
        },
        {
            title: 'Moy ventes/mois',
            dataIndex: '',
            width: 170,
            render: p => (
                <>
{  '--' }
                </>
            ),
        },
        
        {
            title: 'Valeur stock',
            dataIndex: '',
            width: 170,
            render: p => (
                <>
{  '--' }
                </>
            ),
        }, 
     
    ];





    const columnsRes = [

    
        {
            title: 'Designation',
            dataIndex: 'name',
            filteredValue:[searchText],
            onFilter:(value,record)=>{
                return String(record.name).toLowerCase().includes(value.toLowerCase()) || String(record.product_type.name).toLowerCase().includes(value.toLowerCase())
            }
        
        },
        {
            title: 'Prix vente',
            dataIndex: 'retail_price',
        },
      
        {
            title: 'Stock',
            dataIndex: '',
            render: p => (
                <>
{ p.isInventorytracked ?   p.branches.reduce((n, {stock_count}) => n + stock_count, 0) : '--' }

                </>
            ),
        },
    
     
    ];







    return (




<LayoutPage>

<SubNav items={reportNav}  /> 

<div className="container">
                            <div className="grille w100  u-mar-top-l  hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

         <div className='flex grille itemcenter noneres'>
       
       


         <div className='w30 u-mar-right-s'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher dans les produits" style={{minWidth:'210px',height:'32px!important'}} />

</div>



       
       
         



<Select className='w20 u-mar-right-s'  defaultValue="all">
<option value={"all"}>Toutes les catégories</option>
                                            {  categories != null &&

                                                categories.map(categorie=>(


                                                <option  value={categorie._id} > {categorie.name} </option>


                                                ))   }


                                                </Select>




                                                <Select className='w15 u-mar-right-s'  defaultValue="all">
                                                <option value={"all"}>Tous les stocks</option>
                                          


                                                <option  value={"valide"} > Stock valide   </option>
                                                <option  value={"critique"} > Stock critique   </option>
                                                <option  value={"epuisé"} > Stock epuisé   </option>
                                               


                                                </Select>



         </div>



<div className="flex itemcenter">
                                <Dropdown overlay={menu} trigger={['click']}>


<div className="flex itemcenter cursor rad4 btnfilter ">

    <span className="fs13  u-mar-right-xs" style={{color:'#1a1a1a'}}> 
    
    Actions 
     </span>

    <span >

<CaretDownOutlined />

</span>


</div>


</Dropdown>


                        

                                </div>
                           



                                   






 <Drawer

width={510}
onClose={()=>setVisibleEdit(false)}
visible={visibleEdit}
closable={false}
bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
destroyOnClose={true}

>
{ <Details id={productId} onClose={close} />
}
</Drawer>



<Drawer

height={"100%"}
placement={"bottom"}
onClose={()=>setVisibleExport(false)}
visible={visibleExport}
closable={false}
bodyStyle={{ paddingBottom: 5, paddingLeft:0,paddingRight:0 }}
destroyOnClose={true}

>
{ <ProductExport  onClose={closeExport} />
}
</Drawer>



                                </div>





                                <Table columns={ isTabletOrMobile ? columnsRes : columns} dataSource={products} onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}  
                                       pagination={{
                                        pageSize: 10,
                                      } }
                                      
                                      scroll={{
                                        y: 650,
                                      }}
                                      />,


                            </div>
</div>
                           

                            </LayoutPage>



    );


}
