
import React, {useEffect,Fragment, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import { Spin,  Menu, Tabs, Row, Col, Table} from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import Formcustomer from './form';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import  {Logout} from "../../helpers/logout";
import {useHistory,useParams} from "react-router";
import { FileDoneOutlined, HeartOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";

const { TabPane } = Tabs;
const { SubMenu } = Menu;
const antIcon = <LoadingOutlined style={{ fontSize: 49 }} spin />;

export default function  DetailsFacture ({id,onClose}) {
    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const token = localStorage.getItem('token') &&  JSON.parse(localStorage.getItem('token'));
    const history = useHistory()
    const [facture,setFacture] = useState(null)


    useEffect(() => {


        const getFacture = async () => {

try {
            const result = await axios.get(URL+'/sales/'+id, {
                headers: {
                  'tenant_user_api_key': token             
                }
            })
    setLoading(false)

    setFacture(result.data);

} catch (e) {
   if(e.response && e.response.status == 401){
       Logout()
   }
}



        };




        getFacture();


    }, [id]);










    return (




        <div className="flex hv100 bgpos column"  >

        {facture != null  ?
        <Fragment>
          <div style={{height:'65px',borderBottom:'1px solid #dee3e7'}} className="flex bgwhite itemcenter justbtw u-pad-vertical-m u-pad-horizontal-s rel">
        

<span onClick={()=>onClose()} className="lh0 cursor" >
          <svg data-v-93bf3e8c="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><line data-v-93bf3e8c="" x1="18" y1="6" x2="6" y2="18"></line><line data-v-93bf3e8c="" x1="6" y1="6" x2="18" y2="18"></line></svg>
</span>


        <span className="flex itemcenter">
        
        
            <h1 className="av-heavy fs18 nomar fs18res"> 

        
               {facture.status == "complété" ? 'Details Facture ' : facture.status == "en cours" ? 'Facture en cours' : 'Facture supprimée' }
        
            </h1>
        </span>
        
              {facture.status != 'payé' &&
              <button className="btn-default av-heavy btn fs15 cursor  u-pad-vertical-xs"
                      style={{color: 'red', borderColor: ' #eff4fa'}}
                    >
        
                  Annuler
        
              </button>
              }
          </div>
        
        
        <div className="flex">
        
        <div className="grille21 flex itemcenter u-pad-top-s  column" style={{overflow:'hidden',height:'calc(100vh - 70px)',overflowY:'scroll'}}>
        
        
        
        
        
        <div className="w90  block bgwhite fact rel"  id="fact"  >
          <h3 style={{textAlign:'center',margin:'0'}} > Chic'king Leb </h3>
          <h4 style={{marginBottom:'20px',textAlign:'center',marginTop:'0'}}> Tel :  +33 79 39 39 262 / +237 658 965 171   </h4>
        <h2 className="center av-medium fs16 u-mar-bottom-xxs" style={{textAlign:'center',margin:'0'}}> {facture.status == "complété" ? 'FACTURE ' : 'Ticket de session' } #{facture._id} </h2>
        <h4 className="collab fs11" style={{textAlign:'center',margin:'0 0 10px  '}}> { moment(facture.createdAt).format('Do MMMM Y HH:m')} </h4>
        
        
        
        
        
        <ul className="cart-list" style={{
          listStyle: 'none',
        padding: '0',
        margin: '0'
          }}>
        
        
        

      {  facture.items.map( row => 


<li class="section-cart flex itemcenter justbtw ">
             <span  className="grille2 av-medium fs15 ">
                 <span style={{width:'20px'}} className="u-mar-right-xs">{row.quantity}   </span>	{row.product.name}
</span>
         <div className="flex grille itemcenter flexend" >



           <span className="av-medium fs14 ">
           <NumberFormat value={row.total}  displayType={'text'} thousandSeparator={true}  />


             </span>
         </div>
     </li>
 ) }






        
        </ul>
        
        
        
        
        <div  style={{borderTop:'1px solid #dee3e7',display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Client </span>
        
        
          { facture.customer != null &&
        
        
        
                              <h3 className="av-medium fs15 nomar" style={{margin:'0'}} >
            {facture.customer.name} {facture.customer.lastname}
          </h3>
        
        
          }
        
        
        </div>
              
        <div  style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span > Vendeur: </span>
        
        
          { facture.user != null &&
        
        
        
                              <h3 className="av-heavy fs15 nomar" style={{margin:'0'}} >
            {facture.user.infos.firstname}  {facture.user.infos.lastname}
          </h3>
        
        
          }
        
        
        </div>
        
        <div style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}}  >
        <span> Réglèment </span>
        <h3 className="av-heavy fs13" style={{margin:'0'}}> {facture.payment} </h3>
        </div>
          
        <div className="flex  justbtw u-pad-vertical-xs" style={{display:'flex',alignItems:'center',justifyContent: 'space-between',paddingTop: '2px',
            paddingBottom:'2px'}} >
        <span className="fs15"> TOTAL </span>
        <h2 className="fs18 av-heavy" style={{margin:'0'}}> {facture.total} Fcfa</h2>
        </div>
        
        
        
        
        <h4 style={{textAlign:'center',margin:'0'}}>  MERCI DE VOTRE VISITE !</h4>
        
        
        
        
        
        
        </div>
        
        
        
        
        </div>
        
        </div>
        
        
        
        </Fragment>
        :
        
        <div className="hv100 flex itemcenter column  rel" >
        
        <div style={{height:'50px',borderBottom:'1px solid #dee3e7',top:0}} className="abs w100 flex bgwhite itemcenter justcenter rel">
        
          <h1 className="av-heavy fs18 nomar fs18res"> Details Facture  </h1>
        
        
        
        </div>
        
        
        <Spin indicator={antIcon} className="w100 u-mar-top-xxl" />
        
        </div>
        
        }
        
        
        </div>
        


    );


}
