import {
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

  import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

  export const  activetab = atom({
    key: 'activetab', // unique ID (with respect to other atoms/selectors)
    default: '/', // valeur par défaut (alias valeur initials)
  });


  export const  activeStartDate= atom({
    key: 'activeStartDate', 
    default: 'today', 
  });

  export const  activeEndDate= atom({
    key: 'activeEndDate', 
    default: 'today', 
  });

  export const  tenantInfos= atom({
    key: 'tenantInfos', 
    default: {}, 
    effects_UNSTABLE: [persistAtom],
  });


  export const  userInfos= atom({
    key: 'userInfos', 
    default: {}, 
    effects_UNSTABLE: [persistAtom],
  });

