
import React, {Fragment, useEffect, useState,useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import {Steps, Form, Col, Row,Modal,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import FormOrder from './form/step1formpur';

import axios from "axios";
import {useHistory} from "react-router";
import {CaretDownOutlined,CloseOutlined} from '@ant-design/icons';
import { useGetCustomers, useGetProducts, useGetUserBranch,useGetTeam,useAddOrder } from '../../api/api';




export default function  AddOrder ({close,order}) {
    const history = useHistory()

    const [loading,setLoading] = useState(false)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)
    const [current,setCurrent]= useState(0)


    const [form] = Form.useForm();
    const [data1, setData1] = useState({});
    

    const [products,setProducts] = useState(null)
    const [branches,setBranches] = useState(null)
    const [customers,setCustomers] = useState(null)
    const [users,setUsers] = useState(null)
    const [onWha,setOnWha] = useState(true)
    const [onSms,setOnSms] = useState(true)
    const [onEmail,setOnEmail] = useState(true)

    const { data, error, isLoading, refetch } = useGetProducts({}, {});
    const { data:dataBranches,  isLoading:isLoadingBranches } = useGetUserBranch({}, {});
    const { data:dataCustomer,  isLoading:isLoadingCutomers } = useGetCustomers({}, {});
    const { mutateAsync: addOrder } = useAddOrder();
    const [prod,setProd] = useState()
    const [total,setTotal] = useState()
    const [fee,setFee] = useState(500)


    const notifWha= (val) => {

        setOnWha(val)

    }

    const notifSms= (val) => {

        setOnSms(val)

    }

    const notifEmail= (val) => {

        setOnEmail(val)

    }
  
    useEffect(() => {


        setProducts(data?.data);

        setBranches(dataBranches?.data);

        setCustomers(dataCustomer?.data);

    

    }, [data,dataBranches,dataCustomer]);


    
  
    const  changeProd = (prod,total) => { 

         setProd(prod)
         setTotal(total)
      
    }


 const  changeFee = (fee) => { 

         setFee(fee)
       
      
    }

    const openConfirm = () => {

        form.submit()
        
        form.validateFields()
        .then(async function (values) {
       
              
        if(prod?.length > 0) {
         setVisible(true)   
        }else{
            message.warning('Veuillez ajouter un produit')
        }   
          
          
          
               
          
          
 
 
 
 
 
          
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
         
    }
    


   const  handleSubmit = async () => {
       

        form.submit()
        
       form.validateFields()
       .then(async function (values) {
      



        console.log(prod)
      
         values.onWha = onWha
         values.onSms = onSms
         values.onEmail = onEmail
         
         values.items = []
         
         
         
         values.exp_fee = fee
         prod.map((p)=> {
         
             
             let item = { product: p._id, quantity:parseInt(p.num == null ? 1 : p.num),discount:0,price:p.retail_price,total:(p.num == null ? 1 :p.num) *p.retail_price }
         
             values.items.push(item)
             
         
         })
         
         if(values.pay_model == 'Reglé'){
             values.payment = 'espèces'
             values.sale_status = 'complété'
             values.payment_status = 'Reglé'
             values.payment_type = 'espèces'
         }else{
             values.payment = 'espèces'
             values.sale_status = 'en cours'
             values.payment_status = 'Attente de paiement'
         }
         
         values.tunnel = 'pos'
             
         
                 try {
                    
                     setLoading(true)
                   

                     await addOrder(values)


         
                     message.success('Enregistrement réussi!!')
                     setVisible(false)
                     close()
                     //history.push('/orders')
                     setLoading(false)
         
                 } catch(e) {
                     setLoading(false)
                     message.warning('Erreur enregistrement!')
         
                 }
         
         





         
       })
       .catch((errorInfo) => {
         console.log(errorInfo);
       });

        




      }
    





    return (






                            <div className="flex  column rel h100">

                          
                     
<div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-vertical-s" style={{top:0,borderBottom:'1px solid #DDD'}}>



<div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:34}} onClick={()=>close()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

</span>



</div>





<h2 className="fW600 nomar"> {order ? 'Commande ' +order.order_id :'Nouvelle commande'}   </h2>








<div className="flex itemcenter abs" style={{right:34}}>





<Button type="primary"  loading={loading} className="rad4" onClick={()=>openConfirm()} >
    <span className="fs13 fW500 ">  Valider </span>
</Button>

</div>









</div>

                       

                            <Spin tip="Chargment..." size="large" spinning={false }>



                                <Row gutter={32}  className=" w100  u u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres ">

                                <Col span={24} className=" w100  u-pad-top-s u-pad-horizontal-s   h100  u-mar-top-xl-res nopadres noradres ">


                                    <div className="flex mauto w60 column u-mar-top-xl    nomarres u-pad-s-res" style={{height:'max-content'}}>



        
                                           
                                           
                                           
                                       
                                           <FormOrder data={data1} order={order} changeProd={changeProd} changeFee={changeFee} form={form} products={products} customers={customers} branches={branches} users={users}  />
                                     
                                           
                                           
                                           
                                        


                                    </div>



                                </Col>

                              
                                </Row>

                            </Spin>







                            <Modal

width={500}
okButtonProps={{ loading:loading}}
onOk={()=>handleSubmit()}
onCancel={()=>setVisible(false)}
open={visible}
title= {<span className=" fs17"> Confirmez la commande </span>}
bodyStyle={{ paddingBottom: 0,paddingTop:12,backgroundColor:"#F7F7F9" }}
okText={"Valider la commande"}  
cancelText="Annuler"

destroyOnClose={true}

>




<div className="flex  column rel h100 u-pad-vertical-s " >

                        

<h3 className="fW500 fs15"> Envoyer une notification au client par </h3>

<div className="flex column u-mar-top-s">                

<div className="flex justbtw itemcenter u-mar-bottom-xs">

<div className="flex itemcenter">

<Switch checked={onWha} onChange={notifWha}/>
<span className='fs14 fW500 u-mar-left-xs'> Par Whatsapp</span>

</div>

<span style={{opacity:0.6}}> --- </span>

</div>


<div className="flex justbtw itemcenter u-mar-bottom-xs">

<div className="flex itemcenter">

<Switch checked={onSms} onChange={notifSms}/>
<span className='fs14 fW500 u-mar-left-xs'> Par Sms</span>

</div>
<span style={{opacity:0.6}}> --- </span>

</div>




<div className="flex justbtw itemcenter u-mar-bottom-xs">

<div className="flex itemcenter">

<Switch checked={onEmail} onChange={notifEmail}/>
<span className='fs14 fW500 u-mar-left-xs'> Par Email</span>

</div>

<span style={{opacity:0.6}}> --- </span>

</div>

</div>











</div>







</Modal>







</div>
             
                            

    );


}
