import {  Row } from 'antd';
import React from 'react';


function Noti ({notif}) {


  return (
    <div className=''>
         <Row justify="v-space u-mar-top-s rel justcenter itemcenter">
    <div  className='deco justcenter itemcenter flex abs' style={{left:0}} onClick={()=>notif()}>
      <svg data-testid="close-icon" width="24" height="24" viewBox="0 0 24 24" role="img"  title="Fermer"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M5.75 5.75L18.25 18.25M18.25 5.75L5.75 18.25"></path></svg>
      </div>
    <div className='pp'>
    <span className="st-not"> Notifications </span>
    </div>
    
    </Row>
       <div className='center pos'>
       {/* <img className='' alt='img' src='/notif.png' width="auto" height="50"/> */}
       <div className='u-mar-top-jumbo'>
       <svg data-testid="notification-null-state-icon" width="50" height="50" viewBox="0 0 62 62" role="img" ><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(1.000000, 1.000000)" stroke="currentColor" class="svg-icon__stroke" stroke-width="2"><path d="M60,30 C60,46.5681818 46.5681818,60 30,60 C13.4309091,60 0,46.5681818 0,30 C0,13.43 13.4309091,0 30,0 C46.5681818,0 60,13.43 60,30 Z" class="svelte-1ad4rt8"></path><path d="M20.9090909,36.3627273 L20.9090909,41.8172727 C20.9090909,43.3172727 20.0909091,44.5445455 19.0909091,44.5445455 C18.0909091,44.5445455 17.0318182,43.3409091 16.7381818,41.8709091 L15.4545455,35.4536364" class="svelte-1ad4rt8"></path><path d="M38.1818182,16.3627273 L37.83,16.3627273 C32.1263636,21.4490909 24.6072727,24.5445455 16.3636364,24.5445455 C16.0590909,24.5445455 15.7572727,24.5536364 15.4545455,24.5445455 L15.4545455,35.4536364 C15.7572727,35.4445455 16.0590909,35.4536364 16.3636364,35.4536364 C25.0418182,35.4536364 32.9127273,38.9727273 38.7127273,44.5445455 C39.4554545,44.3163636 40,43.6327273 40,42.8145455 L40,18.1809091 C40,17.1772727 39.1863636,16.3627273 38.1818182,16.3627273 Z" class="svelte-1ad4rt8"></path><path d="M36.3636364,18.1809091 L36.3636364,41.8172727" class="svelte-1ad4rt8"></path><path d="M15.4545455,35.4536364 C12.4418182,35.4536364 10,33.0118182 10,29.9990909 C10,26.9872727 12.4418182,24.5445455 15.4545455,24.5445455" class="svelte-1ad4rt8"></path><path d="M50,29.9990909 L43.6363636,29.9990909" class="svelte-1ad4rt8"></path><path d="M49.0909091,38.1809091 L43.6363636,35.4536364" class="svelte-1ad4rt8"></path><path d="M49.0909091,21.8172727 L43.6363636,24.5445455" class="svelte-1ad4rt8"></path></g></g>
        
        </svg>
       </div>
       <h3 className="center fW600 fs18 u-pad-top-s">Restez connecté à tout moment</h3>

       <p className="center u-pad-horizontal-s "> Recevez toutes les notifications liées à votre business, nouvelles commandes , alerte de stock , suivi des livraisons...</p>

       </div>
  
    </div>
  );
};

export default Noti; 