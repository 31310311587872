
import React, {useEffect,useRef, useCallback,useState,Fragment} from 'react';




export default function  CustomerPlaceholder () {








    return (

      <div style={{marginTop:'35px'}}>
                   
{Array(12).fill("").map((a) => 

<div class="flex itemcenter justbtw" style={{height:'40px',borderBottom: '1px solid #f0f0f0'}}>
                      
                      <div className="flex itemcenter" style={{width:'270px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      
                      <div className="flex itemcenter" style={{width:'270px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>

                      <div className="flex itemcenter" style={{width:'270px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>


                      <div className="flex itemcenter" style={{width:'270px',padding: '6px 16px'}}>
                      <div className="placeholder w100" style={{height:'9px'}}>
                      <div className="animated-background"></div>
                      </div>
                      </div>
                      

                      
                      


                      </div>

 )}



                         
</div>
    );


}
