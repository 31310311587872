import  React , {Fragment, useEffect, useState} from "react";
import {Upload,Modal, Layout, Form, Col, Row,Divider,Checkbox,Tabs,Switch,Button, Select, Spin, Input, message} from 'antd';
import ImgCrop from 'antd-img-crop';
import { TwitterPicker } from 'react-color';
import Autosuggest from 'react-autosuggest';
import NumberFormat from "react-number-format";
import { LoadingOutlined,CloseOutlined } from '@ant-design/icons';


const {Option} = Select;
export default function Step2Form({data,products,changeProduct,changeFee }) {
    const [suggestionsserv,setSuggestionsserv] = useState([])
    const [valueserv,setValueserv] = useState('')
    const [exp_fee,setExp_fee] = useState(500)
    const [visibleShip,setVisibleShip] = useState(false)
    const [loading,setLoading] = useState(false)
    const [listprodservv,setListproservv] = useState(true)
    const [suggestions,setSuggestions] = useState([])
    const [productcart,setProductcart] = useState(data?.prod ? data.prod : [])
    const [total,setTotal] = useState(0)


    useEffect(() => {

      gettotal();
      
     
  }, [productcart]);



  async function onSubmit ( values )  { 
    setExp_fee(values.exp_fee)
    setVisibleShip(false)
    changeFee(values.exp_fee)
  }

    const	addProd =  (id) => {
      let prodo = [];
      
const po = [].concat.apply([], products);


const prod = po.filter(u => u._id === id );

  const added = productcart.filter(u => u._id === prod[0]._id);

 

if(	added?.length === 1 ) {

const objIndex = productcart.findIndex((obj => obj._id == prod[0]._id));

console.log(objIndex)
console.log(productcart[objIndex])
if(isNaN(productcart[objIndex].num) ) { productcart[objIndex].num = 2 }else { productcart[objIndex].num++};


setProductcart(productcart.concat())
changeProduct(productcart.concat(),total)

}else{


  setProductcart(productcart.concat(prod))
  changeProduct(productcart.concat(),total)


} 




}


const gettotal =  () => {
  var total = 0;
  for ( var i = 0, _len = productcart?.length; i < _len; i++ ) {
    
    productcart[i]["num"] != undefined ?  total += productcart[i]["num"]*productcart[i]["retail_price"] : total += 1*productcart[i]["retail_price"]
  }
  
  setTotal(total)
  changeProduct(productcart,total)

  }


const removeProd = (id) => {

  const objIndex = productcart.findIndex((obj => obj._id == id));

  let obji = products.findIndex((obj => obj._id == id));
  products[obji].num = 1

  //setProducts(products)

let newcart = productcart.filter((u,i) =>  i != objIndex )
setProductcart(newcart)


}
 

const  onChange = (e) => {

  const objIndex = productcart.findIndex((obj => obj._id == e.target.id));


  if(( parseInt(e.target.value)) > 0 )
  {

productcart[objIndex].num = parseInt(e.target.value);

setProductcart(productcart.concat())
changeProduct(productcart.concat(),total)

}


};


const  onChangePrice = (e) => {

  const objIndex = productcart.findIndex((obj => obj._id == e.target.id));


  if((parseInt(e.target.value)) >= 0 )
  {

productcart[objIndex].retail_price = parseInt(e.target.value);

setProductcart(productcart.concat())
changeProduct(productcart.concat(),total)

}


};


const   getSuggestionsServ = (value) => {

        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
          return [];
        }
        const regex = new RegExp('\\b' + escapedValue, 'i');

        return products?.filter(person => regex.test(getSuggestionValueServ(person)));
      }

const onSuggestionSelectedServ = (event, { suggestion, method }) => {

        setValueserv('');
        setListproservv(true)
        
        }
        
const onSuggestionsFetchRequestedServ = ({ value }) => {
            setSuggestionsserv(getSuggestionsServ(value))
         
          };
        
          
        
 const onSuggestionsClearRequested = () => {
              setSuggestions([])
         
          };

      
const   getSuggestionValueServ = (suggestion) =>   `${suggestion.name} `;

  
const escapeRegexCharacters = (str) => {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          }

const   onChangeSS = (event, { newValue, method }) => {
            setValueserv(newValue)
            setListproservv(newValue != '' ? false : true,)
          
             };

          const inputPropss = {
            placeholder: 'Rechercher avec la Désignation',
            value:valueserv,
            onChange: onChangeSS
          };


          const    renderSuggestionServ = (suggestion) => (
            <>
                       <div className="flex itemcenter  cursor  nopad " style={{height:'40px'}} onClick={()=>addProd(suggestion._id)}  >
            
                       
                       <svg viewBox="0 0 24 24" width="24" height="24" style={{color:'rgb(155, 177, 200)',margin:'0 15px'}}><path data-v-6eb876bc="" fill="none" d="M0 0h24v24H0z"></path><path data-v-6eb876bc="" d="M20.083 15.2l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zm0-4.7l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm-7.569-9.191l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0zM12 3.332L5.887 7 12 10.668 18.113 7 12 3.332z" fill="currentColor"></path></svg>
            
                      
            
                       <h4 className="nomar u-mar-left-l fs14 av-heavy" >
            
                         {suggestion.name } 
                         
                         </h4>
            
            
                    
            
                       </div>
            </>
                   );
            

    
  return (
    <div className="appro rel">
    

<h3 className="fW600 ">Produits</h3>
{products && 
<>
<Autosuggest
           suggestions={suggestionsserv}
           onSuggestionsFetchRequested={onSuggestionsFetchRequestedServ}
           onSuggestionsClearRequested={onSuggestionsClearRequested}
           getSuggestionValue={getSuggestionValueServ}
           onSuggestionSelected={onSuggestionSelectedServ}
           renderSuggestion={renderSuggestionServ}
           inputProps={inputPropss}
         />


<div  style={{top:80}} className="w100 abs">
<div className="flex justbtw u-pad-vertical-xs u-pad-left-xs u-mar-top-s" style={{borderTop: '1px #f2f2f2 solid',
    background:'rgb(243, 245, 249)'}}>
  
  <span className="grille3 fW500"> Produits </span>
  <span className="grille07 fW500 center" style={{marginRight:'15px'}}> Qté </span>
  <span className="grille07  fW500 center"> P.U</span>
  <span className="grille  fW500 right"> Total</span>
  <span className="u-pad-horizontal-m fW500 right">  </span>
   </div>

{productcart?.length != 0 &&
       
       <Fragment>
  
      
       <ul class="cart-list" style={{height:'auto'}}>
      
       {
       
       
       productcart.map( row => 
 <li key={row._id} class="section-cart flex itemcenter justbtw  u-pad-left-xs ">
                     <span  className="grille3 av-heavy fs14 ">
           {row.name}
 </span>


                     <input className="c-input qte grille07 right" value={row.num ? row.num : 1} id={row._id} onChange={onChange} type={"number"}  style={{height:'35px',marginRight:'10px',width: '45px',textAlign:'center', border:0.5}}/>


                   

                     <input className="c-input qte grille07 right" value={row.retail_price} id={row._id} onChange={onChangePrice} type={"number"} style={{height:'35px',width: '45px',textAlign:'center',border:0.5}}/>



                   <span className="av-heavy fs14 grille right">
                   {  Intl.NumberFormat('fr-FR').format((row.num ? row.num : 1 )*row.retail_price) }

                     </span>
                     <span className="av-heavy fs14 u-pad-horizontal-s right">
                     <CloseOutlined  className="rmv cursor fs15" onClick = {()=>removeProd(row._id)} />
                 </span>
             </li>
 ) }

       </ul>

       </Fragment>
      

       }



   

<div className="flex  w100 flexend">


<div className="w50" style={{border:'1px #d9d9d9 solid',borderTop:0}}>
   <div className="flex justbtw u-pad-xs" ><span className="fW500"> Frais de livraison</span> <span className="cursor coltext fW600" onClick={()=>setVisibleShip(true)}> {exp_fee != null ? exp_fee + ' Fcfa' : 'Ajouter'} </span> </div>
 
  <div className="flex justbtw  bggray u-pad-xs fW600 ">  <span > TOTAL  </span>   <span>{  Intl.NumberFormat('fr-FR').format(parseInt(parseInt(total) + parseInt(exp_fee))) } Fcfa</span>  </div>   

   </div>


   </div>

      </div>

      </>


}
      <Modal
        width={400}
        onCancel={()=>setVisibleShip(false)}
        visible={visibleShip}
                                      bodyStyle={{ paddingBottom: 12,paddingTop:12,height:'auto'}}
                                      destroyOnClose={true}
                                      closable={true}
                                      footer=""
                                      maskClosable={true} 
       >








<Form layout="vertical" style={{marginTop:20,padding:20}} onFinish={onSubmit}  initialValues={{
                exp_fee
            }} >
                                            <Row gutter={16}>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="exp_fee"
                                                        label="Frais de livraison"
                                                        rules={[{ required: true }]}
                                                    >
                                                        <Input  />
                                                    </Form.Item>
                                                </Col>


                                           

                                            </Row>


                                            <Form.Item className="w100" >

                                                    <Divider/>
                                                    <div style={{textAlign:'right'}}>
                                                        <Button onClick={()=>setVisibleShip(false)} size={'large'} className="rad8 u-pad-horizontal-l" style={{ marginRight: 12 }}>
                                                            Annuler
                                                        </Button>
                                                        <Button  size={'large'} loading={loading} type="primary" htmlType="submit" className="rad8 u-pad-horizontal-l" type="primary">
                                                            Enregistrer
                                                        </Button>
                                                    </div>



                                            </Form.Item>




                                        </Form>












  </Modal>







    </div>
  );
}
