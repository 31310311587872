
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Spin, Menu,Tag, Table,Input,Typography} from 'antd';

import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

import {
    useRecoilValue,
  } from 'recoil';

  import { useGetReportSalesPayments } from '../../api/api';

import SelectDate from '../../components/selectdate';


import {useHistory} from "react-router";
import LayoutPage from '../layout';
import { activeStartDate } from "../../store";


moment.locale('fr')
const { Text } = Typography;


export default function  Reports () {

    
    const [searchText, setSearchText] = useState("");


    const Startdate = useRecoilValue(activeStartDate);

   
    const [sales,setSales] = useState(null)



    const { data, isLoading, refetch } = useGetReportSalesPayments({}, {});


    useEffect(() => {

        setSales(data?.data);



    }, [data,Startdate]);

    useEffect(() => {
        refetch();
      }, [Startdate]);






    const columns = [

     


    
        {
            title: 'Paiement',
            dataIndex: 'payment',
            filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.category).toLowerCase().includes(value.toLowerCase())
}
        },
       
        {
            title: 'Montant',
            dataIndex: 'total',
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) }
 Fcfa
            </>
        }
/*         {
            title: 'Marge',
            dataIndex: 'profit_margin',
            render: item =>
            <>
{  Intl.NumberFormat('fr-FR').format(item) }
%
            </>
        }, */






    ];







    return (





        <LayoutPage>
                  
                            <Spin tip="Chargment..." size="large" spinning={isLoading }>

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">


                                <div className='w25'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par catégorie" style={{minWidth:'250px',height:'45px!important'}} />

</div>



                                <div className="flex u-mar-right-s itemcenter">

                                 


           <SelectDate />
                              
                              
                                </div>

 
                                </div>

                                <Table columns={columns} dataSource={sales}

summary={(pageData) => {
    let totalv = 0;
    pageData.forEach(({ total }) => {
      totalv += total;
    });
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} className='fW600'>Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Text className='fW600' >{ Intl.NumberFormat('fr-FR').format(totalv)} Fcfa</Text>
          </Table.Summary.Cell>
          
        </Table.Summary.Row>
      
      </>
    );
  }}

                                      
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>

                            </Spin>


       </LayoutPage>           



    );


}
