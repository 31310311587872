
import React, {Fragment, useEffect,useRef, useState} from 'react';
import { useReactToPrint } from "react-to-print";

import {Button, Layout,Dropdown, Checkbox,Spin, Menu,Table,Descriptions} from 'antd';


import noneimg from "./images/product-none.png";
import { useGetProducts} from '../../api/api';
import {CaretDownOutlined,CloseOutlined} from '@ant-design/icons';
import {
    useRecoilValue
  } from 'recoil';

  import moment from 'moment';
import 'moment/locale/fr';

  import {tenantInfos,userInfos} from '../../store';



const {  Content } = Layout;

export default function  ProductExport ({onClose}) {
    const [products,setProducts] = useState()
    const [filters, setFilters] = useState();
    const tenant =  useRecoilValue(tenantInfos)
    const User = useRecoilValue(userInfos);


    const [columns,setColumns] = useState([

     /*    {
            title: 'image',
            dataIndex: '',
            render: text =>
                <>
<img src={noneimg} width="40" height="40" />
                </>

        }, */
        {
            title: 'Designation',
            dataIndex: 'name',
        
        },
        {
            title: 'P.vente',
            dataIndex: 'retail_price',
        },
        {
            title: "P.achat",
            dataIndex: 'supply_price',
        },
      
       
      
        {
            title: 'Stock.debut',
            dataIndex: ''
        }
        ,
      
        {
            title: 'Stock.fin',
            dataIndex: ''
        }
     
    ])

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0 ,
      });

    const { data, error, isLoading, refetch } = useGetProducts({}, {});


    useEffect(() => {
        setProducts(data?.data );
        setPagination({
          ...pagination,
          total: data?.total,
          showQuickJumper: true,
        });

      }, [data]);
    
/*       useEffect(() => {
        refetch();
      }, [pagination.current, pagination.pageSize, filters]); */
      const componentRef = useRef();


      const handlePrint = useReactToPrint({
        content: () => componentRef.current
      });


      const menu = (
        <Menu style={{width:'600px'}} >
        

        <Fragment>


<div className="flex itemcenter justcenter wrap  justbtw h100 rescolumn ">



<div  className="grid-body">

<Checkbox defaultChecked={true} onChange={(e)=>onChange(e,"Designation")} >Designation</Checkbox>
<Checkbox defaultChecked={true} onChange={(e)=>onChange(e,"P.vente")}>Prix de vente</Checkbox>
<Checkbox defaultChecked={true} onChange={(e)=>onChange(e,"P.achat")}>Prix d'achat </Checkbox>
<Checkbox defaultChecked={false}>Catégorie</Checkbox>
<Checkbox defaultChecked={false}>Marque</Checkbox>
<Checkbox defaultChecked={false}>Fournisseur </Checkbox>
<Checkbox defaultChecked={true} onChange={(e)=>onChange(e,"Stock en machine")}  >Quantité en stock</Checkbox>
<Checkbox defaultChecked={false}>Stock minimal</Checkbox>
<Checkbox defaultChecked={false}>Géré en stock</Checkbox>
<Checkbox defaultChecked={false}>Non Géré en stock</Checkbox>
<Checkbox defaultChecked={false}>Stock critique</Checkbox>
<Checkbox defaultChecked={false}>Stock minimal</Checkbox>
<Checkbox defaultChecked={false}>Image</Checkbox>

</div>



</div>

</Fragment>


        </Menu>
    );


   const onChange = (e,field) => {

    if (!e.target.checked){
        setColumns(columns.filter(c=>c.title != field))
 
    }else{



        // switch case


    }


   }


  


  






    return (




<>


                  
                            <Spin tip="Chargment..." size="large" spinning={isLoading }>

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex itemcenter justcenter  u-mar-bottom-s fixed w100 z999 bgwhite u-pad-horizontal-s u-pad-top-s" style={{top:0}}>



                                <div className="flex itemcenter justcenter cursor rad8 btnfilter u-mar-right-s abs" style={{left:0}} onClick={()=>onClose()}>

<span className="fs15  " style={{color:'#1a1a1a'}}> 


<CloseOutlined />

 </span>



</div>





<h2 className="fW600"> Exporter les produits  </h2>




  



<div className="flex itemcenter abs" style={{right:34}}>


<div className='flex itemcenter'>




{/* <Dropdown overlay={menu} class="cursor u-mar-right-xs" trigger={['click']}>


<div className="flex itemcenter cursor rad8 btnfilter u-mar-right-s">

   <span className="fs15 av-heavy u-mar-right-xs" style={{color:'#1a1a1a'}}> 
   
  Filtrer les champs
    </span>

   <span style={{marginTop:'4.5px'}}>

<CaretDownOutlined />

</span>


</div>

</Dropdown> */}



         </div>


                                <Button type="primary"   className="rad4" onClick={()=>handlePrint()}>
                                    <span className="fs13 fW500 ">  Exporter en pdf </span>
                                </Button>

                                </div>
                           








                                </div>




<div  className="u-mar-top-xxl ">

<div ref={componentRef}>
    <div className="bggray u-pad-horizontal-xxl u-pad-vertical-l">




<Descriptions title={"Fiche Produit - "+ tenant.name} layout="vertical">
    <Descriptions.Item label="Exporté le :">{ moment().format('Do MMM Y HH:m')}</Descriptions.Item>
    <Descriptions.Item label="Exporté par:">{User.infos.firstname}  {User.infos.lastname}</Descriptions.Item>
    <Descriptions.Item label="Address" span={2}>
      {tenant.address}  - {tenant.phone}
    </Descriptions.Item>
  </Descriptions>



    </div>

    <div className="">
                                <Table columns={columns}  dataSource={products} 
                                       pagination={false}
                                      />
    </div>
    </div>
</div>

                            </div>

                            </Spin>


                            </>



    );


}
