
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { SubNav } from '../../components/SubNav';
import { salesNav } from '../../config/navItems';
import {Modal,Spin,Button, Table,Input} from 'antd';
import { Link } from "react-router-dom";

import TabPlaceholder from './components/tabplaceholder'; 
import {
    useRecoilValue,
  } from 'recoil';
import Details from './details';
import { useGetSales } from '../../api/api';

import SelectDate from '../../components/selectdate';

import LayoutPage from '../layout';
import { activeStartDate } from "../../store";


moment.locale('fr')

export default function  Sales () {

    const [visible,setVisible] = useState(false)
    const [visibleFilter,setVisibleFilter] = useState(false)
    
    const [sales,setSales] = useState(null)
    const [factureId,setFactureId] = useState(null)
    const [searchText, setSearchText] = useState("");


    const Startdate = useRecoilValue(activeStartDate);


    const { data, isLoading, refetch,isFetching } = useGetSales({}, {});





    useEffect(() => {



    setSales(data?.data);





    }, [data,Startdate]);


    useEffect(() => {
        refetch();
      }, [Startdate]);



    const change  =  (r)  => {
setVisible(true)
setFactureId(r._id)

    }




    const columns = [


        {
            title: 'Ref',
            dataIndex: 'sale_id',
            width: 50,

        },

        {
            title: 'Client',
            dataIndex: '',
            width: 230,
            render: item =>
            <>

{item.customer  != null &&

<div className="flex itemcenter">




<div class="flex  ">
  
    
        <span className="coltext  "> {item.customer.name}</span>
 
<span className="coltext u-mar-left-xxs"> {item.customer.lastname}</span>

</div>

</div>

}

            </>,


filteredValue:[searchText],
onFilter:(value,record)=>{
    return String(record.customer?.name).toLowerCase().includes(value.toLowerCase()) || String(record.user?.infos.firstname).toLowerCase().includes(value.toLowerCase()) || String(record.sale_id).toLowerCase().includes(value.toLowerCase()) || String(record.status).toLowerCase().includes(value.toLowerCase())
}

    
        },
        {
            title: 'Date et heure',
            dataIndex: '',
            width: 150,
            render: item =>
                <>
 {moment(item.createdAt).format('D MMM-YY HH:m')}
                </>

        },

        {
            title: 'Montant',
            dataIndex: '',
            width: 150,
            render: item =>
                <>
{  Intl.NumberFormat('fr-FR').format(item.total) }
<span className="fs10 u-mar-left-xxs">Fcfa</span>
                </>

        },



    
        {
            title: 'Tunnel',
            dataIndex: '',
            width: 140,
            render: item =>
            <>
{  item.tunnel == 'pos' ? 'point de vente' : item.tunnel  }
            </>

        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 100,
        },

        {
            title: 'Paiement',
            dataIndex: 'payment',
            width: 120,

        },



        {
            title: 'Vendeur',
            dataIndex: '',
            width: 200,

            render: item =>
            <>

{item.user  != null &&

<div className="flex itemcenter">




<div class="flex  ">
  
    
        <span className="coltext  av-heavy fs13"> {item.user.infos.firstname}</span>
 
<span className="coltext fs13 u-mar-left-xxs"> {item.user.infos.lastname}</span>

</div>

</div>

}

            </>

    
        },



    ];







    return (





        <LayoutPage>

                            <SubNav items={salesNav}  /> 

                            <div className="container">
                            <div className="grille w100   u-mar-top-l    noborder hauto  u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw itemcenter u-mar-bottom-s">




                                <div className='w25'>

<Input.Search onSearch={(value)=>{setSearchText(value)}} onChange={(e)=>{setSearchText(e.target.value)}}  placeholder="Rechercher par Client,Vendeur..." style={{minWidth:'250px',height:'45px!important'}} />

</div>










                                <div className="flex itemcenter " >
                                        <div className="separator nonedisplayres"></div>


                <SelectDate />


                <Button type="primary"   className="rad4 u-mar-left-s">
<Link to={'/pos'}> <span className="fs13 fW500">  Ajouter une vente </span> </Link>
</Button>

                                    </div>













                                


                                    <Modal

                                        width={500}
                                        onCancel={()=>setVisible(false)}
                                        open={visible}
                                        title= {<span className=" fs17"> Details facture </span>}
    bodyStyle={{ padding: 0 }}
    footer= {null}
    maskClosable={true}
destroyOnClose={true}

                                    >
{ <Details id={factureId} onClose={()=>setVisible(false)} />
 }
                                    </Modal>




                                </div>

                                <Table columns={columns} locale={{emptyText:<></>}}  loading={{indicator:<TabPlaceholder/>,spinning:isFetching}} dataSource={sales}

                                       onRow={(record) => ({
                                           onClick: () => { change(record) }
                                       })}
                                       size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />


                            </div>
</div>
                            


               </LayoutPage>  


    );


}
