
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import Header from '../header';
import LeftSide from '../sider/sider'
import {Button, Layout,Drawer,Select,Spin, Menu,Tag, Table,Input} from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import axios from "axios";
import {URL} from "../../urlapi";
import { Link } from "react-router-dom";
import {Logout} from "../../helpers/logout";
import LayoutPage from '../layout';

const {  Content } = Layout;
export default function  Transfers () {

    const [loading,setLoading] = useState(true)
    const [reload,setReload] = useState(1)
    const [visible,setVisible] = useState(false)

    const [Transfers,setTransfers] = useState(null)



    useEffect(() => {
        const token = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));


        const getTransfers= async () => {

            try {
                const result = await axios.get(URL+'/transfers', {
                    headers: {
                        'tenant_user_api_key': token             
                }
            }
            
            )

            setTransfers(result.data.data);
            setLoading(false)

        } catch (e) {
            setLoading(false)
            if(e.response && e.response.status == 401){
                Logout()
            }
        }



        };




        getTransfers();


    }, [reload]);


    const close = () => {

        setVisible(false)
        setReload(reload+1)

    }



    const columns = [


        {
            title: 'Nom',
            dataIndex: 'name',
            width: 200,
        },
        {
            title: 'Origine',
            dataIndex: 'name',
        },
        {
            title: 'Destination',
            dataIndex: 'name',
        },
      
        {
            title: 'Total de produits',
            dataIndex: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'name',
        },
      



    ];







    return (





        <LayoutPage>

                            {/*<h1 className="fs30 color-0d0 av-heavy u-mar-top-xs ">Employes</h1>*/}

                            <div className="flex u-mar-top-xs">
                            <div className="bmc-dash-pill p-relative  cursor rel">
                                <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Produits</span>
                                <Link to={'/products'} className="ab-link t-0">
                                </Link>
                            </div>


                                <div className="bmc-dash-pill  cursor    rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Catégories</span>
                                    <Link to={'/products/Transfers'} className="ab-link t-0">
                                      </Link>
                                </div>

                                <div className="bmc-dash-pill p-relative cursor-pointer  cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Fournisseurs</span>
                                    <Link to={'/products/supliers'} className="ab-link t-0">
                                    </Link>
                                </div>


                                <div className="bmc-dash-pill   cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Marques</span>
                                    <Link to={'/products/brands'} className="ab-link t-0">
                                    </Link>
                                </div>

                                <div className="bmc-dash-pill p-relative cursor-pointer cursor rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Aprovisionnement</span>
                                    <Link to={'/products/orders'} className="ab-link t-0">
                                    </Link>
                                </div>

                                <div className="bmc-dash-pill bmc-dash-pill-active   cursor  rel">
                                    <span className=" xs-text-fs-14 av-heavy color-6E6 fs16">Transferts</span>
                                    <Link to={'/produits/transfers'} className="ab-link t-0">
                                    </Link>
                                </div>

                            </div>

                            <Spin tip="Chargment..." size="large" spinning={loading }>

                            <div className="grille w100  u-pad-top-s u-pad-horizontal-s u-mar-top-m   blok noshadow noborder hauto rad16 u-mar-top-xl-res nopadres noradres ">


                                <div className="flex justbtw u-mar-bottom-s">

                                <div>

                                    <Input placeholder="Rechercher dans la table" style={{minWidth:'250px',height:'35px!important'}} />

                                </div>

                                    <Button type="primary" size="large"  className="rad8" >
                                    <span className="fs16 av-heavy">  Nouveau transfert </span>
                                </Button>


                                    <Drawer

                                        width={450}
                                        onClose={()=>setVisible(false)}
                                        visible={visible}
                                        bodyStyle={{ paddingBottom: 0,paddingTop:12 }}
                                        destroyOnClose={true}

                                    >


                                    </Drawer>



                                </div>

                                <Table columns={columns} dataSource={Transfers} size="default" pagination={{showSizeChanger:false,size:"small"}} showSizeChanger={false}  />,


                            </div>

                            </Spin>


             
</LayoutPage>


    );


}
