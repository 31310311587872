
import React, { useState,Fragment,useEffect } from 'react';

import logo from '../../logo.png';
import {Form, Input, Button,Spin, message} from 'antd';
import {Link} from "react-router-dom";
import {
    useRecoilState
  } from 'recoil';
import axios from "axios";
import {URL} from "../../urlapi";
import {useHistory} from "react-router";
import  './sign.css';
import { tenantInfos,userInfos } from '../../store';
import jwt_decode from "jwt-decode";
import { LoadingOutlined } from '@ant-design/icons';
import {useGetProducts} from '../../api/api';

export default function  Login () {

    const [loading,setLoading] = useState(false)
    const [_,setTenant] = useRecoilState(tenantInfos)
    const [tenant,updateTenant] = useState()
    const [__,setUser] = useRecoilState(userInfos)
    const history = useHistory()


    // /get/bysubdomain



    useEffect(() => {


        const getTenant = async () => {


            const result = await axios.get(URL+'/tenants/get/bysubdomain', {
                headers: {
                }
            })
            updateTenant(result?.data);
            setLoading(false)
        };




        getTenant();


    }, []);
   


    async function onSubmit ( values )  {

        try {
            setLoading(true)
            /* values.phone = '2376949g99393'
            values.firstname="sinclair" */
            const result = await axios.post(URL + '/employees/login',
                values
            )
            console.log('le result',result)

            const serializedStateTennant = JSON.stringify(result.data.tenant.details.id)
            setTenant(result.data.tenant.details)
            localStorage.setItem('tenant',serializedStateTennant )
            const serializedState = JSON.stringify(result.data.data.token)
            localStorage.setItem('token',serializedState )


          
            setUser(result.data.data.data)
            const serializedStateUser = JSON.stringify(result.data.data.data)
            localStorage.setItem('user',serializedStateUser )

            console.log('le user',serializedStateUser )

            history.push("/");
            message.success('Connexion réussie!!')
            setLoading(false)

        } catch(e) {
            console.log(e)
            setLoading(false)
            message.warning('Erreur connexion!')

        }

    }



    return (


        <Fragment>



        <div className="flex column w100 h100  justcenter itemcenter bgwhiteres" >


        <Form
            name="normal_login"
            className="login-form w20 w100res w70tab bgwhite column flex itemcenter  u-pad-horizontal-l u-pad-top-l rad8 z999"
            size="large"
            initialValues={{
                remember: true,
            }}
            onFinish={onSubmit}
        >

{ tenant?.logo ? 
            <img src={tenant.logo} className="w50 u-mar-bottom-l" style={{borderRadius:8}}/> 
:

<div className="av-heavy fs22 u-mar-bottom-xl  lh0 coltext" >  {tenant?.core_branch} </div>


}


            <Form.Item
                name="email"
                rules={[
                    {
                        type:'email',
                        required: true,
                        message: 'Inserer votre email',
                    },
                ]}
                className="u-mar-bottom-m w100"
            >
                <Input  placeholder="Email"  />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Inserer votre mot de passe!',
                    },
                ]}
                className="u-mar-bottom-xs w100"
            >
                <Input

                    type="password"
                    placeholder="Mot de passe"

                />
            </Form.Item>

           {/*  <div className="fl-r w100 u-mar-bottom-xxs  " style={{textAlign:'right'}}><Link to={'/reset'} className="login-form-forgot fs12" >
                Mot de passe oublié ?
            </Link>
            </div> */}





            <Form.Item className="w100">
                <Button type="primary" size="medium" htmlType="submit"  loading={loading}  className="rad4 w100">
                    <span className="fs13"> Connexion </span>
                </Button>
            </Form.Item>



           


        </Form>



        </div>

        </Fragment>
            );


}







