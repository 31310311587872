import React, { useState } from 'react';
import {
  useRecoilState
} from 'recoil';
import moment from 'moment';

import { DateRangePicker } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import {defaultStaticRanges} from './daterange';




import { activeStartDate,activeEndDate } from '../store'; 
import { Dropdown,Menu,Button,DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const SelectDate = () => {

  const [activeStartDateInput,setActiveStartDate] = useRecoilState(activeStartDate)
  const [activeEndDateInput,setActiveEndDate] = useRecoilState(activeEndDate)

  const [open,setOpen] = useState(false)



  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    }
  ]);




const handleOpenChange = (flag) => {
  setOpen(flag);
};

 const calculateDateRangeFromTwoDates = (state) => {

  setState([state.selection]) 
  const beginDate = moment(state.selection.startDate);
  const endDate = moment(state.selection.endDate);
  const today = moment();
  let text = '';
  const duration = moment.duration(endDate.diff(beginDate));

  console.log(duration)
  let selectedDateDifference;
  if(duration.get('months')!= 0 ) {
   selectedDateDifference = 364
  }else {
   selectedDateDifference = duration.get('days');
}

  console.log(state)

  switch (selectedDateDifference) {
    case 364:
      text = moment(endDate).isSame(today, 'year') ? 'this_year' : 'last_year';
      /* setActiveStartDate(text)
      setActiveEndDate(text) */
      setActiveStartDate(moment(state.selection.startDate).format())
      setActiveEndDate(moment(state.selection.endDate).format())
      break;
    case 30:
    case 29:
      text = moment(endDate).isSame(today, 'month') ? 'this_month' : 'last_month';
      /* setActiveStartDate(text)
      setActiveEndDate(text) */
      setActiveStartDate(moment(state.selection.startDate).format())
      setActiveEndDate(moment(state.selection.endDate).format())
      break;
    case 7:
    case 6:
      text = moment(endDate).isSame(today, 'week') ? 'this_week' : 'last_week';
     /*  setActiveStartDate(text)
      setActiveEndDate(text) */
      setActiveStartDate(moment(state.selection.startDate).format())
      setActiveEndDate(moment(state.selection.endDate).format())
      break;
    case 1:
    case 0:
      text = moment(endDate).isSame(today, 'day') ? 'today' : moment(endDate).isSame(moment().subtract(1, 'days'), 'day')  ? 'yesterday' : moment(state.selection.startDate).format()
      /* setActiveStartDate(text)
      setActiveEndDate(text) */
      setActiveStartDate(moment(state.selection.startDate).format())
      setActiveEndDate(moment(state.selection.endDate).format())
      break;
    default:

      setActiveStartDate(moment(state.selection.startDate).format())
      setActiveEndDate(moment(state.selection.endDate).format())

      break;
  }
  console.log(text)

};








  const menudate = (

<Menu>
<div className='block hauto w100 rad8 box-shadow u-pad-m' s >


<DateRangePicker
 onChange={(item) => calculateDateRangeFromTwoDates(item)  }
 

showSelectionPreview={true}
  moveRangeOnFirstSelection={false}
  months={1}
  staticRanges={defaultStaticRanges}
  inputRanges={[]}
  locale={locales['fr']}
  ranges={state}
  direction="vertical"
/>

</div>

</Menu>


  );
  

  return (
    <Dropdown    
    overlay= { menudate }  
    placement={"bottomLeft"}
/*     trigger={['click']}
 */     visible={open}            
     onVisibleChange={handleOpenChange}
  style={{backgroundColor:'#f9f9f9',marginBottom:15}}
 >


<Button type="default"   className="rad4 flex itemcenter">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#0075ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
    
    
    <span className="fs13 u-mar-left-xs fW500"> 
    
    {activeStartDateInput == "today" ? 'Aujourdhui' : activeStartDateInput == "yesterday" ? 'Hier' : activeStartDateInput == "this_week" ? 'Cette semaine' : activeStartDateInput == "last_week" ? 'Semaine passée' : activeStartDateInput == "this_month" ? 'Le mois en cours' : activeStartDateInput == "last_month" ? 'Le mois dernier' : activeStartDateInput == "this_year" ? 'Cette année' :  activeStartDateInput == "last_year" ? 'Année dernière' : ( activeStartDateInput != activeEndDateInput ?   moment(activeStartDateInput).format('DD MMM') + ' - ' +moment(activeEndDateInput).format('DD MMM') : moment(activeStartDateInput).format('DD MMM YYYY') )  }
    
    </span>
</Button>



  
       
    </Dropdown>
  );
};

export default SelectDate;