
import React, {Fragment, useEffect, useState,useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { Form, Col, Row,Button, Spin, message} from 'antd';
import FormGoals from './form/form';

import axios from "axios";
import {useHistory} from "react-router";
import {CloseOutlined} from '@ant-design/icons';
import { useGetTeam } from '../../api/api';




export default function  AddOrder ({close}) {
    const history = useHistory()

    const [loading,setLoading] = useState(false)



    const [form] = Form.useForm();
    


    const [users,setUsers] = useState(null)

    const { data, error, isLoading, refetch } = useGetTeam({}, {});

   // const { mutateAsync: addOrder } = useAddOrder();

  
    useEffect(() => {

        setUsers(data?.data);

    }, [data]);


    



   const  handleSubmit = async () => {
       

        form.submit()
        
       form.validateFields()
       .then(async function (values) {
      



      
         
         values.items = []
         
         
         
        
       
         values.tunnel = 'pos'
             
         
                 try {
                    
                     setLoading(true)
                   

                   //  await addOrder(values)


         
                     message.success('Enregistrement réussi!!')
                     close()
                     //history.push('/orders')
                     setLoading(false)
         
                 } catch(e) {
                     setLoading(false)
                     message.warning('Erreur enregistrement!')
         
                 }
         
         





         
       })
       .catch((errorInfo) => {
         console.log(errorInfo);
       });

        




      }
    





    return (






                            <div className="flex  column rel h100">

                          
                     


                       

                            <Spin tip="Chargment..." size="large" spinning={false }>


                                           <FormGoals form={form} users={users}  />
                                     
                                           
                            </Spin>

</div>
             
                            

    );


}
